import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import ErrorSharpIcon from "@mui/icons-material/ErrorSharp";
import { env } from "../config";
import logo from "../assets/logo-final.png";
import { removeEventFromDataLayer } from "../utils";

export default function EmailVerification() {
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const verification = searchParams.get("verification");

  const handleVerification = async () => {
    setLoading(true);
    try {
      const res = await fetch(
        `${env.REACT_APP_API_HOST}email-verification/?verification=${verification}`,
        { method: "GET", credentials: "include" }
      );
      const reData = await res.json();
      if (!res.ok) throw new Error(reData.error);
      const resData = JSON.parse(localStorage.getItem("taglabUser"));
      window.dataLayer.push({
        event: "registration-step-2",
        verified: true,
        username: resData.username,
        organization: resData.organization,
        plan: resData.plan_name,
        points_available: resData.points_available,
        referral: resData.referral,
        referrer: resData.referrer,
      });
      navigate("/scans-reports");
    } catch (resError) {
      setFailed(true);
      setError(resError.message);
      setTimeout(() => {
        navigate("/auth");
      }, 2000);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    verification ? handleVerification() : navigate("/auth");
    return () => {
      removeEventFromDataLayer("registration-step-2");
    };
  }, [searchParams]);

  return (
    <>
      <Box sx={{ pt: "2rem", px: "2rem", width: "100%" }}>
        <Paper sx={{ width: "auto", p: "2rem" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "2rem" }}>
              {loading && (
                <>
                  <CircularProgress />
                  <Typography variant="overline">
                    Verifying your email
                  </Typography>
                </>
              )}
              {failed && (
                <>
                  <ErrorSharpIcon color="error" />
                  <Typography variant="overline" color="error">
                    {error}
                  </Typography>
                </>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={logo} width={150} />
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
}
