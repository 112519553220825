import {
  Avatar,
  Box,
  Chip,
  Grid,
  Icon,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import { faArrowDownAZ, faArrowUpAZ } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useMemo, useState } from "react";

import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";
import ArrowUpwardSharpIcon from "@mui/icons-material/ArrowUpwardSharp";
import EventSharpIcon from "@mui/icons-material/EventSharp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";

function FilterIcon({ state }) {
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    switch (state) {
      case "ascending":
        setIcon(<ArrowUpwardSharpIcon />);
        break;
      case "descending":
        setIcon(<ArrowDownwardSharpIcon />);
        break;
      default:
        setIcon(<RemoveSharpIcon />);
    }
  }, [state]);
  return <>{icon}</>;
}

export default function ScansFilter({ type, setScanFilters }) {
  const [searchTerm, setSearchTerm] = useState(null);
  const [alphabeticalSort, setAlphabeticalSort] = useState(null);
  const [sortByDate, setSortByDate] = useState(null);
  const [showCrawls, setShowCrawls] = useState(true);
  const [showScenarios, setShowScenarios] = useState(true);
  const [filterVersion, setFilterVersion] = useState(type ?? 1);

  useEffect(() => {
    setFilterVersion(type);
  }, [type]);

  const setNewSort = (sort) => {
    switch (sort) {
      case "ascending":
        return "descending";
      case "descending":
        return null;
      default:
        return "ascending";
    }
  };

  const searchTermHandler = (e) => {
    const searchTerm$ = e.target.value;
    setSearchTerm(searchTerm$);
    setScanFilters((filters) => ({ ...filters, searchTerm: searchTerm$ }));
  };

  const sortAlphabeticallyHandler = () => {
    setAlphabeticalSort((sort) => {
      let newSort = setNewSort(sort);
      setScanFilters((filters) => ({ ...filters, alphabeticalSort: newSort }));
      return newSort;
    });
  };

  const sortByDateHandler = () => {
    setSortByDate((sort) => {
      let newSort = setNewSort(sort);
      setScanFilters((filters) => ({ ...filters, sortByDate: newSort }));
      return newSort;
    });
  };

  const toggleCrawls = () => {
    setShowCrawls((show) => {
      setScanFilters((filters) => ({ ...filters, displayCrawls: !show }));
      return !show;
    });
  };
  const toggleScenarios = () => {
    setShowScenarios((show) => {
      setScanFilters((filters) => ({ ...filters, displayScenarios: !show }));
      return !show;
    });
  };

  return (
    <Box display={"flex"} alignItems={"center"} gap={2}>
      <Paper elevation={0}>
        <Box px={2} py={1}>
          <Grid
            container
            spacing={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"end"}
          >
            <Grid
              item
              display={"flex"}
              height={"auto"}
              alignItems={"center"}
              width={filterVersion === 1 ? "auto" : "100%"}
            >
              <TextField
                fullWidth
                onChange={searchTermHandler}
                size="small"
                id="search"
                label="Search"
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchSharpIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>
              {filterVersion === 1 ? (
                <Chip
                  onClick={() => sortAlphabeticallyHandler()}
                  avatar={
                    <Avatar>
                      <FilterIcon state={alphabeticalSort} />{" "}
                    </Avatar>
                  }
                  label={`By Name`}
                  variant="outlined"
                />
              ) : (
                <>
                  <Tooltip title="Sort By Name">
                    <Box>
                      <FontAwesomeIcon
                        onClick={() => sortAlphabeticallyHandler()}
                        icon={faArrowDownAZ}
                      />
                    </Box>
                  </Tooltip>
                </>
              )}
            </Grid>
            <Grid item>
              {filterVersion === 1 ? (
                <Chip
                  onClick={() => sortByDateHandler()}
                  avatar={
                    <Avatar>
                      <FilterIcon state={sortByDate} />{" "}
                    </Avatar>
                  }
                  label={`By Date`}
                  variant="outlined"
                />
              ) : (
                <>
                  <Tooltip title="Sort By Date">
                    <EventSharpIcon onClick={() => sortByDateHandler()} />
                  </Tooltip>
                </>
              )}
            </Grid>
            <Grid item>
              {filterVersion === 1 ? (
                <Chip
                  label="Crawls"
                  color={showCrawls ? "secondary" : "default"}
                  onClick={() => toggleCrawls()}
                  avatar={
                    <Avatar
                      sx={{
                        bgcolor: orange[400],
                        width: 24,
                        height: 24,
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      C
                    </Avatar>
                  }
                ></Chip>
              ) : (
                <>
                  <Tooltip title="Toggle Crawls">
                    <Avatar
                      onClick={() => toggleCrawls()}
                      sx={{
                        bgcolor: showCrawls ? orange[400] : grey[500],
                        width: 24,
                        height: 24,
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      C
                    </Avatar>
                  </Tooltip>
                </>
              )}
            </Grid>
            <Grid item>
              {filterVersion === 1 ? (
                <Chip
                  label="Scenarios"
                  color={showScenarios ? "info" : "default"}
                  onClick={() => toggleScenarios()}
                  avatar={
                    <Avatar
                      sx={{
                        bgcolor: blue[400],
                        width: 24,
                        height: 24,
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      S
                    </Avatar>
                  }
                ></Chip>
              ) : (
                <>
                  <Tooltip title="Toggle Scenarios">
                    <Avatar
                      onClick={() => toggleScenarios()}
                      sx={{
                        bgcolor: showScenarios ? blue[400] : grey[500],
                        width: 24,
                        height: 24,
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      S
                    </Avatar>
                  </Tooltip>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}
