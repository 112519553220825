import {
  Alert,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { stringToDateFormat } from "../utils";
import { red } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";

const NotificationList = ({ notifs, handleShowNotif }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          p: 2,
          position: "absolute",
          width: "100%",
          zIndex: 99,
        }}
      >
        <Tooltip title="Hide Notifications">
          <IconButton
            sx={{ ":hover": { bgcolor: red[400], color: "#fff" } }}
            onClick={handleShowNotif}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <List disablePadding>
        {notifs?.map((elem, index) => (
          <ListItem key={index} divider>
            <ListItemText
              primary={elem.notification_content}
              secondary={stringToDateFormat(elem.creation_date, true)}
            />
          </ListItem>
        ))}
        {notifs.length === 0 && <Alert>Notification not found</Alert>}
      </List>
    </>
  );
};
export default NotificationList;
