import { createSlice, configureStore } from "@reduxjs/toolkit";

const initAuthState = { checkEmailVerification: true, registrationEmail: null };
const authStateSlice = createSlice({
  name: "authState",
  initialState: initAuthState,
  reducers: {
    updateCheckEmailVerification(state, action) {
      state.checkEmailVerification = !state.checkEmailVerification;
    },
    updateRegistrationEmail(state, payload) {
      state.registrationEmail = payload.registrationEmail;
    },
  },
});

const initialActiveReportState = { uniqueTags: null, reportName: null };
const activeReportSlice = createSlice({
  name: "activeReport",
  initialState: initialActiveReportState,
  reducers: {
    setActiveReport(state, action) {
      state.uniqueTags = action.payload.uniqueTags;
      state.reportName = action.payload.reportName;
    },
    clearActiveReport(state) {
      state.uniqueTags = null;
      state.reportName = null;
    },
  },
});

const initUiState = { newNotificationCount: 0 };
const uiStateSlice = createSlice({
  name: "uiState",
  initialState: initUiState,
  reducers: {
    setNotificationsCount(state, action) {
      state.newNotificationCount += action.payload;
    },
    clearNotificationsCount(state) {
      state.newNotificationCount = 0;
    },
  },
});

const store = configureStore({
  reducer: {
    authState: authStateSlice.reducer,
    activeReport: activeReportSlice.reducer,
    uiState: uiStateSlice.reducer,
  },
});

export const authStateActions = authStateSlice.actions;
export const activeReportActions = activeReportSlice.actions;
export const uiStateActions = uiStateSlice.actions;
export default store;
