import { useNavigate, useParams } from "react-router-dom";

import authServices from "../../services/authService";
import { useEffect } from "react";

export default function Refer() {
  const { referralId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const login = authServices.checkLogin();
    login.then((isLoggedIn) => {
      if (isLoggedIn) {
        navigate("/user-info");
        return;
      }
      localStorage.setItem("taglabReferrer", referralId);
      navigate("/auth?authMode=signup");
    });
  }, [referralId]);

  return <>{referralId}</>;
}
