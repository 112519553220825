import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Copyright from "../../components/Copyright";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { env } from "../../config";

export default function NewPasswordForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const [validating, setValidating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordState, setPasswordState] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const resetToken = searchParams.get("token");

  const checkTokenValidity = async (token) => {
    try {
      const res = await fetch(
        `${env.REACT_APP_API_HOST}forgot-password/?token=${token}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      const resData = await res.json();
      if (!res.ok) throw new Error(resData.error);
      setStateMessage({ status: "primary", message: resData.message });
    } catch (resError) {
      setStateMessage({ status: "error", message: resError.message });
    } finally {
      setValidating(false);
    }
  };

  const passwordRules = (password1, password2) => {
    if (password1 === password2) {
      if (password1.length < 6) {
        return {
          pass: false,
          message: "Password is too short. Use at least 6 Characters",
        };
      }
      return { pass: true };
    }
    return { pass: false, message: "Passwords don't match" };
  };

  useEffect(() => {
    if (!resetToken) {
      navigate("/auth?authMode=login");
      return;
    }
    // check token validity
    setValidating(true);
    checkTokenValidity(resetToken);
  }, [searchParams]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const password = data.get("password");
    const confirmPassword = data.get("confirmPassword");
    const rules = passwordRules(password, confirmPassword);
    if (!rules.pass) {
      setPasswordState(rules.message);
      return;
    }
    try {
      const res = await fetch(`${env.REACT_APP_API_HOST}reset-password/`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ token: resetToken, password }),
      });
      const resData = await res.json();
      if (!res.ok) throw new Error(resData.error);
      setPasswordState(false);
      setStateMessage({
        status: "primary",
        message: `${resData.message}. Redirecting you to login`,
      });
      setTimeout(() => {
        navigate("/auth?authMode=login");
      }, 3000);
    } catch (resError) {
      setPasswordState(resError.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5" gutterBottom>
        Password Reset
      </Typography>

      {validating ? (
        <Box
          display={"flex"}
          flexDirection={"column"}
          p={3}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress />
          <Typography variant="overline" mt={3} paragraph>
            Validating Reset Token
          </Typography>
        </Box>
      ) : (
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          {stateMessage && (
            <Typography
              variant="body2"
              color={stateMessage.status}
              gutterBottom
            >
              {stateMessage.message}
            </Typography>
          )}

          {passwordState && (
            <Typography
              align="center"
              gutterBottom
              color="error"
              variant="body2"
            >
              {passwordState}
            </Typography>
          )}
          <FormControl required fullWidth id="password" variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              name="password"
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <FormControl
            required
            fullWidth
            id="confirmPassword"
            variant="outlined"
            sx={{ mt: 2 }}
          >
            <InputLabel htmlFor="outlined-adornment-confirm-password">
              Confirm Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-confirm-password"
              name="confirmPassword"
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
            />
          </FormControl>
          {loading ? (
            <Box
              sx={{ mt: 3, mb: 2 }}
              display={"flex"}
              justifyContent={"center"}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Set New Password
            </Button>
          )}

          <Grid container>
            <Grid item xs></Grid>
            <Grid item>
              <NavLink to="?authMode=login">
                <Typography variant="body2" color="primary">
                  {"Go to login"}
                </Typography>
              </NavLink>
            </Grid>
          </Grid>

          {/* <Divider sx={{ mt: 4, mb: 4 }}>
          <Chip label="or sign in with" />
        </Divider>

        <SocialAuth /> */}
        </Box>
      )}
      <Copyright sx={{ mt: 4 }} />
    </Box>
  );
}
