import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import DeleteSweepSharpIcon from "@mui/icons-material/DeleteSweepSharp";
import PlayCircleOutlineSharpIcon from "@mui/icons-material/PlayCircleOutlineSharp";
import ScanLaunch from "../../components/Scans/ScanLaunch";
import ScanReportVersionCard from "./ScanReportVersionCard";
import { env } from "../../config";
import { useSearchParams } from "react-router-dom";

export default function ScanReportsVersions(props) {
  const [reportVersions, setReportVersions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [versions, setVersions] = useState(false);
  const [selectedBulk, setSelectedBulk] = useState([]);
  const [startBulkDelete, setStartBulkDelete] = useState(false);
  const [startLaunch, setStartLaunch] = useState(false);

  const handleStartLaunch = () => {
    setStartLaunch(true);
  };

  const id = searchParams.get("scanId");
  const type = searchParams.get("scanType");

  const getVersions = async () => {
    try {
      const response = await fetch(
        `${env.REACT_APP_API_HOST}scans/reports/?id=${id}&type=${type}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      const resData = await response.json();
      if (!response.ok) throw new Error(resData.error);
      setLoading(true);
      setReportVersions(() => [...resData]);
    } catch (resError) {
    } finally {
      setLoading(false);
    }
  };

  const handleSelectedBulk = (e) => {
    setSelectedBulk((cur) => {
      if (e.checked) {
        if (!cur.find((item) => item === e.id)) {
          return [...cur, e.id];
        }
      } else {
        if (cur.find((item) => item === e.id)) {
          return [...cur.filter((item) => item !== e.id)];
        }
      }
    });
  };

  const handleReportsBulkDelete = async () => {
    const response = await fetch(`${env.REACT_APP_API_HOST}deleteReportBULK/`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(selectedBulk),
    });
    const resData = await response.json();
    if (response.ok) {
      setVersions(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    getVersions();
    setVersions(false);
    setSelectedBulk([]);
    setStartBulkDelete(false);
  }, [searchParams, versions]);

  useEffect(() => {
    getVersions();
  }, [startLaunch]);

  const handleClose = () => {
    setStartBulkDelete(false);
  };

  const bulkDeleteModal = (
    <>
      <Dialog open={startBulkDelete} onClose={handleClose}>
        <DialogTitle>{`Delete ${selectedBulk.length} Reports?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You're about to delete {selectedBulk.length} reports. This action
            cannot be undone. Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="error"
            startIcon={<DeleteSweepSharpIcon />}
            onClick={handleReportsBulkDelete}
            autoFocus
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  return (
    <>
      {loading ? (
        <Grid item width={"100%"}>
          <Typography variant="h6">Loading reports</Typography>
          <LinearProgress color="primary"></LinearProgress>
        </Grid>
      ) : (
        <>
          {bulkDeleteModal}
          {startLaunch && (
            <ScanLaunch
              scanId={id}
              scanName={props.name ?? ""}
              scanType={type}
              setStartLaunch={setStartLaunch}
            />
          )}
          <Paper square sx={{ mb: 2, position: "sticky", top: 0, zIndex: 999 }}>
            <Box
              display={"flex"}
              width={"100%"}
              alignItems={"center"}
              justifyContent={"space-between"}
              px={2}
              py={1}
            >
              <Typography variant="overline" color={"primary"}>
                {reportVersions.length} Report
                {reportVersions.length !== 1 && <>s</>}
              </Typography>
              <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
                {selectedBulk.length > 0 && (
                  <Button
                    color="error"
                    variant="outlined"
                    startIcon={<DeleteSweepSharpIcon />}
                    onClick={() => setStartBulkDelete(true)}
                  >
                    delete
                  </Button>
                )}
                <Tooltip title="Launch">
                  <Button
                    onClick={handleStartLaunch}
                    variant="outlined"
                    startIcon={<PlayCircleOutlineSharpIcon />}
                  >
                    Launch
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </Paper>
          <Grid container spacing={2} width={"100%"}>
            {reportVersions
              .sort((a, b) => b.id - a.id)
              .map((el, idx) => (
                <Grid
                  width={"100%"}
                  item
                  key={idx}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={3}
                >
                  <ScanReportVersionCard
                    {...el}
                    setVersions={setVersions}
                    setSelectedBulk={handleSelectedBulk}
                    reloadVersions={getVersions}
                    width={"100%"}
                  />
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </>
  );
}
