import { createTheme, responsiveFontSizes } from "@mui/material";
import { green, orange, yellow } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: green[600],
    },
    secondary: {
      main: yellow[700],
    },
    info: {
      main: "#1E90FF",
    },
    success: {
      main: green[400],
    },
  },
  typography: {
    fontFamily: "cabin",
  },
});

export default responsiveFontSizes(theme);
