import { Box, Button, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import { env } from "../../config";

export default function Tutorial() {
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(0);
  const [categoryData, setCategoryData] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepData, setActiveStepData] = useState(null);
  const mediaUrl = "https://taglabstorage.blob.core.windows.net/media/";

  const getCategories = async () => {
    try {
      const res = await fetch(`${env.REACT_APP_API_HOST}tutorial/`);
      const resData = await res.json();
      if (!res.ok) throw new Error("Error fetching tutorial categories!");
      setCategories(resData);
      getCategoryData(resData[0]);
    } catch (resError) {
      console.log(resError.message);
    }
  };

  const getCategoryData = async (category) => {
    setActiveCategory(category);
    setActiveStepData(null);
    try {
      const res = await fetch(
        `${env.REACT_APP_API_HOST}tutorial/${category.id}/`
      );
      const resData = await res.json();
      if (!res.ok) throw new Error("Error fetching category content!");
      setCategoryData(resData);
      setActiveStep(0);
      setActiveStepData(resData[0]);
    } catch (resError) {
      console.log(resError.message);
    }
  };

  const handleStep = (step) => {
    setActiveStep(step);
    setActiveStepData(categoryData[step]);
  };

  const handleNext = () => {
    if (activeStep < categoryData.length - 1) {
      handleStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      handleStep(activeStep - 1);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    setActiveStepData(categoryData[activeStep]);
  }, [activeStep]);

  return (
    <>
      <Paper
        sx={{
          height: "100%",
          width: "100%",
          //   minHeight: "50%",
          display: "flex",
        }}
      >
        <Box sx={{ width: "60%" }}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              padding: 2,
            }}
          >
            {categories.map((cat, idx) => (
              <Button
                variant={activeCategory.id === cat.id ? "contained" : ""}
                sx={{ borderRadius: "35px" }}
                key={idx}
                onClick={() => getCategoryData(cat)}
              >
                {cat.category_name}
              </Button>
            ))}
          </Box>
          {activeStepData && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingX: "3rem",
                  paddingY: "1.5rem",
                }}
              >
                <img
                  style={{ objectFit: "scale-down", height: "" }}
                  src={`${mediaUrl}${activeStepData.screenshot}`}
                />
              </Box>
            </>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingY: "1.5rem",
            }}
          >
            <Stepper nonLinear activeStep={activeStep}>
              {categoryData.map((data, idx) => (
                <Step key={idx}>
                  <StepButton
                    color="inherit"
                    onClick={() => handleStep(idx)}
                  ></StepButton>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
        <Box
          sx={{
            width: "40%",
            height: "100%",
            bgcolor: "#F6FFFD",
            position: "relative",
          }}
        >
          {activeStepData && (
            <>
              <Box
                sx={{
                  display: "flex",
                  padding: 3,
                  justifyContent: "center",
                  alignContent: "center",
                  bgcolor: "#2CA14810",
                }}
              >
                <Typography variant="h6" fontWeight={600}>
                  {activeCategory.category_name} Tutorial
                </Typography>
              </Box>
              <Box sx={{ width: "100%", padding: 3 }}>
                <div
                  dangerouslySetInnerHTML={{ __html: activeStepData.content }}
                ></div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  padding: "3rem",
                  gap: "4rem",
                  position: "absolute",
                  bottom: "0",
                }}
              >
                {activeStep > 0 && (
                  <Button variant="outlined" onClick={handleBack}>
                    Back
                  </Button>
                )}
                {activeStep < categoryData.length - 1 && (
                  <Button variant="contained" onClick={handleNext}>
                    Next
                  </Button>
                )}
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </>
  );
}
