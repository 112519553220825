import { Link, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import Card from "../../components/card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { env } from "../../config.js";

export default function CronScheduleDetails() {
  const { scheduleId } = useParams();
  const [schedule, setSchedule] = useState(null);

  let navigate = useNavigate();
  const handleRoute = useCallback(
    (val) => {
      navigate(val);
    },
    [navigate]
  );

  const getSchedule = useCallback(() => {
    fetch(`${env.REACT_APP_API_HOST}scheduled_tasks/${scheduleId}`, {
      method: "GET",
      credentials: "include",
    })
      .then(async (res) => {
        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.error);
        }
        return res.json();
      })
      .then((data) => {
        setSchedule(data);
      })
      .catch((err) => {
        handleRoute("/login");
      });
  }, []);

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      getSchedule(subscribe);
    }
    return () => (subscribe = false);
  }, []);

  return (
    schedule && (
      <div className="flex flex-col gap-4">
        <Card className="flex justify-between items-center">
          <Link to="/schedule">
            <button className="flex items-center">
              <FontAwesomeIcon
                icon="fa-solid fa-angle-left"
                className="text-green cursor-pointer mr-2"
              ></FontAwesomeIcon>
              Back
            </button>
          </Link>
          <h1 className="font-bold">{schedule.name}</h1>
          <button
            className={`px-2 py-1 rounded-lg text-white ${
              schedule.enabled ? "bg-green" : "bg-red-600"
            }`}
          >
            {schedule.enabled ? "Enabled" : "Disabled"}
          </button>
        </Card>

        <div className="grid grid-cols-4 gap-4">
          <Card className="col-span-3 ">
            <div className="grid grid-cols-2 gap-2">
              <p>
                <span className="font-bold mr-2">Start Time:</span>
                {(() => {
                  const d = new Date(schedule.start_time);
                  return d.toUTCString();
                })()}
              </p>
              {schedule.expires && (
                <p>
                  <span className="font-bold mr-2">Expiry:</span>
                  {(() => {
                    const d = new Date(schedule.expires);
                    return d.toUTCString();
                  })()}
                </p>
              )}

              <p>
                <span className="font-bold mr-2">Type:</span>
                {schedule.one_off ? (
                  <span>
                    <FontAwesomeIcon
                      icon="fa-solid fa-1"
                      className="text-red-600 mr-2"
                    />
                    One Off
                  </span>
                ) : (
                  <span>
                    <FontAwesomeIcon
                      icon="fa-solid fa-repeat"
                      className="text-green mr-2"
                    />
                    Repeating
                  </span>
                )}
              </p>
              {!schedule.one_off && (
                <p>
                  <span className="font-bold mr-2">Schedule:</span>
                  {schedule.human_readable}
                </p>
              )}
            </div>
          </Card>
          <Card className="bg-green text-white">
            <h1 className="font-bold text-center">
              {schedule.scan
                ? "Scenario Scan"
                : schedule.crawler
                ? "Crawler"
                : ""}
            </h1>
            <p className="text-center">
              {schedule.scan
                ? schedule.scan.scan_name
                : schedule.crawler
                ? schedule.crawler.crawler_name
                : ""}
            </p>
          </Card>
        </div>

        {/* Results */}
        <Card>
          <h1 className="font-bold">Schedule Runs</h1>
          {schedule.results.length > 0 ? (
            <div className="w-full">
              <table className="table-auto border-collapse border w-full">
                <thead>
                  <tr>
                    <th className="p-2">#</th>
                    <th className="p-2">Start Time</th>
                    <th className="p-2">End Time</th>
                    <th className="p-2">Status</th>
                    <th className="p-2">Results</th>
                  </tr>
                </thead>
                <tbody>
                  {schedule.results.map((r, idx) => {
                    return (
                      <tr key={idx}>
                        <td className="text-center border p-2">{idx + 1}</td>
                        <td className="text-center border p-2">
                          {(() => {
                            const d = new Date(r.date_created);
                            return d.toUTCString();
                          })()}
                        </td>
                        <td className="text-center border p-2">
                          {(() => {
                            const d = new Date(r.date_done);
                            return d.toUTCString();
                          })()}
                        </td>
                        <td className="text-center border p-2">{r.status}</td>
                        <td className="text-center border p-2">{r.result}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            "No Runs"
          )}
        </Card>
      </div>
    )
  );
}
