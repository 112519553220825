import { Badge, Button, IconButton, Popover, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import CustomButton from "@src/shared/customButton";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import ChromeReaderModeSharpIcon from "@mui/icons-material/ChromeReaderModeSharp";
import CompanyIcon from "../../components/icons/companyIcon";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "../../assets/menu.svg";
import NotificationList from "../../components/notificationList";
import NotificationsNoneSharpIcon from "@mui/icons-material/NotificationsNoneSharp";
import RecommendSharpIcon from "@mui/icons-material/RecommendSharp";
import ReportIcon from "../../components/icons/reportIcon";
import ScanIcon from "../../components/icons/scan";
import ScheduleIcon from "../../components/icons/scheduleIcon";
// import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Upgrade from "../../components/Upgrade.jsx";
import ValidationIcon from "../../components/icons/validationIcon";
import avatar from "../../assets/userImage.png";
import { env } from "../../config.js";
import { green } from "@mui/material/colors";
import notificationIcon from "../../assets/notification.svg";
import { uiStateActions } from "../../store/index.js";

const drawerWidth = 232;
const navbar = [
  {
    title: "Scan",
    Icon: ScanIcon,
    active: false,
    subMenu: [
      {
        title: "Scenario Scan",
        navigate: "/scan/scenario-scan",
        active: false,
      },
      {
        title: "Crawl Scan",
        navigate: "/scan/crawl-scan",
        active: false,
      },
    ],
  },
  {
    title: "Report",
    Icon: ReportIcon,
    navigate: "/scans-reports",
    active: false,
  },
  {
    title: "Validations",
    Icon: ValidationIcon,
    active: false,
    navigate: "/validations",
  },
  {
    title: "Schedule",
    navigate: "/schedule",
    Icon: ScheduleIcon,
    active: false,
  },
  {
    title: "Referrals",
    navigate: "/referrals",
    Icon: RecommendSharpIcon,
    active: false,
  },
  {
    title: "Tutorial",
    navigate: "/tutorial",
    Icon: ChromeReaderModeSharpIcon,
    active: false,
  },
];

const SubMenu = ({ subMenu, handleNavigation }) => {
  const [hoveredIndex, setHoveredIndex] = React.useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <div>
      {subMenu &&
        subMenu.map((list, i) => (
          <ListItem
            key={i}
            disablePadding
            className={`${
              list.active ? "!bg-gradient" : "bg-white"
            } hover:!bg-white`}
            onClick={() => handleNavigation(list.navigate, true)}
            onMouseEnter={() => handleMouseEnter(i)}
            onMouseLeave={handleMouseLeave}
          >
            <ListItemButton>
              <ListItemIcon className="!min-w-[2rem]"></ListItemIcon>
              <ListItemText
                primary={
                  <span
                    className={`font-normal text-sm  ${
                      list.active
                        ? "!text-white"
                        : hoveredIndex === i
                        ? "text-black"
                        : "text-light-grey"
                    }`}
                  >
                    {list.title}
                  </span>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
    </div>
  );
};

const AccordionSubmenu = ({
  item,
  index,
  hoveredIndex,
  onMouseEnter,
  onMouseLeave,
  handleNavigation,
  expand,
}) => {
  const [isExpand, setIsExpand] = useState(expand);

  return (
    <Accordion
      sx={{
        backgroundColor: "#fff !important",
        boxShadow: "none !important",
      }}
      className="before:!bg-white"
      key={`sidebar-accordion-${index}`}
      expanded={isExpand}
      onChange={() => setIsExpand(!isExpand)}
    >
      <AccordionSummary
        sx={{
          minHeight: "50px !important",
        }}
        className={` hover:!bg-[rgba(0,0,0,0.04)]  !pl-8  hover:text-white !max-h-3 !min-h:[45px]  ${
          item.subMenu.some((item) => item.active) ? "accordian" : ""
        }`}
        // expandIcon={<ExpandMoreIcon fill={"white"} width={12} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onMouseEnter={() => onMouseEnter(index)}
        onMouseLeave={onMouseLeave}
      >
        <ListItemIcon className="!min-w-[2rem] mr-2">
          <item.Icon
            height={23}
            stroke={
              hoveredIndex === index
                ? "black"
                : item.subMenu.some((item) => item.active)
                ? "green"
                : "#969BA0"
            }
            fill={
              hoveredIndex === index
                ? "black"
                : item.subMenu.some((item) => item.active)
                ? "green"
                : "#969BA0"
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <p
              className={`font-normal  text-sm ${
                item.subMenu.some((item) => item.active)
                  ? "text-green"
                  : "text-light-grey"
              }   ${hoveredIndex === index && "!text-black"}`}
            >
              {item.title}
            </p>
          }
        />
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "0 !important",
        }}
        className="bg-white ml-9"
      >
        <SubMenu subMenu={item.subMenu} handleNavigation={handleNavigation} />
      </AccordionDetails>
    </Accordion>
  );
};

export default function AppSidebar(props) {
  const navigate = useNavigate();
  const { window, title, notifs, get_notifications } = props;
  const [crawlScanActive, setCrawlScanActive] = useState("");
  const [scenarioScanActive, setScenarioScanActive] = useState("");
  const [scan, setScan] = useState(true);
  const [schedule, setSchedule] = useState(false);
  const [report, setReport] = useState(false);
  const [userIcon, setUserIcon] = useState("");
  const [userInfo, setUserInfo] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const [check_clicks, setCheck_clicks] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [navbarItems, setNavbarItems] = useState(navbar);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showHideNavbar, setShowHideNavbar] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const location = useLocation();
  let url = location?.pathname;
  let search = location?.search;
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [hoveredIndex, setHoveredIndex] = React.useState(null);
  const [user, setUser] = useState(null);

  const newNotifications = useSelector(
    (state) => state.uiState.newNotificationCount
  );

  const userSetHandler = () => {
    const data = localStorage.getItem("taglabUser");
    if (data) {
      setUser(JSON.parse(data));
    }
  };

  useEffect(() => {
    let subscription = true;
    if (subscription) {
      get_notifications(handleRoute);
    }
    return () => (subscription = false);
  }, []);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  useEffect(() => {
    let _isSubNavbar = navbar.some(
      (item) => item?.subMenu?.some((i) => i.navigate === url) ?? false
    );
    handleNavigation(url, _isSubNavbar);
    userSetHandler();
  }, [url]);

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleNavigation = (url, isSubNavbar = false) => {
    if (
      url === "/report/tagsummary" ||
      url === "/report/commands" ||
      url === "/report/validation" ||
      url === "/report/cookies"
    ) {
      if (!id) {
        return;
      }
    }
    let _navbarItems = [...navbarItems];

    _navbarItems = _navbarItems.map((item) => {
      if (item.active) {
        item.active = false;
      }
      if (isSubNavbar) {
        item.subMenu &&
          item.subMenu.map((i) => {
            if (i.active) {
              i.active = false;
            }
            if (i.navigate === url) {
              i.active = true;
            }
            return { ...i };
          });
      } else {
        item.subMenu &&
          item.subMenu.map((i) => {
            if (i.active) {
              i.active = false;
            }
          });
        // const _url = url.split("/");
        if (item.navigate === url) {
          item.active = true;
        }
      }
      return { ...item };
    });

    setNavbarItems([..._navbarItems]);
    navigate(url + search);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleClickDropdown = (event) => {
    setAnchorEl(event.currentTarget);
    setIsDropdownOpen(true);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    setIsDropdownOpen(false);
  };

  const handleLogout = async () => {
    await fetch(`${env.REACT_APP_API_HOST}logout/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        localStorage.removeItem("taglabUser");
        navigate("/login");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleRoute = (val) => {
    handleQueryString();
    navigate(val);
  };

  const handleQueryString = () => {
    setCrawlScanActive("");
    setScenarioScanActive("");
    setUserIcon("");
    setScan(false);
    setSchedule(false);
    setReport(false);
    setUserInfo(false);
    const queryString = window.location.href;
    const active = "topBar_button_selected";
    if (queryString.includes("scenario-scan")) {
      setScenarioScanActive(active);
      setScan(true);
    } else if (queryString.includes("crawl-scan")) {
      setCrawlScanActive(active);
      setScan(true);
    } else if (queryString.includes("scans-reports")) {
      setReport(true);
    } else if (queryString.includes("schedule")) {
      setSchedule(true);
    } else if (queryString.includes("/user-info")) {
      setUserIcon(active);
      setUserInfo(true);
    }
  };

  const [showUpgrade, setShowUpgrade] = useState(false);

  const drawer = (
    <div>
      <Upgrade open={showUpgrade} closeFn={setShowUpgrade} />
      <Toolbar
        className="mb-4 flex justify-center"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <CompanyIcon height={56} className={"mt-4"} />
      </Toolbar>

      <List>
        {navbarItems.map((item, index) =>
          item?.subMenu ? (
            <AccordionSubmenu
              item={item}
              index={index}
              key={`accordion-submenu-${index}`}
              hoveredIndex={hoveredIndex}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              handleNavigation={handleNavigation}
              expand={item?.subMenu?.some((i) => i.navigate === url) ?? false}
            />
          ) : (
            <ListItem
              key={`accordion-submenu-listitem-${index}`}
              disablePadding
              className={`${item.active ? "border-l-4 border-l-green" : ""} `}
              onClick={() => handleNavigation(item.navigate, false)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <ListItemButton className=" !pl-8">
                <ListItemIcon className="!min-w-[2rem] mr-2 ">
                  <item.Icon
                    height={23}
                    stroke={
                      hoveredIndex === index
                        ? "black"
                        : item.active
                        ? "green"
                        : "#969BA0"
                    }
                    fill={
                      hoveredIndex === index
                        ? "black"
                        : item.active
                        ? "green"
                        : "#969BA0"
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <p
                      className={`font-normal  text-sm ${
                        item.active ? "text-green" : "text-light-grey"
                      }   ${hoveredIndex === index && "!text-black"}`}
                    >
                      {item.title}
                    </p>
                  }
                />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          my: "2rem",
          position: "absolute",
          bottom: 0,
        }}
      >
        {user && (
          <Typography variant="subtitle1" fontWeight={600} color={green[400]}>
            {user.plan}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setShowUpgrade(true)}
        >
          <Typography fontWeight={600} sx={{ textTransform: "capitalize" }}>
            <Typography variant="caption">Change Subscription</Typography>
          </Typography>
        </Button>
      </Box>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const dispatch = useDispatch();
  const clearNotifications = () => {
    dispatch(uiStateActions.clearNotificationsCount());
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: {
            sm: `calc(100% -  ${showHideNavbar ? drawerWidth : "0"}px)`,
          },
          ml: { sm: `${showHideNavbar ? drawerWidth : "0"}px` },
          boxShadow: "none",
        }}
      >
        <Toolbar className="flex justify-between bg-white ">
          <div className="flex items-center gap-3">
            <img
              src={MenuIcon}
              className="cursor-pointer"
              onClick={() => setShowHideNavbar((prev) => !prev)}
            />
            <h1 className="text-black font-bold text-lg">{title}</h1>
          </div>
          <div className="flex gap-3 items-center">
            <IconButton
              onClick={(event) => {
                setAnchorE2(event.currentTarget);
                setIsDropdownOpen1(true);
                clearNotifications();
              }}
            >
              <Badge badgeContent={newNotifications} color="primary">
                <NotificationsNoneSharpIcon color="primary" />
              </Badge>
            </IconButton>

            <Popover
              id={"simple-popover1"}
              open={isDropdownOpen1}
              anchorEl={anchorE2}
              onClose={() => {
                setAnchorE2(null);
                setIsDropdownOpen1(false);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box sx={{ width: "25rem", height: "50rem" }}>
                <NotificationList
                  notifs={notifs}
                  handleShowNotif={() => {
                    setAnchorE2(null);
                    setIsDropdownOpen1(false);
                  }}
                />
              </Box>
            </Popover>
            {user ? (
              <>
                <p className="text-light-grey text-sm">
                  Hello, <span className="text-black"> {user.firstName} </span>
                </p>
                <img
                  src={user.profilePicture ? user.profilePicture : avatar}
                  className="h-[40px] w-[40px] cursor-pointer"
                  onClick={handleClickDropdown}
                />
              </>
            ) : (
              <>
                <p className="text-light-grey text-sm">Hello</p>
                <img
                  src={avatar}
                  className="h-[40px] w-[40px] cursor-pointer"
                  onClick={handleClickDropdown}
                />
              </>
            )}
            <Popover
              id={"simple-popover"}
              open={isDropdownOpen}
              anchorEl={anchorEl}
              onClose={handleCloseDropdown}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="w-64 p-4">
                <h2
                  className="cursor-pointer"
                  onClick={() => {
                    handleCloseDropdown();
                    navigate("/user-info");
                  }}
                >
                  Go to profile
                </h2>
                <h2 className="cursor-pointer" onClick={handleLogout}>
                  Logout
                </h2>
              </div>
            </Popover>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: showHideNavbar ? drawerWidth : "0" },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#fff",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          //   style={{ backgroundColor: "red" }}
          variant="persistent"
          open={showHideNavbar}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: showHideNavbar ? drawerWidth : "0",
              backgroundColor: "#fff",
              border: 0,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
