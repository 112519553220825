import "./scenarioStyle.css";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { grey } from "@mui/material/colors";

const options = [
  "getUrl",
  "click",
  "sleep",
  "validate",
  "input",
  "submit",
  "scroll",
  "execute_Javascript",
  "Bulk_URL_Open",
];

const scrollTypes = ["element", "location"];

function ScenarioScanTable({
  selected,
  setSelected,
  handleAddinstruction,
  scanData,
  selectedScanIndex,
  handleRemoveInstruction,
  handlechangeOrder,
  handleEditInstruction,
  onEdit,
  selectedRowToEdit,
  handleChangesSave,
  handleChangeInstructionData,
  selectedRowData,
  handleStepsCountChange,
  StepsCount,
  handleDuplicateScanStep,
  scroll,
  handleScrollTypeChange,
  scrollLocation,
  handleChangeScrollLocation,
}) {
  const [openModalConfDeletion, setopenModalConfDeletion] = useState(false);
  const [indexTodelete, setIndexToDelete] = useState();
  const [elementToRemove, setElementToRemove] = useState();
  const handleSelectChange = (e) => {
    setSelected(e.target.value);
  };

  const handleClosemodalConfDeletion = () => {
    setopenModalConfDeletion(false);
  };
  const handleOpenmodalConfDeletion = (index) => {
    setElementToRemove(scanData[selectedScanIndex].instructions[index].action);
    setIndexToDelete(index);
    setopenModalConfDeletion(true);
  };

  const handleRemoveAfterConf = () => {
    handleStepsCountChange(false, indexTodelete);
    handleRemoveInstruction(indexTodelete);
    handleClosemodalConfDeletion();
  };

  return (
    <div>
      <div>
        <table className="scenarioTable_table">
          <thead className="scenarionTable_thead">
            <tr className="scenarionTable_thead_tr">
              <td>Step Name</td>
              <td>Command</td>
              <td>Target</td>
              <td>Value</td>
              <td>Settings</td>
            </tr>
          </thead>
          <tbody style={{ width: "100%" }} className="scenarionTable_tbody">
            {scanData && scanData[selectedScanIndex]?.instructions?.length ? (
              scanData[selectedScanIndex].instructions.map((elem, index) => (
                <tr key={index}>
                  {elem.action === "click" ? (
                    <>
                      <td>{elem.items.step_name}</td>
                      <td>{elem.action}</td>
                      {elem.items.click_attr_name &&
                      elem.items.click_attr_value ? (
                        <td>
                          {elem.items.click_attr_name}=
                          {elem.items.click_attr_value}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td></td>
                    </>
                  ) : (
                    <></>
                  )}
                  {elem.action === "getUrl" ? (
                    <>
                      <td>{elem.items.step_name}</td>
                      <td>{elem.action}</td>
                      {elem.items.url ? <td>{elem.items.url}</td> : <td></td>}
                      <td></td>
                    </>
                  ) : (
                    <></>
                  )}
                  {elem.action === "sleep" ? (
                    <>
                      <td>{elem.items.step_name}</td>
                      <td>{elem.action}</td>
                      <td></td>
                      <td>{elem.items.value}</td>
                    </>
                  ) : (
                    <></>
                  )}
                  {elem.action === "validate" ? (
                    <>
                      <td>{elem.items.step_name}</td>
                      <td>{elem.action}</td>
                      <td></td>
                      <td>{elem.items.value}</td>
                    </>
                  ) : (
                    <></>
                  )}
                  {elem.action === "input" ? (
                    <>
                      <td>{elem.items.step_name}</td>
                      <td>{elem.action}</td>
                      {elem.items.input_attr_name &&
                      elem.items.input_attr_value ? (
                        <td>
                          {elem.items.input_attr_name}=
                          {elem.items.input_attr_value}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td>{elem.items.input_text}</td>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* //!! new commands showcase */}
                  {elem.action === "submit" ? (
                    <>
                      <td>{elem.items.step_name}</td>
                      <td>{elem.action}</td>
                      <td></td>
                      <td></td>
                    </>
                  ) : (
                    <></>
                  )}
                  {elem.action === "scroll" &&
                  elem.items.scroll_to === "element" ? (
                    <>
                      {/* //!!! check this one for UI/UX of the scroll command */}
                      <td>{elem.items.step_name}</td>
                      <td>{elem.action}</td>
                      <td>{elem.items.scroll_to}</td>
                      <td>
                        {elem.items.scroll_attr_name}=
                        {elem.items.scroll_attr_value}
                      </td>
                    </>
                  ) : (
                    <></>
                  )}
                  {elem.action === "scroll" &&
                  elem.items.scroll_to === "location" ? (
                    <>
                      {/* //!!! check this one for UI/UX of the scroll command */}
                      <td>{elem.items.step_name}</td>
                      <td>{elem.action}</td>
                      <td>{elem.items.scroll_to}</td>
                      <td>{elem.items.scroll_location}</td>
                    </>
                  ) : (
                    <></>
                  )}
                  {elem.action === "execute_Javascript" ? (
                    <>
                      <td>{elem.items.step_name}</td>
                      <td>{elem.action}</td>
                      <td>{elem.items.jsCode}</td>
                      <td></td>
                    </>
                  ) : (
                    <></>
                  )}
                  {elem.action === "Bulk_URL_Open" ? (
                    <>
                      <td>{elem.items.step_name}</td>
                      <td>{elem.action}</td>
                      <td>{elem.items.URLs}</td>
                      <td></td>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* //!! end new commands showcase */}
                  <td>
                    <div className="scenarioScan_events">
                      <FontAwesomeIcon
                        className="text-red-600"
                        icon="fa-solid fa-trash"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleOpenmodalConfDeletion(index);
                        }}
                      />
                      <div className="scenarioScanTable_up-down_buttons">
                        <FontAwesomeIcon
                          icon="fa-solid fa-angle-up"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handlechangeOrder("up", index);
                          }}
                        />
                        <FontAwesomeIcon
                          icon="fa-solid fa-angle-down"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handlechangeOrder("down", index);
                          }}
                        />
                      </div>
                      <Tooltip title="Edit">
                        <FontAwesomeIcon
                          className="text-gray-600"
                          icon="fa-solid fa-pen"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleEditInstruction(index)}
                        />
                      </Tooltip>
                      <Tooltip title="Clone Command">
                        <FontAwesomeIcon
                          className="text-blue-600"
                          icon="fa-solid fa-clone"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDuplicateScanStep(index)}
                        />
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
        <Box sx={{ mt: 3 }}>
          <Paper
            square
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: 2,
              p: 1,
              bgcolor: grey[300],
            }}
          >
            {onEdit ? (
              <Button
                onClick={() => {
                  handleChangesSave();
                }}
                size="small"
                color="success"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            ) : (
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  handleStepsCountChange(true, 0);
                  if (scanData.length > 0) handleAddinstruction();
                  else alert("no scan data available!");
                }}
                startIcon={<AddIcon />}
              >
                Add
              </Button>
            )}
            {onEdit &&
            selectedRowToEdit !== null &&
            selectedRowData !== null ? (
              <></>
            ) : (
              <>
                <Button
                  size="small"
                  startIcon={<DeleteOutlineIcon />}
                  color="error"
                  variant="contained"
                >
                  Remove
                </Button>
              </>
            )}
          </Paper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
              p: 3,
              bgcolor: "#fff",
            }}
          >
            <div className="scenarioScan_rightAddCommand_Item">
              <FormControl size="small" fullWidth>
                <InputLabel>Command</InputLabel>
                <Select
                  label="Command"
                  value={selected}
                  fullWidth
                  onChange={(e) => {
                    handleSelectChange(e);
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ style: { border: "5px solid red" } }}
                  disabled={
                    onEdit &&
                    selectedRowToEdit !== null &&
                    selectedRowData !== null
                      ? true
                      : false
                  }
                >
                  {options
                    ? options.map((elem, index) => (
                        <MenuItem key={index} value={elem}>
                          {elem}
                        </MenuItem>
                      ))
                    : []}
                </Select>
              </FormControl>
            </div>
            {selected === "getUrl" ? (
              <>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      fullWidth
                      label="Step Name"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["step_name"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "step_name")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      fullWidth
                      label="Step Name"
                      type="text"
                      id="step_name"
                      defaultValue={"getUrl_" + StepsCount?.getUrl}
                    />
                  )}
                </div>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      fullWidth
                      label="URL"
                      type="text"
                      value={
                        selectedRowData !== null ? selectedRowData["url"] : ""
                      }
                      onChange={(e) => handleChangeInstructionData(e, "url")}
                    />
                  ) : (
                    <TextField
                      size="small"
                      fullWidth
                      label="URL"
                      type="text"
                      className="scenarioScan_rightAddCommand_item_input"
                      id="url"
                    />
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
            {selected === "click" ? (
              <>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      fullWidth
                      label="Step Name"
                      type="text"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["step_name"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "step_name")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      fullWidth
                      label="Step Name"
                      type="text"
                      className="scenarioScan_rightAddCommand_item_input"
                      id="step_name"
                      defaultValue={"click_" + StepsCount?.click}
                    />
                  )}
                </div>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      fullWidth
                      label="Attribute Name"
                      required
                      type="text"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["click_attr_name"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "click_attr_name")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      fullWidth
                      label="Attribute Name"
                      required
                      type="text"
                      id="click_attr_name"
                    />
                  )}
                </div>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      label="Attribute Value"
                      required
                      fullWidth
                      type="text"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["click_attr_value"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "click_attr_value")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      label="Attribute Value"
                      required
                      fullWidth
                      type="text"
                      id="click_attr_value"
                    />
                  )}
                </div>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      fullWidth
                      label="Tag Name"
                      type="text"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["click_tag"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "click_tag")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      fullWidth
                      label="Tag Name"
                      type="text"
                      id="click_tag"
                    />
                  )}
                </div>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      label="Index"
                      fullWidth
                      type="text"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["click_index"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "click_index")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      label="Index"
                      fullWidth
                      type="text"
                      id="click_index"
                    />
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
            {selected === "validate" ? (
              <>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      fullWidth
                      label="Step Name"
                      type="text"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["step_name"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "step_name")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      fullWidth
                      label="Step Name"
                      type="text"
                      id="step_name"
                      defaultValue={"validate_" + StepsCount?.validate}
                    />
                  )}
                </div>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      fullWidth
                      label="Text"
                      type="text"
                      className="scenarioScan_rightAddCommand_item_input"
                      value={
                        selectedRowData !== null ? selectedRowData["value"] : ""
                      }
                      onChange={(e) => handleChangeInstructionData(e, "value")}
                    />
                  ) : (
                    <TextField
                      size="small"
                      fullWidth
                      label="Text"
                      type="text"
                      className="scenarioScan_rightAddCommand_item_input"
                      id="value"
                    />
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
            {selected === "sleep" ? (
              <>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      fullWidth
                      label="Step Name"
                      type="text"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["step_name"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "step_name")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      fullWidth
                      label="Step Name"
                      type="text"
                      id="step_name"
                      defaultValue={"sleep_" + StepsCount?.sleep}
                    />
                  )}
                </div>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      fullWidth
                      label="Time (secs)"
                      type="text"
                      value={
                        selectedRowData !== null ? selectedRowData["value"] : ""
                      }
                      onChange={(e) => handleChangeInstructionData(e, "value")}
                    />
                  ) : (
                    <TextField
                      size="small"
                      fullWidth
                      label="Time (secs)"
                      type="text"
                      id="value"
                    />
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
            {selected === "input" ? (
              <>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      fullWidth
                      label="Step Name"
                      type="text"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["step_name"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "step_name")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      fullWidth
                      label="Step Name"
                      type="text"
                      id="step_name"
                      defaultValue={"input_" + StepsCount?.input}
                    />
                  )}
                </div>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      fullWidth
                      label="Attribute Name"
                      type="text"
                      required
                      value={
                        selectedRowData !== null
                          ? selectedRowData["input_attr_name"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "input_attr_name")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      fullWidth
                      label="Attribute Name"
                      type="text"
                      required
                      id="input_attr_name"
                    />
                  )}
                </div>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      required
                      fullWidth
                      label="Attribute Value"
                      type="text"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["input_attr_value"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "input_attr_value")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      fullWidth
                      label="Attribute Value"
                      type="text"
                      required
                      id="input_attr_value"
                    />
                  )}
                </div>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      fullWidth
                      label="Tag Name"
                      type="text"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["input_tag"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "input_tag")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      fullWidth
                      label="Tag Name"
                      type="text"
                      id="input_tag"
                    />
                  )}
                </div>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      fullWidth
                      label="Index"
                      type="text"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["input_index"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "input_index")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      fullWidth
                      label="Index"
                      type="text"
                      id="input_index"
                    />
                  )}
                </div>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      label="Text"
                      fullWidth
                      type="text"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["input_text"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "input_text")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      fullWidth
                      label="Text"
                      type="text"
                      id="input_text"
                    />
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
            {/* //**! here add new commands **/}
            {selected === "submit" ? (
              <>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      label="Step Name"
                      fullWidth
                      type="text"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["step_name"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "step_name")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      fullWidth
                      label="Step Name"
                      type="text"
                      id="step_name"
                      defaultValue={"submit" + StepsCount?.submit}
                    />
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
            {selected === "scroll" ? (
              <>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      fullWidth
                      label="Step Name"
                      type="text"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["step_name"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "step_name")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      label="Step Name"
                      fullWidth
                      type="text"
                      id="step_name"
                      defaultValue={"scroll" + StepsCount?.scroll}
                    />
                  )}
                </div>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <FormControl size="small" fullWidth>
                      <InputLabel>Scroll to</InputLabel>

                      <Select
                        label="Scroll to"
                        id="demo-simple-select"
                        value={
                          selectedRowData !== null
                            ? selectedRowData["scroll_to"]
                            : ""
                        }
                        onChange={(e) => {
                          handleChangeInstructionData(e, "scroll_to");
                        }}
                        MenuProps={MenuProps}
                      >
                        {scrollTypes
                          ? scrollTypes.map((elem, index) => (
                              <MenuItem key={index} value={elem}>
                                {elem}
                              </MenuItem>
                            ))
                          : []}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl size="small" fullWidth>
                      <InputLabel>Scroll to</InputLabel>
                      <Select
                        label="Scroll to"
                        id="demo-simple-select"
                        value={scroll}
                        onChange={(e) => {
                          handleScrollTypeChange(e);
                        }}
                        MenuProps={MenuProps}
                      >
                        {scrollTypes
                          ? scrollTypes.map((elem, index) => (
                              <MenuItem key={index} value={elem}>
                                {elem}
                              </MenuItem>
                            ))
                          : []}
                      </Select>
                    </FormControl>
                  )}
                </div>
                {!onEdit && scroll === "location" ? (
                  <>
                    <div className="scenarioScan_rightAddCommand_Item">
                      {onEdit &&
                      selectedRowToEdit !== null &&
                      selectedRowData !== null ? (
                        <FormControl size="small" fullWidth>
                          <InputLabel>Scroll Location</InputLabel>

                          <Select
                            label="Scroll Location"
                            id="scroll_location"
                            value={
                              selectedRowData !== null
                                ? selectedRowData["scroll_location"]
                                : ""
                            }
                            onChange={(e) =>
                              handleChangeInstructionData(e, "scroll_location")
                            }
                            MenuProps={MenuProps}
                          >
                            {["Start", "Center", "End"].map((elem, index) => (
                              <MenuItem key={index} value={elem}>
                                {elem}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <FormControl size="small" fullWidth>
                          <InputLabel>Scroll Location</InputLabel>
                          <Select
                            label="Scroll Location"
                            id="scroll_location"
                            value={scrollLocation}
                            onChange={(e) => {
                              handleChangeScrollLocation(e);
                            }}
                            MenuProps={MenuProps}
                            disabled={
                              onEdit &&
                              selectedRowToEdit !== null &&
                              selectedRowData !== null
                                ? true
                                : false
                            }
                          >
                            {["Start", "Center", "End"].map((elem, index) => (
                              <MenuItem key={index} value={elem}>
                                {elem}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {!onEdit && scroll === "element" ? (
                  <>
                    <div className="scenarioScan_rightAddCommand_Item">
                      {onEdit &&
                      selectedRowToEdit !== null &&
                      selectedRowData !== null ? (
                        <TextField
                          size="small"
                          fullWidth
                          label=" Attribute name"
                          type="text"
                          value={
                            selectedRowData !== null
                              ? selectedRowData["scroll_attr_name"]
                              : ""
                          }
                          onChange={(e) =>
                            handleChangeInstructionData(e, "scroll_attr_name")
                          }
                        />
                      ) : (
                        <TextField
                          size="small"
                          fullWidth
                          label=" Attribute name"
                          type="text"
                          id="scroll_attr_name"
                        />
                      )}
                    </div>
                    <div className="scenarioScan_rightAddCommand_Item">
                      {onEdit &&
                      selectedRowToEdit !== null &&
                      selectedRowData !== null ? (
                        <TextField
                          size="small"
                          fullWidth
                          label="Attribute Value"
                          type="text"
                          value={
                            selectedRowData !== null
                              ? selectedRowData["scroll_attr_value"]
                              : ""
                          }
                          onChange={(e) =>
                            handleChangeInstructionData(e, "scroll_attr_value")
                          }
                        />
                      ) : (
                        <TextField
                          size="small"
                          fullWidth
                          label="Attribute Value"
                          type="text"
                          id="scroll_attr_value"
                        />
                      )}
                    </div>
                    <div className="scenarioScan_rightAddCommand_Item">
                      {onEdit &&
                      selectedRowToEdit !== null &&
                      selectedRowData !== null ? (
                        <TextField
                          size="small"
                          label="Tag name"
                          fullWidth
                          type="text"
                          value={
                            selectedRowData !== null
                              ? selectedRowData["scroll_tag_name"]
                              : ""
                          }
                          onChange={(e) =>
                            handleChangeInstructionData(e, "scroll_tag_name")
                          }
                        />
                      ) : (
                        <TextField
                          size="small"
                          label="Tag name"
                          fullWidth
                          type="text"
                          id="scroll_tag_name"
                        />
                      )}
                    </div>
                    <div className="scenarioScan_rightAddCommand_Item">
                      {onEdit &&
                      selectedRowToEdit !== null &&
                      selectedRowData !== null ? (
                        <TextField
                          size="small"
                          fullWidth
                          label="Scroll Index"
                          type="text"
                          value={
                            selectedRowData !== null
                              ? selectedRowData["scroll_index"]
                              : ""
                          }
                          onChange={(e) =>
                            handleChangeInstructionData(e, "scroll_index")
                          }
                        />
                      ) : (
                        <TextField
                          size="small"
                          label="Scroll Index"
                          fullWidth
                          type="text"
                          id="scroll_index"
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {onEdit && selectedRowData?.scroll_to === "location" ? (
                  <>
                    <div className="scenarioScan_rightAddCommand_Item">
                      {onEdit &&
                      selectedRowToEdit !== null &&
                      selectedRowData !== null ? (
                        <FormControl size="small">
                          <InputLabel>Scroll Location</InputLabel>

                          <Select
                            label="Scroll Location"
                            id="scroll_location"
                            value={
                              selectedRowData !== null
                                ? selectedRowData["scroll_location"]
                                : ""
                            }
                            onChange={(e) =>
                              handleChangeInstructionData(e, "scroll_location")
                            }
                            MenuProps={MenuProps}
                            inputProps={{ style: { border: "5px solid red" } }}
                          >
                            {["Start", "Center", "End"].map((elem, index) => (
                              <MenuItem key={index} value={elem}>
                                {elem}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <FormControl size="small">
                          <InputLabel>Scroll Location</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="scroll_location"
                            value={scrollLocation}
                            onChange={(e) => {
                              handleChangeScrollLocation(e);
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ style: { border: "5px solid red" } }}
                            disabled={
                              onEdit &&
                              selectedRowToEdit !== null &&
                              selectedRowData !== null
                                ? true
                                : false
                            }
                          >
                            {["Start", "Center", "End"].map((elem, index) => (
                              <MenuItem key={index} value={elem}>
                                {elem}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {onEdit && selectedRowData?.scroll_to === "element" ? (
                  <>
                    <div className="scenarioScan_rightAddCommand_Item">
                      {onEdit &&
                      selectedRowToEdit !== null &&
                      selectedRowData !== null ? (
                        <TextField
                          size="small"
                          fullWidth
                          label=" Attribute name"
                          type="text"
                          value={
                            selectedRowData !== null
                              ? selectedRowData["scroll_attr_name"]
                              : ""
                          }
                          onChange={(e) =>
                            handleChangeInstructionData(e, "scroll_attr_name")
                          }
                        />
                      ) : (
                        <TextField
                          size="small"
                          label=" Attribute name"
                          fullWidth
                          type="text"
                          id="scroll_attr_name"
                        />
                      )}
                    </div>
                    <div className="scenarioScan_rightAddCommand_Item">
                      {onEdit &&
                      selectedRowToEdit !== null &&
                      selectedRowData !== null ? (
                        <TextField
                          size="small"
                          fullWidth
                          label="Attribute Value"
                          type="text"
                          value={
                            selectedRowData !== null
                              ? selectedRowData["scroll_attr_value"]
                              : ""
                          }
                          onChange={(e) =>
                            handleChangeInstructionData(e, "scroll_attr_value")
                          }
                        />
                      ) : (
                        <TextField
                          size="small"
                          fullWidth
                          label="Attribute Value"
                          type="text"
                          id="scroll_attr_value"
                        />
                      )}
                    </div>
                    <div className="scenarioScan_rightAddCommand_Item">
                      {onEdit &&
                      selectedRowToEdit !== null &&
                      selectedRowData !== null ? (
                        <TextField
                          size="small"
                          fullWidth
                          label="Tag name"
                          type="text"
                          value={
                            selectedRowData !== null
                              ? selectedRowData["scroll_tag_name"]
                              : ""
                          }
                          onChange={(e) =>
                            handleChangeInstructionData(e, "scroll_tag_name")
                          }
                        />
                      ) : (
                        <TextField
                          size="small"
                          fullWidth
                          label="Tag name"
                          type="text"
                          id="scroll_tag_name"
                        />
                      )}
                    </div>
                    <div className="scenarioScan_rightAddCommand_Item">
                      {onEdit &&
                      selectedRowToEdit !== null &&
                      selectedRowData !== null ? (
                        <TextField
                          size="small"
                          fullWidth
                          label="Scroll Index"
                          type="text"
                          value={
                            selectedRowData !== null
                              ? selectedRowData["scroll_index"]
                              : ""
                          }
                          onChange={(e) =>
                            handleChangeInstructionData(e, "scroll_index")
                          }
                        />
                      ) : (
                        <TextField
                          size="small"
                          fullWidth
                          label="Scroll Index"
                          type="text"
                          id="scroll_index"
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {selected === "execute_Javascript" ? (
              <>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      label="Step Name"
                      fullWidth
                      type="text"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["step_name"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "step_name")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      label="Step Name"
                      fullWidth
                      type="text"
                      id="step_name"
                      defaultValue={
                        "execute_Javascript" + StepsCount?.execute_Javascript
                      }
                    />
                  )}
                </div>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      multiline
                      fullWidth
                      rows={4}
                      label="JS Code"
                      type="text"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["jsCode"]
                          : ""
                      }
                      onChange={(e) => handleChangeInstructionData(e, "jsCode")}
                    />
                  ) : (
                    <TextField
                      size="small"
                      multiline
                      fullWidth
                      rows={4}
                      label="JS Code"
                      type="text"
                      id="jsCode"
                    />
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
            {selected === "Bulk_URL_Open" ? (
              <>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      fullWidth
                      label="Step Name"
                      type="text"
                      value={
                        selectedRowData !== null
                          ? selectedRowData["step_name"]
                          : ""
                      }
                      onChange={(e) =>
                        handleChangeInstructionData(e, "step_name")
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      fullWidth
                      label="Step Name"
                      type="text"
                      id="step_name"
                      defaultValue={
                        "execute_Javascript" + StepsCount?.Bulk_URL_Open
                      }
                    />
                  )}
                </div>
                <div className="scenarioScan_rightAddCommand_Item">
                  {onEdit &&
                  selectedRowToEdit !== null &&
                  selectedRowData !== null ? (
                    <TextField
                      size="small"
                      multiline
                      fullWidth
                      label="URLs"
                      rows={4}
                      type="text"
                      value={
                        selectedRowData !== null ? selectedRowData["URLs"] : ""
                      }
                      onChange={(e) => handleChangeInstructionData(e, "URLs")}
                    />
                  ) : (
                    <TextField
                      size="small"
                      multiline
                      fullWidth
                      label="URLs"
                      rows={4}
                      type="text"
                      id="URLs"
                    />
                  )}
                </div>
              </>
            ) : (
              <></>
            )}

            {/* //**! end **/}
            {onEdit &&
            selectedRowToEdit !== null &&
            selectedRowData !== null ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginTop: "20px",
                  gap: "10px",
                }}
              >
                {selected === "input" ? (
                  <div className="scenarioScan_rightAddCommand_Item">
                    <label
                      className="scenarioScan_rightAddCommand_Item_label"
                      htmlFor=""
                    >
                      Sensitive/Personal
                    </label>
                    <input
                      id="sensitiveCheckBox"
                      type="checkBox"
                      checked={selectedRowData.sensitiveCheckBox}
                      onChange={(e) =>
                        handleChangeInstructionData(e, "sensitiveCheckBox")
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div className="scenarioScan_rightAddCommand_Item">
                  <label
                    className="scenarioScan_rightAddCommand_Item_label"
                    htmlFor=""
                  >
                    Mandatory
                  </label>
                  <input
                    id="mandatoryCheckBox"
                    type="checkBox"
                    checked={selectedRowData.mandatoryCheckBox}
                    onChange={(e) =>
                      handleChangeInstructionData(e, "mandatoryCheckBox")
                    }
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {!onEdit ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginTop: "20px",
                  gap: "10px",
                }}
              >
                {selected === "input" ? (
                  <div className="scenarioScan_rightAddCommand_Item">
                    <label
                      className="scenarioScan_rightAddCommand_Item_label"
                      htmlFor=""
                    >
                      Sensitive/Personal
                    </label>
                    <input id="sensitiveCheckBox" type="checkBox" />
                  </div>
                ) : (
                  <></>
                )}
                <div className="scenarioScan_rightAddCommand_Item">
                  <label
                    className="scenarioScan_rightAddCommand_Item_label"
                    htmlFor=""
                  >
                    Mandatory
                  </label>
                  <input id="mandatoryCheckBox" type="checkBox" />
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="scenarioScan_rightAddCommand_Item">
              <label
                className="scenarioScan_rightAddCommand_Item_label"
                htmlFor=""
              >
                Screenshot
              </label>
              {onEdit &&
              selectedRowToEdit !== null &&
              selectedRowData !== null ? (
                <input
                  id="screenShotCheckBox"
                  type="checkBox"
                  checked={selectedRowData?.screenShotCheckBox}
                  onChange={(e) =>
                    handleChangeInstructionData(e, "screenShotCheckBox")
                  }
                />
              ) : (
                <input id="screenShotCheckBox" type="checkBox" />
              )}
            </div>
          </Box>
        </Box>
      </div>

      <Dialog
        open={openModalConfDeletion}
        onClose={handleClosemodalConfDeletion}
      >
        <DialogTitle>Delete Instruction</DialogTitle>
        <DialogContent>
          <Alert severity="error">
            You are about to delete{" "}
            <Typography fontWeight={600}>{elementToRemove}.</Typography>
            Would you like to proceed with the deletion?
          </Alert>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: 2,
            }}
          >
            <Button
              color="inherit"
              variant="contained"
              size="small"
              onClick={handleClosemodalConfDeletion}
            >
              Cancel
            </Button>
            <Button
              onClick={handleRemoveAfterConf}
              variant="contained"
              size="small"
              color="error"
            >
              Delete
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ marginLeft: "3%", marginBottom: "20px" }}>
            You are about to delete <b> {elementToRemove}</b>
            . <br /> Would you like to proceed with the deletion?
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
            className="scanNamingButton_container"
          >
            <button
              style={{ cursor: "pointer" }}
              className="scanNamingButton"
              onClick={handleRemoveAfterConf}
            >
              Delete
            </button>
            <button
              style={{ cursor: "pointer" }}
              className="scanNamingButton"
              onClick={handleClosemodalConfDeletion}
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ScenarioScanTable;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#ffffff",
  boxShadow: 24,
  p: 3,
  borderRadius: "5px",
  border: 0,
  textAlign: "center",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
