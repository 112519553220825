import {
  Alert,
  AppBar,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { blue, green, orange, red } from "@mui/material/colors";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import DataObjectSharpIcon from "@mui/icons-material/DataObjectSharp";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import DownloadForOfflineSharpIcon from "@mui/icons-material/DownloadForOfflineSharp";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import StyleSharpIcon from "@mui/icons-material/StyleSharp";
import SummarizeSharpIcon from "@mui/icons-material/SummarizeSharp";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import VerifiedUserSharpIcon from "@mui/icons-material/VerifiedUserSharp";
import { env } from "../../config";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ReportsNav = ({ setVid }) => {
  const [crawlsScans, setCrawlsScans] = useState([]);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [validations, setValidations] = useState([]);
  const [selectedValidations, setSelectedValidations] = useState({});
  const [startDelete, setStartDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteComplete, setDeleteComplete] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  const uniqueTags = useSelector((state) => state.activeReport.uniqueTags);
  const reportName = useSelector((state) => state.activeReport.reportName);
  const [selectedTechnologies, setSelectecTechnologies] = useState([]);
  const [reportGen, setReportGen] = useState(false);
  const [reportGenLoader, setReportGenLoader] = useState(false);
  const [reportGenError, setReportGenError] = useState(null);

  const handleCloseReportGenModal = () => {
    setReportGen(false);
    setSelectecTechnologies([]);
  };

  const handleStartDelete = () => {
    setStartDelete(true);
  };

  const handleClose = () => {
    setStartDelete(false);
    setDeleteError(false);
  };

  const handleValidationChange = (event) => {
    const value = event.target.value;
    setSelectedValidations(value);
  };
  let navigate = useNavigate();
  const handleRoute = useCallback(
    (val) => {
      navigate(val);
    },
    [navigate]
  );

  const location = useLocation();

  const reportsTabs = [
    {
      title: "Tags Summary",
      navigate: `/report/tagsummary?id=${id}`,
      active: location.pathname.includes("/tagsummary"),
    },
    {
      title: "Pages / Commands",
      navigate: `/report/commands?id=${id}`,
      active: location.pathname.includes("/commands"),
    },
    {
      title: "Validations",
      navigate: `/report/validation?id=${id}`,
      active: location.pathname.includes("/validation"),
    },
    {
      title: "Cookies",
      navigate: `/report/cookies?id=${id}`,
      active: location.pathname.includes("/cookies"),
    },
    {
      title: "Log",
      navigate: `/report/log?id=${id}`,
      active: location.pathname.includes("/log"),
    },
  ];

  useEffect(() => {
    if (id) {
      if (crawlsScans.length === 0) {
        getValidations();
      }
    }
  }, [id]);

  const getValidations = async () => {
    await fetch(`${env.REACT_APP_API_HOST}get_validations`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.isAuthenticated === "False") {
          handleRoute("/login");
          return;
        }
        setValidations(response.data);
        setSelectedValidations(response.data[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const setNewValidation = async () => {
    const validationId = selectedValidations.validation_id;
    if (validationId < 0) return;
    const data = {
      reportId: id,
      validationId: selectedValidations.validation_id,
    };
    await fetch(`${env.REACT_APP_API_HOST}set_validation_to_scan_or_crawl/`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.isAuthenticated === "False") {
          handleRoute("/login");
          return;
        }
        if (response.Success === "True") {
          setVid(selectedValidations.validation_id);
          handleRoute(`/report/validation?id=${id}`);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `${env.REACT_APP_API_HOST}deleteReport/${id}`,
        { method: "GET", credentials: "include" }
      );
      if (!response.ok) throw new Error(resData.error);
      const resData = response.json();
      setDeleteComplete(true);
      setTimeout(() => {
        setStartDelete(false);
        setDeleteComplete(false);
        navigate("/scans-reports");
      }, 2000);
    } catch (resError) {
      setDeleteError(`${resError}`);
    } finally {
      setDeleting(false);
    }
  };

  const handleReportSubmit = async (event) => {
    event.preventDefault();
    setReportGenLoader(true);
    setReportGenError(null);
    const fd = new FormData(event.currentTarget);
    const data = {};
    fd.forEach((i, j) => {
      data[j] = true;
    });
    if (Object.keys(data).length === 0 && selectedTechnologies.length === 0) {
      setReportGenError("Select an item to add to your report!");
      setReportGenLoader(false);
      return;
    }
    data["technologies"] = selectedTechnologies;
    try {
      const res = await fetch(
        `${env.REACT_APP_API_HOST}reports/get-files/${id}/`,
        {
          method: "post",
          credentials: "include",
          body: JSON.stringify(data),
        }
      );
      if (!res.ok) throw new Error(res.statusText);
      const resData = await res.blob();
      /** Deal with download */
      const aElem = document.createElement("a");
      aElem.setAttribute("download", `${reportName}`);
      const href = URL.createObjectURL(resData);
      aElem.href = href;
      aElem.setAttribute("target", "_blank");
      aElem.click();
      URL.revokeObjectURL(href);
      aElem.remove();
      /** Housekeeping */
      handleCloseReportGenModal();
    } catch (err) {
      setReportGenError(err.message);
    } finally {
      setReportGenLoader(false);
    }
  };

  const handleSelectTechnology = (tech, add) => {
    setSelectecTechnologies((_techs) => {
      if (add) {
        if (_techs.includes(tech)) {
          return _techs;
        } else {
          return [..._techs, tech];
        }
      } else {
        return [..._techs.filter((t) => t === tech)];
      }
    });
    setTimeout(() => {
      console.log(selectedTechnologies);
    }, 1);
  };

  const downloadModal = (
    <>
      <Dialog
        open={reportGen}
        handleClose={handleCloseReportGenModal}
        fullScreen
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseReportGenModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h5">Generate Report</Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box component="form" onSubmit={handleReportSubmit}>
            {reportGenError && (
              <Alert severity="error" sx={{ my: 2 }}>
                {reportGenError}
              </Alert>
            )}
            <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Excel Workbook
                </Typography>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: blue[400] }}>
                        <SummarizeSharpIcon />
                      </Avatar>
                    }
                    title={<Typography>Summaries</Typography>}
                  />
                  <CardContent>
                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton dense>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              tabIndex={-1}
                              disableRipple
                              name="unique_tags"
                            />
                          </ListItemIcon>
                          <ListItemText primary={`Summary + Unique Tags`} />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton dense>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              tabIndex={-1}
                              disableRipple
                              name="errors_and_warnings"
                            />
                          </ListItemIcon>
                          <ListItemText primary={`Errors and Warnings`} />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton dense>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              tabIndex={-1}
                              disableRipple
                              name="cookies"
                            />
                          </ListItemIcon>
                          <ListItemText primary={`Cookies`} />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: green[400] }}>
                        <StyleSharpIcon />
                      </Avatar>
                    }
                    title={<Typography>Tags and Parameters</Typography>}
                  />
                  <CardContent>
                    <List disablePadding>
                      {uniqueTags &&
                        uniqueTags.map((t, i) => (
                          <>
                            <ListItem disablePadding>
                              <ListItemButton dense>
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                    onChange={(e) => {
                                      console.log(
                                        e.target.value,
                                        e.target.checked
                                      );
                                      handleSelectTechnology(
                                        t.technology_name,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemAvatar>
                                  <Avatar src={t.technology_icon} />
                                </ListItemAvatar>
                                <ListItemText primary={t.technology_name} />
                              </ListItemButton>
                            </ListItem>
                          </>
                        ))}
                    </List>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: orange[400] }}>
                        <VerifiedUserSharpIcon />
                      </Avatar>
                    }
                    title={<Typography>Validations</Typography>}
                  />
                  <CardContent>
                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton dense>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              tabIndex={-1}
                              disableRipple
                              name="validations"
                            />
                          </ListItemIcon>
                          <ListItemText primary={`Validations`} />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  JSON Object
                </Typography>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: orange[400] }}>
                        <VerifiedUserSharpIcon />
                      </Avatar>
                    }
                    title={<Typography>Data Objects</Typography>}
                  />
                  <CardContent>
                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton dense>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              tabIndex={-1}
                              disableRipple
                              name="data_layer"
                            />
                          </ListItemIcon>
                          <ListItemText primary={`Data Layer`} />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Box>
            </Box>
            <Box
              sx={{
                mt: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {reportGenLoader ? (
                <LinearProgress />
              ) : (
                <Button type="submit" variant="contained">
                  Get Report
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );

  const deleteModal = (
    <>
      <Dialog open={startDelete} onClose={handleClose}>
        <DialogTitle>{`Delete Report?`}</DialogTitle>
        {deleteComplete ? (
          <>
            <DialogContent>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <TaskAltIcon fontSize="large" color="success" />
                <Typography>Deleted</Typography>
              </Box>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogContent>
              <Box textAlign={"center"}>
                <Typography align="center" color={red[600]} variant="subtitle">
                  {deleteError}
                </Typography>
              </Box>
              <DialogContentText id="alert-dialog-description">
                You're about to delete this report. Do you want to proceed?
              </DialogContentText>
            </DialogContent>
            {!deleting ? (
              <DialogActions>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  color="error"
                  startIcon={<DeleteSharpIcon />}
                  onClick={handleDelete}
                  autoFocus
                  variant="contained"
                >
                  Delete
                </Button>
              </DialogActions>
            ) : (
              <>
                <LinearProgress color="error" />
              </>
            )}
          </>
        )}
      </Dialog>
    </>
  );

  return (
    <>
      {downloadModal}
      {deleteModal}
      <Box
        sx={{ zIndex: 99 }}
        className="pb-4 flex flex-wrap flex-grow gap-3 bg-transparent justify-between"
      >
        <div className="flex gap-4 flex-wrap">
          {reportsTabs.map((item, index) => {
            return (
              <button
                key={`reports-nav-tabs-item-${index}`}
                className={`py-2 px-6 rounded-lg ${
                  item.active
                    ? "bg-green text-white"
                    : "bg-white hover:bg-green/25 transition-colors duration-300 text-black"
                }`}
                onClick={() => navigate(item.navigate)}
              >
                {item.title}
              </button>
            );
          })}
        </div>
        <Paper
          sx={{
            display: "flex",
            gap: 1,
            p: 1,
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          {validations.length > 0 && (
            <>
              <FormControl size="small" sx={{ width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Validations
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={selectedValidations}
                  onChange={handleValidationChange}
                  input={<OutlinedInput label="Validations" />}
                  renderValue={(selected) => selected.validation_name}
                  MenuProps={MenuProps}
                >
                  {validations.map((item, index) => (
                    <MenuItem value={item} key={`nav-validation-item-${index}`}>
                      <ListItemText primary={item.validation_name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button onClick={() => setNewValidation()} variant="outlined">
                <Typography variant="caption">Process Validation</Typography>
              </Button>
            </>
          )}
          <Divider orientation="vertical" />
          <Tooltip title="Download Report">
            <IconButton
              onClick={() => setReportGen(true)}
              color="primary"
              sx={{ ":hover": { bgcolor: green["500"], color: "#fff" } }}
            >
              <DownloadForOfflineSharpIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Report">
            <IconButton
              onClick={handleStartDelete}
              sx={{ ":hover": { bgcolor: red["500"], color: "#fff" } }}
              color="error"
            >
              <DeleteSharpIcon />
            </IconButton>
          </Tooltip>
        </Paper>
      </Box>
    </>
  );
};

export default ReportsNav;
