import { Box, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import ForgotPasswordForm from "./ForgotPasswordForm";
import Grid from "@mui/material/Grid";
import LoginForm from "./LoginForm";
import Logo from "../../assets/login_logo.png";
import NewPasswordForm from "./NewPasswordForm";
import Paper from "@mui/material/Paper";
import PricingTable from "../../components/PricingTable/PricingTable";
import SignupForm from "./SignupForm";
import authService from "../../services/authService";
import mainLogo from "../../assets/logo-final.png";
import { useEffect } from "react";

export default function Auth() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const searchParam = searchParams.get("authMode");

  useEffect(() => {
    if (!searchParam) {
      navigate("?authMode=login");
    }
    const login = authService.checkLogin();
    login.then((isLoggedIn) => {
      if (isLoggedIn) navigate("/scan/scenario-scan");
    });
  }, [searchParams]);

  return (
    <Grid container component="main" sx={{ fontFamily: "cabin" }}>
      <CssBaseline />
      <Grid
        className="hidden lg:block"
        item
        xs={0}
        sm={0}
        md={0}
        lg={8}
        xl={9}
        sx={{
          backgroundRepeat: "no-repeat",
          backgroundColor: "#12b922",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            gap: 4,
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <a href="http://www.taglab.net">
            <img src={Logo} height={"30rem"} alt="taglab" />
          </a>
        </Box>
      </Grid>
      <Grid
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        minHeight={"100vh"}
        item
        xs={12}
        sm={12}
        md={12}
        lg={4}
        xl={3}
        component={Paper}
        elevation={6}
        square
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <a href="http://www.taglab.net">
              <img src={mainLogo} alt="taglab" width={200} />
            </a>
          </Box>
          {
            {
              login: <LoginForm />,
              signup: <SignupForm />,
              resetRequest: <ForgotPasswordForm />,
              reset: <NewPasswordForm />,
            }[searchParam]
          }
        </Box>
      </Grid>
    </Grid>
  );
}
