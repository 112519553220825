import "./crawlStyle.css";

import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { green, grey, red, yellow } from "@mui/material/colors";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AddchartSharpIcon from "@mui/icons-material/AddchartSharp";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import React from "react";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import ScanDelete from "../../components/Scans/ScanDelete.jsx";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import { env } from "../../config.js";

function CrawlScan() {
  const [scanData, setScanData] = useState([]);
  const [maxCrawlerLimit, setMaxCrawlerLimit] = useState();
  const [submitMessage, setSubmitMessage] = useState(null);
  const [crawls, setCrawls] = useState([]);
  const [crawlSearch, setCrawlSearch] = useState("");
  const [activeCrawl, setActiveCrawl] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDelete, setStartDelete] = useState(false);
  const [crawlToDelete, setCrawlToDelete] = useState(null);
  const navigate = useNavigate();

  const handleStartDelete = (crawl) => {
    setCrawlToDelete(crawl);
    setStartDelete(true);
    setCrawls((crawls) => crawls.filter((c) => c.crawl_id !== crawl.crawl_id));
  };

  const getCrawls = async () => {
    try {
      const res = await fetch(`${env.REACT_APP_API_HOST}scans/crawls/`, {
        method: "GET",
        credentials: "include",
      });
      const resData = await res.json();
      if (!res.ok) throw new Error();
      setCrawls(resData);
    } catch (resError) {}
  };

  const getScanData = useCallback(async () => {
    const req = await fetch(`${env.REACT_APP_API_HOST}get_scans/`, {
      method: "GET",
      credentials: "include",
    });
    const response = await req.json();
    if (response.isAuthenticated === "False") {
      navigate("/login");
      return;
    }
    setScanData(response);
  }, []);

  const getCrawlerHardLimit = () =>
    fetch(`${env.REACT_APP_API_HOST}default_crawler_page_limit/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setMaxCrawlerLimit(data);
      });

  useEffect(() => {
    getCrawls();
    getScanData();
    getCrawlerHardLimit();
  }, []);

  useEffect(() => {
    // activeCrawl
    const activeCrawlId = searchParams.get("activeCrawl");
    setActiveCrawl(crawls.find((c) => c.crawl_id == activeCrawlId));
  }, [crawls, searchParams]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = {};
    let scanId = activeCrawl ? activeCrawl.crawl_id : undefined;
    let scanType = "crawl";

    formData.forEach((v, k) => {
      data[k] = v;
    });

    try {
      if (!activeCrawl.crawl_id) {
        // Check name
        setSubmitMessage({
          severity: "info",
          message: "Checking Unique Crawl Name",
        });

        const res = await fetch(
          `${env.REACT_APP_API_HOST}check_crawler_name/`,
          {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({ crawl_name: formData.get("crawl_name") }),
          }
        );
        const resData = await res.json();
        if (!res.ok) {
          throw new Error("There was probleming validating the name");
        }
        if (!resData.uniqueName) {
          throw new Error("The crawl name exists. Use a unique name");
        }
      }

      if (activeCrawl && activeCrawl.crawl_id) {
        // update scan
        setSubmitMessage({
          severity: "info",
          message: `Updating Scan`,
        });
        const saveRes = await fetch(
          `${env.REACT_APP_API_HOST}scans/crawls/${activeCrawl.crawl_id}/`,
          {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify(data),
          }
        );
        const saveResData = await saveRes.json();
        if (!saveRes.ok) throw new Error(saveResData.error);
        setSubmitMessage({
          severity: "success",
          message: `${saveResData.message}`,
        });
      } else {
        // New scan
        setSubmitMessage({
          severity: "info",
          message: formData.get("launch_on_save")
            ? `Saving and Lauching Scan`
            : `Saving Scan`,
        });
        const saveRes = await fetch(`${env.REACT_APP_API_HOST}scans/crawls/`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify(data),
        });
        const saveResData = await saveRes.json();
        if (!saveRes.ok) throw new Error(saveResData.error);
        scanId = saveResData.crawl_id;
        event.target.reset();
        getCrawls();
        setSubmitMessage({
          severity: "success",
          message: `${saveResData.message}`,
        });
      }

      if (formData.get("launch_on_save")) {
        // check points
        setSubmitMessage({
          severity: "info",
          message: "Checking available storage and points",
        });
        const pointsRes = await fetch(
          `${env.REACT_APP_API_HOST}check_points/`,
          {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
              scenario_to_run_before: formData.get("scenario_to_run_before"),
            }),
          }
        );
        const pointsResData = await pointsRes.json();
        if (!pointsRes.ok) throw new Error(pointsResData.error);

        // launchScan
        setSubmitMessage({
          severity: "info",
          message: "Launching Scan. Track results on the reports page",
        });
        setTimeout(() => {
          setSubmitMessage(null);
          navigate(`/scans-reports?scanId=${scanId}&scanType=crawl`);
        }, 2000);
        const response = await fetch(`${env.REACT_APP_API_HOST}scans/launch/`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({ scanId, scanType }),
        });
        const resData = await response.json();
        if (!response.ok) throw new Error(resData.error);
      }
    } catch (resError) {
      setSubmitMessage({ severity: "error", message: resError.message });
    } finally {
      setTimeout(() => setSubmitMessage(null), 15000);
    }
  };

  const handleClearSearch = (e) => {
    e.target.parentElement.previousSibling.value = "";
    setCrawlSearch("");
  };

  const handleSelectCrawl = useCallback((id) => {
    // navigate(`/scan/crawl-scan?active=${id}`);
    setSearchParams({ activeCrawl: id });
  }, []);

  const handleClearActiveCrawl = () => {
    setActiveCrawl(null);
    setSearchParams({});
  };

  const handleFormChange = (name, value) => {
    setActiveCrawl((ac) => {
      ac = ac ? ac : {};
      ac[name] = value;
      return { ...ac };
    });
  };

  return (
    <>
      {startDelete && crawlToDelete && (
        <ScanDelete
          scanId={crawlToDelete.crawl_id}
          scanName={crawlToDelete.crawler_name}
          scanType={"crawl"}
          setStartDelete={setStartDelete}
        />
      )}
      <Typography variant="h6" color="primary" gutterBottom>
        Crawl Scan
      </Typography>
      <Grid container columnSpacing={2}>
        <Grid item xs={0} md={3}>
          <Paper>
            <Box sx={{ p: 2 }}>
              <TextField
                onChange={(e) => {
                  setCrawlSearch(e.target.value);
                }}
                variant="standard"
                fullWidth
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchSharpIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <Tooltip title="Clear Search">
                      <InputAdornment
                        position="end"
                        component="button"
                        onClick={handleClearSearch}
                      >
                        <ClearSharpIcon />
                      </InputAdornment>
                    </Tooltip>
                  ),
                }}
              />
            </Box>
            <List>
              {crawls
                .filter((c) =>
                  c.crawler_name
                    .toLowerCase()
                    .includes(crawlSearch.toLowerCase())
                )
                .map((c, i) => (
                  <ListItem
                    divider
                    key={i}
                    disablePadding
                    secondaryAction={
                      <IconButton
                        onClick={() => handleStartDelete(c)}
                        sx={{
                          ":hover": { color: red[400], bgcolor: red[100] },
                        }}
                      >
                        <DeleteOutlineSharpIcon />
                      </IconButton>
                    }
                  >
                    <ListItemButton
                      sx={{
                        "&.MuiListItemButton-root.Mui-selected": {
                          bgcolor: green[200],
                        },
                      }}
                      selected={
                        activeCrawl && c.crawl_id === activeCrawl.crawl_id
                      }
                      onClick={() => handleSelectCrawl(c.crawl_id)}
                    >
                      <ListItemText>
                        <Typography variant="body2">
                          {c.crawler_name}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Card>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: activeCrawl ? red[400] : green[400] }}>
                  {activeCrawl ? <EditSharpIcon /> : <AddchartSharpIcon />}
                </Avatar>
              }
              title={
                <Typography color="primary">
                  {activeCrawl
                    ? `Editing ${activeCrawl.crawler_name}`
                    : `Create New Crawl Scan`}
                </Typography>
              }
              action={
                activeCrawl ? (
                  <Tooltip title="Close Scan">
                    <IconButton
                      onClick={handleClearActiveCrawl}
                      sx={{
                        bgcolor: grey[100],
                        ":hover": { color: red[400], bgcolor: red[100] },
                      }}
                    >
                      <ClearSharpIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <></>
                )
              }
            />
            <CardContent>
              <Box
                onSubmit={handleSubmit}
                component="form"
                noValidate={false}
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                {submitMessage && (
                  <Alert severity={submitMessage.severity}>
                    {submitMessage.message}
                  </Alert>
                )}

                <TextField
                  fullWidth
                  label="Crawl Name"
                  required
                  name="crawl_name"
                  value={
                    activeCrawl && activeCrawl.crawler_name
                      ? activeCrawl.crawler_name
                      : ""
                  }
                  onChange={(e) =>
                    handleFormChange("crawler_name", e.target.value)
                  }
                ></TextField>
                <TextField
                  fullWidth
                  label="URL to Crawl"
                  required
                  name="url_to_crawl"
                  value={
                    activeCrawl && activeCrawl.url_to_crawl
                      ? activeCrawl.url_to_crawl
                      : ""
                  }
                  onChange={(e) =>
                    handleFormChange("url_to_crawl", e.target.value)
                  }
                ></TextField>
                <FormControl>
                  <InputLabel>Activate Scenario before Crawl</InputLabel>
                  <Select
                    onChange={(e) =>
                      handleFormChange("scan_to_run", e.target.value)
                    }
                    value={
                      activeCrawl && activeCrawl.scan_to_run
                        ? activeCrawl.scan_to_run
                        : ""
                    }
                    name="scenario_to_run_before"
                    label="Activate Scenario before Crawl"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {scanData.length > 0 &&
                      scanData.map((elem, index) => (
                        <MenuItem key={index} value={elem.scan_name}>
                          {elem.scan_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <TextField
                  value={
                    activeCrawl && activeCrawl.uri_regex
                      ? activeCrawl.uri_regex
                      : ""
                  }
                  onChange={(e) =>
                    handleFormChange("uri_regex", e.target.value)
                  }
                  name="uri_regex"
                  label="URI Regex"
                  helperText="Enter an optional regex to limit page urls within specific rules"
                ></TextField>
                <TextField
                  value={
                    activeCrawl && activeCrawl.crawler_limit
                      ? activeCrawl.crawler_limit
                      : ""
                  }
                  onChange={(e) =>
                    handleFormChange("crawler_limit", e.target.value)
                  }
                  name="crawler_limit"
                  label="Crawler Pages Limit"
                  helperText={`Enter an optional limit to crawled pages (Max: ${maxCrawlerLimit})`}
                ></TextField>
                <Grid container columns={2}>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            activeCrawl && activeCrawl.include_subdomain
                              ? activeCrawl.include_subdomain
                              : false
                          }
                          onChange={(e) =>
                            handleFormChange(
                              "include_subdomain",
                              e.target.value
                            )
                          }
                          name="include_subdomain"
                          inputProps={{ "aria-label": "Include Subdomains" }}
                        />
                      }
                      label="Include Subdomains"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            activeCrawl &&
                            activeCrawl.include_urls_with_qm_hashtag
                              ? activeCrawl.include_urls_with_qm_hashtag
                              : false
                          }
                          onChange={(e) =>
                            handleFormChange(
                              "include_urls_with_qm_hashtag",
                              e.target.value
                            )
                          }
                          name="include_urls_with_qm_hashtag"
                          inputProps={{
                            "aria-label":
                              "Include URLs containing “?” and “#” in crawling",
                          }}
                        />
                      }
                      label="Include URLs containing “?” and “#” in crawling"
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                    gap: 2,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="launch_on_save"
                        inputProps={{
                          "aria-label": "Launch Scan on Save",
                        }}
                      />
                    }
                    label="Launch Scan on Save"
                  />
                  {submitMessage ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      startIcon={<SaveSharpIcon />}
                      color={
                        activeCrawl && activeCrawl.crawl_id
                          ? "secondary"
                          : "primary"
                      }
                    >
                      {activeCrawl && activeCrawl.crawl_id
                        ? "Update Scan"
                        : "Save Scan"}
                    </Button>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default CrawlScan;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
