import {
  Alert,
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PricingTable from "./PricingTable/PricingTable";
import { grey } from "@mui/material/colors";

export default function Upgrade({ open, closeFn }) {
  const handleClose = () => {
    closeFn(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullScreen>
        <AppBar sx={{ position: "relative" }} color="inherit">
          <Toolbar>
            <Button
              color="error"
              onClick={handleClose}
              aria-label="close"
              size="large"
              startIcon={<CloseIcon />}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <DialogContent sx={{ bgcolor: grey[300] }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h3"
              textAlign={"center"}
              fontWeight={600}
              gutterBottom
            >
              Upgrade your Subscription
            </Typography>
            <PricingTable display={"default"} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
