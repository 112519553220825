import {
  Avatar,
  Box,
  IconButton,
  LinearProgress,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { blue, grey, orange, red } from "@mui/material/colors";
import { cancelReport, deleteReport } from "../../services/reportService";
import { formatBytes, modalBoxStyle } from "../../utils";

import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import { DataGrid } from "@mui/x-data-grid";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import { env } from "../../config";

function ReportHistory() {
  const [data$, setData$] = useState([]);
  const [deleteScan, setDeleteScan] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const openDeleteModalHandler = () => setDeleteModal(true);
  const closeDeleteModalHandler = () => setDeleteModal(false);
  const [cancelScan, setCancelScan] = useState({});
  const [cancelModal, setCancelModal] = useState(false);
  const openCancelModalHandler = () => setCancelModal(true);
  const closeCancelModalHandler = () => setCancelModal(false);
  const [loading, setLoading] = useState(false);
  const [inactiveReports, setInactiveReports] = useState([]);

  const getHistory = async () => {
    setLoading(true);
    try {
      const res = await fetch(
        `${env.REACT_APP_API_HOST}user/account-history/`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      const resData = await res.json();
      if (!res.ok) throw new Error("Error");
      const allData = resData.map((e) => ({ ...e, id: e.report_id }));
      setData$(allData);
    } catch (resError) {
    } finally {
      setLoading(false);
    }
  };

  const getInactiveReports = async () => {
    const res = await fetch(`${env.REACT_APP_API_HOST}reports/inactive/`, {
      method: "GET",
      credentials: "include",
    });
    const resData = await res.json();
    if (!res.ok) return;
    setInactiveReports(resData.map((e) => ({ ...e, id: e.reportId })));
  };

  useEffect(() => {
    getHistory();
    getInactiveReports();
  }, []);

  const deletehandler = () => {
    deleteReport(deleteScan.report_id).then(() => {
      getInactiveReports();
    });
    setData$(data$.filter((item) => item.report_id !== deleteScan.report_id));
    closeDeleteModalHandler();
  };

  const cancelHandler = () => {
    cancelReport(cancelScan.report_id);
    const report = data$.find(
      (item) => item.report_id === cancelScan.report_id
    );
    report.running = "Completed";
    const idx = data$.indexOf(report);
    data$[idx] = report;
    setData$([...data$]);
    closeCancelModalHandler();
  };
  const columns = [
    {
      field: "scan_name",
      headerName: "Scan",
      flex: 1,
      renderCell: (params) => {
        const t = params.row.scan_type[0].toUpperCase();
        const bgColor = t == "C" ? orange[400] : blue[400];
        return (
          <>
            <Tooltip title={params.row.scan_type}>
              <Avatar sx={{ bgcolor: bgColor, mr: 2, width: 30, height: 30 }}>
                {t}
              </Avatar>
            </Tooltip>
            {params.row.scan_name}
          </>
        );
      },
    },
    { field: "report_username", headerName: "Username", width: 100 },
    {
      field: "running",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <Typography variant="overline">{params.row.running}</Typography>
      ),
    },
    {
      field: "report_start_date",
      headerName: "Start Date",
      flex: 1,
      valueGetter: (params) => {
        const d = new Date(params.row.report_start_date);
        return d
          ? `${d.toLocaleDateString()} @ ${d.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}`
          : "";
      },
    },
    {
      field: "report_end_date",
      headerName: "End Date",
      flex: 1,
      valueGetter: (params) => {
        const d =
          params.row.report_end_date.length > 0
            ? new Date(params.row.report_end_date)
            : undefined;
        return d
          ? `${d.toLocaleDateString()} @ ${d.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}`
          : "";
      },
    },
    {
      field: "deducted_points",
      headerName: "Points",
    },
    {
      field: "report_size",
      headerName: "Size",
      sortable: false,
      valueGetter: (params) => formatBytes(params.row.report_size),
    },
    {
      headerName: "Action",
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <>
          {params.row.running.toLowerCase() === "running" ? (
            <IconButton
              onClick={() => {
                setCancelScan(params.row);
                openCancelModalHandler();
              }}
              sx={{ color: orange[400] }}
            >
              <CancelSharpIcon />
            </IconButton>
          ) : (
            <IconButton
              sx={{ color: red[400] }}
              onClick={() => {
                setDeleteScan(params.row);
                openDeleteModalHandler();
              }}
            >
              <DeleteOutlineSharpIcon />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const inactiveReportCols = [
    {
      field: "reportName",
      headerName: "Scan",
      flex: 1,
      renderCell: (params) => {
        const t = params.row.scanType[0].toUpperCase();
        const bgColor = t == "C" ? orange[400] : blue[400];
        return (
          <>
            <Tooltip title={params.row.scanType}>
              <Avatar sx={{ bgcolor: bgColor, mr: 2, width: 30, height: 30 }}>
                {t}
              </Avatar>
            </Tooltip>
            {params.row.scan ?? params.row.reportName}
          </>
        );
      },
    },
    { field: "user", headerName: "User", width: 100 },
    {
      field: "reportStatus",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <Typography variant="overline">{params.row.reportStatus}</Typography>
      ),
    },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      valueGetter: (params) => {
        const d = new Date(params.row.startDate);
        return d
          ? `${d.toLocaleDateString()} @ ${d.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}`
          : "";
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
      valueGetter: (params) => {
        const d =
          params.row.endDate && params.row.endDate.length > 0
            ? new Date(params.row.endDate)
            : undefined;
        return d
          ? `${d.toLocaleDateString()} @ ${d.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}`
          : "";
      },
    },
    {
      field: "pointsConsumption",
      headerName: "Points",
    },
  ];

  return (
    <>
      {loading && (
        <>
          <Typography>Fetching Account History</Typography>
          <LinearProgress />
        </>
      )}
      {data$.length > 0 && (
        <Paper sx={{ mb: 3 }}>
          <Box sx={{ p: 2, display: "flex", gap: 2 }}>
            <Typography
              gutterBottom
              variant="overline"
              sx={{ fontSize: "0.8rem" }}
            >
              Active Reports
            </Typography>
          </Box>
          <DataGrid
            sx={{
              borderRadius: 0,
              "& .MuiDataGrid-menuIcon": {
                width: "auto",
                visibility: "visible",
              },
            }}
            hideFooterSelectedRowCount={true}
            rowSelection={false}
            autoHeight={true}
            rows={data$}
            columns={columns}
            initialState={{
              rows: [],
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
          />
        </Paper>
      )}

      {inactiveReports.length > 0 && (
        <Paper>
          <Box
            sx={{
              p: 2,
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Typography variant="overline" sx={{ fontSize: "0.8rem" }}>
              Inactive Reports
            </Typography>
            <Tooltip title="Reports whose content has been deleted">
              <InfoSharpIcon color="disabled" sx={{ fontSize: "1rem" }} />
            </Tooltip>
          </Box>
          <DataGrid
            sx={{
              borderRadius: 0,
              "& .MuiDataGrid-menuIcon": {
                width: "auto",
                visibility: "visible",
              },
            }}
            autoHeight={true}
            rows={inactiveReports}
            columns={inactiveReportCols}
            hideFooterSelectedRowCount={true}
            rowSelection={false}
            initialState={{
              rows: [],
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
          />
        </Paper>
      )}

      <Modal
        open={deleteModal}
        onClose={closeDeleteModalHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalBoxStyle}>
          <h2 className="text-center mb-2">
            <FontAwesomeIcon
              icon="fa-solid fa-trash"
              className="mr-2 text-red-600"
            ></FontAwesomeIcon>
            Confirm Delete
          </h2>
          <div>
            <p id="modal-modal-description" className="text-center">
              You're about to delete "{deleteScan.scan_name}" report contents.
              This action cannot be undone
            </p>
            <div className="flex justify-between mt-3">
              <button
                onClick={closeDeleteModalHandler}
                className="bg-grey-300 text-white px-2.5 py-2 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={deletehandler}
                className="bg-red-600 text-white px-2.5 py-2 rounded-lg"
              >
                Delete
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={cancelModal}
        onClose={closeCancelModalHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalBoxStyle}>
          <h2 className="text-center mb-2">
            <FontAwesomeIcon
              icon="fa-solid fa-circle-xmark"
              className="mr-2 text-orange-600"
            ></FontAwesomeIcon>
            Confirm Scan Stop
          </h2>
          <div>
            <p id="modal-modal-description" className="text-center">
              You're about to stop {cancelScan.scan_name}.
            </p>
            <div className="flex justify-between mt-3">
              <button
                onClick={closeCancelModalHandler}
                className="bg-grey-300 text-white px-2.5 py-2 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={cancelHandler}
                className="bg-orange-600 text-white px-2.5 py-2 rounded-lg"
              >
                Stop
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ReportHistory;
