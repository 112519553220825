import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import PlayCircleOutlineSharpIcon from "@mui/icons-material/PlayCircleOutlineSharp";
import { env } from "../../config";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ScanLaunch({
  scanId,
  scanName,
  scanType,
  setStartLaunch,
}) {
  const [userNotes, setUserNotes] = useState("");
  const [loading, setLoading] = useState(false);
  const [launchMessage, setLaunchMessage] = useState(null);
  const navigate = useNavigate();

  const handleUserNotesChange = (event) => {
    const notes = event.target.value;
    setUserNotes(notes);
  };

  const handleClose = () => {
    setLoading(false);
    setLaunchMessage(null);
    setStartLaunch(false);
  };

  const handleLaunchScan = async () => {
    setLoading(true);
    setLaunchMessage({
      severity: "info",
      message: `Launching ${scanName}. Track progress on the reports page.`,
    });
    try {
      const pointsRes = await fetch(`${env.REACT_APP_API_HOST}check_points/`, {
        method: "POST",
        credentials: "include",
      });
      const pointsResData = await pointsRes.json();
      if (!pointsRes.ok) throw new Error(pointsResData.error);

      setTimeout(() => {
        navigate(`/scans-reports?scanId=${scanId}&scanType=${scanType}`);
        handleClose();
      }, 2000);

      const response = await fetch(`${env.REACT_APP_API_HOST}scans/launch/`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ scanId, scanType, userNotes }),
      });
      const resData = await response.json();
      if (!response.ok) throw new Error(resData.error);
    } catch (resError) {
      setLaunchMessage({
        severity: "error",
        message: `${resError.message}`,
      });
    }
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogTitle>
          <PlayCircleOutlineSharpIcon color="success" fontSize="large" />{" "}
          {`Launch ${scanName}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="launch-dialog-description"></DialogContentText>
          {launchMessage && (
            <Alert severity={launchMessage.severity}>
              {launchMessage.message}
            </Alert>
          )}
          <Box py={1}>
            <TextField
              onChange={handleUserNotesChange}
              id="outlined-multiline-static"
              label="Notes"
              multiline
              rows={4}
              placeholder="Optional Notes"
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <Box sx={{ m: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Button variant="outlined" color="error" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                onClick={handleLaunchScan}
                variant="contained"
                color="success"
                autoFocus
                startIcon={<PlayCircleOutlineSharpIcon />}
              >
                Launch
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
