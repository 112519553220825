const ValidationIcon = ({ fill = "#969BA0", stroke = "#969BA0", ...props }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="28"
      viewBox="0 0 64.000000 64.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M99 602 c-9 -4 -30 -20 -48 -37 l-31 -30 0 -236 c0 -189 3 -239 14
-248 17 -15 388 -11 393 4 2 6 -66 10 -189 10 l-193 0 -3 230 c-2 207 -1 231
15 242 21 16 315 18 344 3 16 -9 19 -22 19 -85 0 -45 4 -75 10 -75 7 0 10 30
8 82 -4 107 -4 108 -203 108 -101 0 -145 3 -135 10 20 13 316 13 341 0 17 -9
19 -22 19 -100 0 -56 4 -90 10 -90 7 0 10 35 8 97 -3 93 -4 98 -28 110 -27 14
-321 18 -351 5z"
        />
        <path
          d="M86 487 c-12 -9 -17 -23 -14 -47 3 -34 4 -35 48 -35 39 0 45 3 48 22
2 12 10 27 18 34 13 10 9 14 -23 25 -48 17 -55 17 -77 1z m62 -26 c3 -10 -2
-11 -19 -7 l-24 7 24 -20 23 -21 -31 0 c-26 0 -31 4 -31 23 0 13 3 27 7 31 11
11 46 2 51 -13z"
        />
        <path
          d="M205 440 c-4 -6 15 -10 50 -10 35 0 54 4 50 10 -3 6 -26 10 -50 10
-24 0 -47 -4 -50 -10z"
        />
        <path
          d="M86 367 c-12 -9 -17 -23 -14 -47 3 -34 4 -35 48 -35 39 0 45 3 48 22
2 12 10 27 18 34 13 10 9 14 -23 25 -48 17 -55 17 -77 1z m62 -26 c3 -10 -2
-11 -19 -7 l-24 7 24 -20 23 -21 -31 0 c-26 0 -31 4 -31 23 0 13 3 27 7 31 11
11 46 2 51 -13z"
        />
        <path
          d="M389 347 c-39 -26 -69 -79 -69 -122 0 -43 30 -96 69 -122 71 -48 175
-20 213 57 50 98 -21 210 -132 210 -32 0 -58 -7 -81 -23z m165 -28 c60 -52 61
-126 1 -186 -29 -29 -39 -33 -85 -33 -46 0 -56 4 -85 33 -60 60 -59 134 1 186
29 26 43 31 84 31 41 0 55 -5 84 -31z"
        />
        <path
          d="M482 233 c-40 -34 -43 -35 -60 -19 -29 25 -38 19 -12 -9 13 -14 28
-25 34 -25 6 0 34 20 61 45 59 53 39 60 -23 8z"
        />
        <path
          d="M205 320 c-4 -6 15 -10 50 -10 35 0 54 4 50 10 -3 6 -26 10 -50 10
-24 0 -47 -4 -50 -10z"
        />
        <path
          d="M86 247 c-12 -9 -17 -23 -14 -47 3 -34 4 -35 48 -35 44 0 45 1 48 35
4 36 -16 60 -48 60 -9 0 -24 -6 -34 -13z m62 -39 c3 -25 0 -28 -27 -28 -26 0
-31 4 -31 23 0 13 3 27 7 31 17 17 48 0 51 -26z"
        />
        <path
          d="M205 200 c-4 -6 15 -10 50 -10 35 0 54 4 50 10 -3 6 -26 10 -50 10
-24 0 -47 -4 -50 -10z"
        />
      </g>
    </svg>
  );
};

export default ValidationIcon;
