import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  OutlinedInput,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Copyright from "../../components/Copyright";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { env } from "../../config";
import { red } from "@mui/material/colors";
import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { removeEventFromDataLayer } from "../../utils";

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const recaptcha = useRef();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      setLoginError("Please verify you're not a robot!");
      return;
    }
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const username = data.get("username");
    const password = data.get("password");

    try {
      const res = await fetch(`${env.REACT_APP_API_HOST}login/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify({ username, password }),
      });
      const resData = await res.json();
      if (!res.ok) throw new Error(resData.error);
      event.target.reset();
      localStorage.setItem(
        "taglabUser",
        JSON.stringify({
          profilePicture: resData.profile_picture,
          firstName: resData.first_name,
          username: resData.username,
          organization: resData.organization,
          plan: resData.plan_name,
          points_available: resData.points_available,
          referral: resData.referral,
          referrer: resData.referrer,
        })
      );
      window.dataLayer.push({
        event: "sign-in",
        username: resData.username,
        organization: resData.organization,
        plan: resData.plan_name,
        points_available: resData.points_available,
        referral: resData.referral,
        referrer: resData.referrer,
      });
      setTimeout(() => {
        removeEventFromDataLayer("sign-in")
      }, 5000)
      navigate("/scans-reports");
    } catch (resError) {
      setLoginError(resError.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5" gutterBottom>
        Sign in
      </Typography>
      {loginError && (
        <Box
          my={2}
          px={2}
          py={1}
          bgcolor={red[100]}
          display={"flex"}
          flexDirection={"column"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography color={red[700]}>{loginError}</Typography>
        </Box>
      )}

      <Box
        component="form"
        noValidate={false}
        onSubmit={handleSubmit}
        sx={{ mt: 1 }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="email"
          autoFocus
          sx={{ mb: 3 }}
        />
        <FormControl required fullWidth id="password" variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            name="password"
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        {loading ? (
          <Box my={2}>
            <LinearProgress />
          </Box>
        ) : (
          <Box
            sx={{
              mt: 3,
              mb: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReCAPTCHA
              ref={recaptcha}
              sitekey={process.env.REACT_APP_SITE_KEY}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        )}

        <Grid container>
          <Grid item xs>
            <NavLink to="?authMode=resetRequest">
              <Typography color="primary" variant="body2">
                Forgot password?
              </Typography>
            </NavLink>
          </Grid>
          <Grid item>
            <NavLink to="?authMode=signup">
              <Typography variant="body2" color="primary">
                {"Don't have an account? Sign Up"}
              </Typography>
            </NavLink>
          </Grid>
        </Grid>

        {/* <Divider sx={{ mt: 4, mb: 4 }}>
          <Chip label="or sign in with" />
        </Divider>

        <SocialAuth /> */}
      </Box>
      <Copyright sx={{ mt: 4 }} />
    </Box>
  );
}
