import React, { useCallback, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { env } from "../../config.js";
import { useNavigate } from "react-router-dom";

function SimpleDialog(props) {
  let navigate = useNavigate();

  const handleRoute = useCallback(
    (val) => {
      navigate(val);
    },
    [navigate]
  );

  const { onClose, setAvatar, selectedValue, commitAvatar, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    setAvatar(value);
  };

  const handleSetAvatar = async () => {
    const formData = new FormData();
    formData.append(
      "profile_picture",
      await (
        await fetch(require("../../assets/avatars/" + selectedValue))
      ).blob()
    );
    //return
    await fetch(`${env.REACT_APP_API_HOST}change_profile_picture/`, {
      method: "POST",
      credentials: "include",
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.isAuthenticated === "False") handleRoute("/login");
        commitAvatar(require("../../assets/avatars/" + selectedValue));
        onClose(selectedValue);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Change Avatar</DialogTitle>
      <div className=" flex flex-wrap max-w-lg gap-2 bg-gray-200  p-9">
        {Avatars.map((item) => (
          <img
            className={
              item === selectedValue
                ? "bg-green rounded-lg w-16"
                : "rounded-lg cursor-pointer hover:bg-green/50 w-16"
            }
            src={require("../../assets/avatars/" + item)}
            onClick={() => handleListItemClick(item)}
            key={item}
          ></img>
        ))}
      </div>
      <div className="flex p-9 mb-4 ">
        <button
          className="bg bg-green text-white rounded-lg mx-auto w-80 text-sm py-4"
          onClick={() => handleSetAvatar()}
        >
          Select Avatar
        </button>
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  commitAvatar: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setAvatar: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo({ currentAvatar }) {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(currentAvatar);
  const [newAvatar, setNewAvatar] = useState(currentAvatar);

  useEffect(() => {
    setNewAvatar(currentAvatar);
  }, currentAvatar);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    //setSelectedValue(value);
  };

  const setAvatar = (value) => {
    setSelectedValue(value);
  };

  const commitAvatar = (value) => {
    setNewAvatar(value);
  };

  return (
    <div className="userInfo_imgContainer">
      {/* <Typography variant="subtitle1" component="div">
        Selected: {selectedValue}
      </Typography>
      <br />
      <Button variant="outlined" onClick={handleClickOpen}>
        Open simple dialog
      </Button> */}
      <img
        src={newAvatar || require("../../assets/userImage.png")}
        className="userIfo_img"
        alt=""
      />
      <FontAwesomeIcon
        onClick={handleClickOpen}
        icon="fa-solid fa-square-pen"
        className="userInfo_left_img_icon"
      />
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        setAvatar={setAvatar}
        commitAvatar={commitAvatar}
      />
    </div>
  );
}

const Avatars = [
  "4043229_afro_avatar_man_male.png",
  "4043230_illness_apple_avatar_watch_sick.png",
  "4043231_person_afro_female_woman.png",
  "4043232_avatar_batman_hero_comics.png",
  "4043233_spirited_no_face_anime_away_nobody.png",
  "4043234_avatar_russian_bear_animal.png",
  "4043235_afro_kid_child_boy.png",
  "4043236_boy_male_portrait_avatar.png",
  "4043237_food_avatar_avocado_scream.png",
  "4043238_boy_kid_person_avatar.png",
  "4043239_child_baby_toddler_kid.png",
  "4043240_breaking_chemisrty_heisenberg_avatar_bad.png",
  "4043241_helmet_builder_worker.png",
  "4043242_cactus_pirate_cacti_avatar.png",
  "4043243_man_comedy_actor_chaplin.png",
  "4043244_crying_avatar_rain_cloud.png",
  "4043245_coffee_cup_zorro_avatar.png",
  "4043246_love_addicted_draw_pencil.png",
  "4043247_avatar_1_woman_portrait_female.png",
  "4043248_woman_avatar_female_portrait.png",
  "4043249_geisha_avatar_woman_japanese.png",
  "4043250_avatar_kid_girl_child.png",
  "4043251_woman_avatar_female_girl.png",
  "4043252_kid_child_person_girl.png",
  "4043253_halloween_movie_jason_friday.png",
  "4043254_grandma_avatar_nanny_elderly.png",
  "4043255_beard_male_hipster_man.png",
  "4043256_indian_male_man_person.png",
  "4043257_man_sikh_indian_turban.png",
  "4043258_indian_boy_native_kid.png",
  "4043259_avatar_indian_hindi_woman.png",
  "4043260_male_avatar_portrait_man.png",
  "4043261_artist_monroe_marilyn_avatar.png",
  "4043262_avatar_muslim_man.png",
  "4043263_muslim_avatar_paranja_woman.png",
  "4043264_woman_sister_avatar_nun.png",
  "4043265_man_portrait_old_male.png",
  "4043266_monster_zombie_dead_avatar.png",
  "4043267_wrestler_man_fighter_luchador.png",
  "4043268_ufo_space_alien_avatar.png",
  "4043269_male_trump_avatar_president_donald trump.png",
  "4043270_joker_squad_woman_avatar_suicide.png",
  "4043271_bug_spider_avatar_insect.png",
  "4043272_avatar_sluggard_sloth_lazybones.png",
  "4043273_sheep_mutton_animal_avatar.png",
  "4043274_scientist_avatar_einstein_professor.png",
  "4043275_person_avatar_punk_man.png",
  "4043276_christmas_clous_santa.png",
  "4043277_person_avatar_pilot_traveller.png",
  "4043278_ozzy_avatar_singer_male_rock.png",
  "4043279_avatar_man_male_afro.png",
];
