import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { env } from "../../../config";
import { Paper } from "@mui/material";
import Loader from "../../../components/loader";
import { LogViewer, LogViewerSearch } from "@patternfly/react-log-viewer";

export default function ReportLog() {
  const [searchParams] = useSearchParams();
  const [log, setLog] = useState(null);
  const [loading, setLoading] = useState(false);

  const getReportLog = async () => {
    setLoading(true);
    const report_id = searchParams.get("id");
    const res = await fetch(
      `${env.REACT_APP_API_HOST}get_report_log/${report_id}`,
      { method: "get", credentials: "include" }
    );
    const resData = await res.json();
    setLog(resData.data);
    setLoading(false);
  };

  useEffect(() => {
    getReportLog();
  }, []);

  return (
    <>
      {log ? (
        <Paper sx={{ p: 2 }}>
          <LogViewer data={log} isTextWrapped={true} height={900} />
        </Paper>
      ) : (
        <Loader />
      )}
    </>
  );
}
