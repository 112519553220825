import { useState, useEffect, useMemo, useRef, forwardRef } from "react";
import ReportsNav from "../../../components/reports/reportsNav";
import { useSearchParams } from "react-router-dom";
import { env } from "../../../config";
import Loader from "../../../components/loader";
import Card from "../../../components/card";
import filterIcon from "../../../assets/filter.svg";
import checkIcon from "../../../assets/checkMark.svg";
import crossIcon from "../../../assets/crossIcon.svg";
import { Popover } from "@mui/material";
import { useIsOverflow } from "../../../hooks/useIsOverflow";
import downArrow from "../../../assets/downArrow.png";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import MaterialTable from '@material-table/core';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Validation = ({vid}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [validation, setValidations] = useState([]);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [anchorE2, setAnchorE2] = useState(null);

  const [openSearchDialog, setOpenSearchDialog] = useState(false);

  const handleSearchDialogClickOpen = () => {
    setOpenSearchDialog(true);
  };

  const handleSearchDialogClose = () => {
    setOpenSearchDialog(false);
  };

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [id, vid]);

  
  const getData = async (id) => {
    setIsLoading(true);
    await fetch(`${env.REACT_APP_API_HOST}get_report_validation/${id}`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        setIsLoading(false);
        if (response.Success === "True") {
          setValidations(response.data)
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      });
  };
  const validationsList = useMemo(() => {
    return (
      validation?.validations_list ?? []
    );
  }, [validation]);

  const filterValidation = (filter) => {
    setSelectedFilter(filter)
  }

  return (
    <div>
      {isLoading && <Loader />}
      {/* <ReportsNav /> */}
      {!isLoading && <>
        <div className="flex gap-4 justify-between">
          <Card className="w-full md:w-[32%]">
            <div className="flex flex-col items-center flex-1">
              <h1 className="font-bold">Total Validations</h1>
              <span className="text-sm text-light-grey">{validation.total_validations || "0"}</span>
            </div>
          </Card>
          <Card className="w-full md:w-[32%]">
            <div className="flex flex-col items-center flex-1">
              <h1 className="font-bold">Successful Validations</h1>
              <span className="text-sm text-light-grey">{validation.successful_validations || "0"}</span>
            </div>
          </Card>
          <Card className="w-full md:w-[32%]">
            <div className="flex flex-col items-center flex-1">
              <h1 className="font-bold">Failed Validations</h1>
              <span className="text-sm text-light-grey">{validation.failed_validations || "0"}</span>
            </div>
          </Card>
        </div>

        <Card className="mt-4 bg-transparent">
          <div className="flex justify-between items-center bg-white p-4 rounded-lg">
            <h1 className="font-bold">{validation.validation_name || 'N/A'}</h1>
            <div className="ml-4 mr-auto">
              <Button variant="outlined" onClick={handleSearchDialogClickOpen}>
                Search
              </Button>
              <Dialog
                fullScreen={true}
                open={openSearchDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleSearchDialogClose}
                aria-describedby="alert-dialog-slide-description"
              >
                
                <DialogTitle>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleSearchDialogClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  {"Search Cookies:"}
                </DialogTitle>
                <DialogContent>
                  <MaterialTable
                    title=""
                    columns={filteringColumns}
                    data={validationsList}        
                    options={{
                      filtering: true,
                    }}
                  />
                </DialogContent>
              </Dialog>
            </div>
            <div className="flex gap-3 items-center">
              <span className="text-light-grey text-sm">Filter: {selectedFilter} </span>
              <img
                src={filterIcon}
                className="h-[40px] w-[40px] cursor-pointer"
                onClick={(event) => {
                  setAnchorE2(event.currentTarget);
                  setIsDropdownOpen1(true);
                }}
              />
              <Popover
                id={"simple-popover1"}
                open={isDropdownOpen1}
                anchorEl={anchorE2}
                onClose={() => {
                  setAnchorE2(null);
                  setIsDropdownOpen1(false);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div className="w-64">
                  <div className="py-2">
                    <div className="border boder-b-1 px-4 py-2">
                      <p className="text-light-grey text-sm cursor-pointer" onClick={() => {setIsDropdownOpen1(false); filterValidation('All')}}>
                        Show all
                      </p>
                    </div>

                    <div className="border boder-b-1 px-4 py-2">
                      <p className="text-light-grey text-sm cursor-pointer" onClick={() => {setIsDropdownOpen1(false); filterValidation('Success')}}>
                        Show successfull
                      </p>
                    </div>
                    <div className="border boder-b-1 px-4 py-2">
                      <p className="text-light-grey text-sm cursor-pointer" onClick={() => {setIsDropdownOpen1(false); filterValidation('Failed')}}>
                        Show failed
                      </p>
                    </div>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
          <div>
            {validationsList.map((item, index) => (
              <Card1 data={item} filter={selectedFilter}/>
            ))}
          </div>
        </Card>
      </>}
    </div>
  );
};
export default Validation;

const Card1 = ({ data, filter }) => {
  const superRef = useRef(null);
  const childRef1 = useRef(null);

  const [isShow, setIsShow] = useState(false);

  const isOverflow1 = useIsOverflow(superRef, childRef1);

  if(filter !== 'All' && filter !== data.status) return

  return (
    <div className="mt-4 pb-4 bg-white rounded-lg py-6 px-6 mx-3">
      <div className="flex justify-between items-center">
        <h1 className="px-2 "><span className="font-bold">Source: </span>{data.source}</h1>
        {data.status === 'Failed' ? <img src={crossIcon} /> : <img src={checkIcon} />}
      </div>
      <h1 className=" w-[40%] sm:w-full px-2  mt-4"><span className="font-bold">Type specifier: </span>{data.type_specifier}</h1>
      <h1 className="px-2 "><span className="font-bold">Validation type: </span>{data.validation_type}</h1>
      <h1 className="px-2"><span className="font-bold">Technology: </span>{data.technology_name}</h1>
      
        <h1 className=" w-[50%] sm:w-[80%] px-2 text-light-grey">
          <span className="font-bold text-black">Condition: </span> {data.condition}
        </h1>
        

      {data.status === 'Failed' && (
        <div
          ref={superRef}
          className="mt-1 w-full bg-red-100 rounded flex justify-between items-center"
        >
          {!isShow && (
            <div
              className=" px-2 py-1  text-sm truncate w-[90%]"
              ref={childRef1}
            >
              {data.error_explanation}
            </div>
          )}
          {isShow && (
            <div className="px-2 py-1  text-sm">
              {data.error_explanation}
            </div>
          )}
          <div className="w-[40px] mr-3">
            {isOverflow1 && (
              <img
                src={downArrow}
                className="cursor-pointer"
                onClick={() => setIsShow((prev) => !prev)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};



const filteringColumns = [
  {
    title: "Source",
    field: "source",
  },
  {
    title: "Status",
    field: "status",
    lookup: {Failed: 'Failed', Success: 'Success'}
  },
  {
    title: "Validation Type",
    field: "validation_type",
  },
  {
    title: "Technology",
    field: "technology_name",
  },
  {
    title: "Condition",
    field: "condition",
  }
];