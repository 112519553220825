import * as React from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import EditNotificationsSharpIcon from "@mui/icons-material/EditNotificationsSharp";
import EmailNotificationAction from "./EmailNotificationAction";
import { Typography } from "@mui/material";
import { env } from "../../config.js";
import { useEffect } from "react";
import { useState } from "react";

export default function EmailNotificationSttings() {
  const [emailNotificationSettings, setEmailNotificationSettings] = useState(
    []
  );

  useEffect(() => {
    fetch(`${env.REACT_APP_API_HOST}user/email-notifications/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setEmailNotificationSettings([...data]);
      });
  }, []);

  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <CardHeader
          sx={{ fontFamily: "Cabin" }}
          avatar={
            <Avatar sx={{ bgcolor: "#12b922" }}>
              <EditNotificationsSharpIcon />
            </Avatar>
          }
          title={
            <Typography sx={{ fontFamily: "cabin" }} variant="body1">
              Email Notification Settings
            </Typography>
          }
        />
        <CardContent>
          {emailNotificationSettings.map((elem, index) => {
            return (
              <>
                <EmailNotificationAction key={index} {...elem} />
                {index + 1 < emailNotificationSettings.length && (
                  <Divider></Divider>
                )}
              </>
            );
          })}
        </CardContent>
      </Card>
    </Box>
  );
}
