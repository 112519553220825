import "./App.css";
import "./index.css";
import "rc-table/assets/index.css";

import {
  fa1,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDownAZ,
  faArrowDownZA,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowUpZA,
  faAsterisk,
  faBell,
  faChartGantt,
  faChartSimple,
  faCheck,
  faCircleCheck,
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleXmark,
  faClone,
  faDesktop,
  faDownload,
  faEllipsisVertical,
  faEye,
  faEyeSlash,
  faFile,
  faFileArrowUp,
  faFloppyDisk,
  faPen,
  faPenToSquare,
  faPlus,
  faRepeat,
  faRocket,
  faSpinner,
  faSquarePen,
  faSync,
  faToggleOff,
  faToggleOn,
  faTrash,
  faTriangleExclamation,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  faClipboard,
  faClock,
  faCopy,
  faNoteSticky,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import {
  faFacebookF,
  faFacebookSquare,
  faLinkedin,
  faSquareXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { useEffect, useState } from "react";

import Cookies from "js-cookie";
import EmailVerificationModal from "./components/EmailVerificationModal.jsx";
import RoutesComponent from "./Routes.jsx";
import { ThemeProvider } from "@mui/material";
import authService from "./services/authService";
import { env } from "./config.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import theme from "./theme";
import { useDispatch } from "react-redux";
import { uiStateActions } from "./store/index.js";

library.add(
  faLinkedin,
  faFacebookF,
  faFacebookSquare,
  faSquareXTwitter,
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleXmark,
  faUser,
  faChartSimple,
  faBell,
  faClock,
  faDesktop,
  faArrowRightFromBracket,
  faPlus,
  faEllipsisVertical,
  faRocket,
  faTrash,
  faCopy,
  faClipboard,
  faFile,
  faFileArrowUp,
  faSquarePen,
  faSpinner,
  faCircleCheck,
  faDownload,
  faFloppyDisk,
  faAngleDown,
  faAngleRight,
  faEye,
  faEyeSlash,
  faAngleUp,
  faPen,
  faNoteSticky,
  faClone,
  faArrowRight,
  faXmark,
  faSync,
  faTriangleExclamation,
  faArrowDownAZ,
  faArrowUpZA,
  faArrowDownZA,
  faCheck,
  faAsterisk,
  faPenToSquare,
  faChartGantt,
  faAngleLeft,
  fa1,
  faRepeat,
  faToggleOn,
  faToggleOff
);

function App() {
  const [notifNumber, setNotifNumber] = useState(0);
  const [shakeNotif, setShakeNotif] = useState(false);
  const [tokenStr, setTokenStr] = useState(undefined);
  const url = env.REACT_APP_API_HOST.replace("https", "wss")
    .replace("http", "ws")
    .replace("api/", "");
  const [notifs, setNotifs] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    authService.getCsrfToken();
  }, []);

  const getToken = async () => {
    await fetch(`${env.REACT_APP_API_HOST}get_org_token/`, {
      method: "POST",
      credentials: "include",
      body: {},
    })
      .then((res) => res.json())
      .then((res) => {
        setTokenStr(res.token);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    let subscription = true;
    if (subscription) getToken();
    return () => (subscription = false);
  }, []);

  const [webSocket, setWebSocket] = useState();

  useEffect(() => {
    let subscription = true;
    let token = Cookies.get("token");
    if (token === undefined && tokenStr === undefined) {
      getToken();
    }
    setTimeout(() => {
      var connectionString = `${url}ws/notifications/${
        Cookies.get("token") || tokenStr
      }/`;

      if (subscription) {
        if (webSocket === undefined || webSocket.readyState > 1) {
          setWebSocket(new WebSocket(connectionString));
        }
        if (webSocket) {
          webSocket.onopen = (event) => {
            console.log("connected");
          };
          webSocket.onmessage = function (event) {
            const json = JSON.parse(event.data);
            try {
              if ((json.event = "update")) {
                if (json.payload.message.length > 0) {
                  const today = new Date();
                  var date =
                    today.getFullYear() +
                    "-" +
                    (today.getMonth() + 1) +
                    "-" +
                    today.getDate();
                  var time =
                    today.getHours() +
                    ":" +
                    today.getMinutes() +
                    ":" +
                    today.getSeconds();

                  let currentDate = `${date} ${time}`;
                  const newNot = {
                    notification_content: json.payload.message,
                    creation_date: currentDate,
                  };
                  dispatch(uiStateActions.setNotificationsCount(1));
                  setNotifs((state) => [newNot, ...state]);
                  setShakeNotif(true);
                }
                setNotifNumber((state) => (state !== 0 ? state + 1 : 1));
              }
            } catch (err) {
              console.log(err);
            }
          };

          webSocket.onclose = function (event) {
            const ws = new WebSocket(connectionString);
            setWebSocket(ws);
          };

          webSocket.onerror = function (err) {
            console.log(
              "Socket encountered error: ",
              err.message,
              "Closing socket"
            );
            webSocket.close();
          };
        }
      }
    }, 700);

    return () => (subscription = false);
  }, [webSocket]);

  const get_notifications = async (calbackRoute) => {
    await fetch(`${env.REACT_APP_API_HOST}get_notifications/`, {
      method: "POST",
      credentials: "include",
      body: {},
    })
      .then((res) => res.json())
      .then((response) => {
        setNotifs(response.data);
        if (response.isAuthenticated === "False")
          calbackRoute("/auth?authMode=login");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <EmailVerificationModal />
      <RoutesComponent
        notifNumber={notifNumber}
        shakeNotif={shakeNotif}
        setShakeNotif={setShakeNotif}
        setNotifNumber={setNotifNumber}
        get_notifications={get_notifications}
        notifs={notifs}
      />
    </ThemeProvider>
  );
}

export default App;
