const ScanIcon = ({ stroke = "#969BA0", ...props }) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.44238 5.44336L9.88522 5.44336"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.44238 14.3281L8.77451 14.3281"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.44238 9.88477L12.1066 9.88477"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.5499 9.8851V6.99943C16.5499 4.17101 16.5499 2.75679 15.6712 1.87811C14.7926 0.999433 13.3783 0.999433 10.5499 0.999433H7C4.17157 0.999433 2.75736 0.999433 1.87868 1.87811C1 2.75679 1 4.17101 1 6.99943V14.9922C1 17.8206 1 19.2348 1.87868 20.1135C2.75736 20.9922 4.17157 20.9922 7 20.9922H8.77496"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle
        cx="14.8842"
        cy="17.1049"
        r="2.77677"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18.7725 20.9922L17.1064 19.3262"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default ScanIcon;
