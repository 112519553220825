const ReportIcon = ({ fill = "#969BA0", stroke = "#969BA0", ...props }) => {
  return (
    <svg
      width="28"
      height="30"
      viewBox="0 0 28 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1696 6.5288C6.92535 8.04307 4.66699 11.4216 4.66699 15.3464C4.66699 15.6908 4.68438 16.031 4.71831 16.3661L12.179 14.2956L10.1696 6.5288ZM13.0612 5.72801L15.2064 14.0199L15.2175 14.0627C15.2651 14.2458 15.3325 14.505 15.3613 14.7428C15.3976 15.0429 15.4031 15.515 15.1254 15.9962C14.8476 16.4775 14.4361 16.7088 14.158 16.8275C13.9378 16.9215 13.6796 16.9928 13.4973 17.0432L13.4546 17.055L5.46843 19.2713C6.92138 22.6542 10.1949 25.013 14.0003 25.013C19.155 25.013 23.3337 20.6851 23.3337 15.3464C23.3337 10.0076 19.155 5.67969 14.0003 5.67969C13.6833 5.67969 13.3701 5.69605 13.0612 5.72801Z"
        fill={fill}
      />
      <path
        d="M11.4899 5.64398C11.2437 4.69232 11.1206 4.21649 10.6917 4.01754C10.2627 3.8186 9.86773 4.00895 9.07776 4.38967C8.30671 4.76126 7.57537 5.21994 6.89746 5.7587C5.68197 6.72469 4.66209 7.92917 3.89604 9.30339C3.13 10.6776 2.6328 12.1946 2.43282 13.7679C2.3187 14.6657 2.30265 15.5717 2.3836 16.4691C2.46299 17.3492 2.50269 17.7893 2.88777 18.0556C3.27285 18.322 3.74584 18.1907 4.69182 17.9282L12.106 15.8706C13 15.6225 13.447 15.4984 13.6515 15.1441C13.856 14.7898 13.7398 14.3407 13.5075 13.4425L11.4899 5.64398Z"
        fill={fill}
      />
    </svg>
  );
};

export default ReportIcon;
