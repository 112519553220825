import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey, red } from "@mui/material/colors";

import BackspaceSharpIcon from "@mui/icons-material/BackspaceSharp";

export default function ValidationStepConditon({
  index,
  instructionId,
  condition,
  filters,
  removeCondition,
}) {
  return (
    <>
      <Box sx={{ display: "flex", width: "100%", alignItems: "center", mb: 2 }}>
        <Box sx={{ width: "100%" }}>
          <Typography gutterBottom variant="subtitle2">
            Key
          </Typography>
          <Grid container fullWidth spacing={2}>
            <Grid item xs={6}>
              <Select
                defaultValue={condition.key_filter}
                name={`conditionKeyFunction:${instructionId}:${condition.id}_${index}`}
                fullWidth
              >
                {filters.map((el, i) => (
                  <MenuItem key={i} value={el.name}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                defaultValue={condition.key}
                name={`conditionKey:${instructionId}:${condition.id}_${index}`}
                fullWidth
              ></TextField>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            mx: 2,
            pt: 2,
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography variant="h3">:</Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography gutterBottom variant="subtitle2">
            Value
          </Typography>
          <Grid container fullWidth spacing={2}>
            <Grid item xs={6}>
              <Select
                defaultValue={condition.value_filter}
                name={`conditionValueFunction:${instructionId}:${condition.id}_${index}`}
                fullWidth
              >
                {filters.map((el, i) => (
                  <MenuItem key={i} value={el.name}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                defaultValue={condition.value}
                name={`conditionValue:${instructionId}:${condition.id}_${index}`}
                fullWidth
              ></TextField>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            mx: 1,
            pt: 2,
          }}
        >
          {" "}
          <Tooltip title="Remove Condition">
            <IconButton
              onClick={() => removeCondition(index)}
              sx={{
                ":hover": { color: red[400] },
                color: grey[500],
              }}
            >
              <BackspaceSharpIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
}
