import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./adminLayoutStyle.css";

function MainAdminLayout({ children }) {
  const [accountSelected, setAccountSelected] = useState(true);
  const [usersSelected, setUsersSelected] = useState(false);
  const [plansSelected, setPlansSelected] = useState(false);
  const [regexSelected, setRegexSelected] = useState(false);
  const [planCreationSelected, setPlanCreationSelected] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    handleQueryString();
  });
  const handleQueryString = () => {
    setAccountSelected(false);
    setPlansSelected(false);
    setUsersSelected(false);
    setRegexSelected(false);
    setPlanCreationSelected(false);
    const queryString = window.location.href;
    if (queryString.includes("admin-dashboard/account")) {
      setAccountSelected(true);
    } else if (queryString.includes("admin-dashboard/users")) {
      setUsersSelected(true);
    } else if (queryString.includes("admin-dashboard/plans")) {
      setPlansSelected(true);
    } else if (queryString.includes("admin-dashboard/regex")) {
      setRegexSelected(true);
    } else if (queryString.includes("admin-dashboard/plan_creation")) {
      setPlanCreationSelected(true);
    }
  };
  const handleRoute = (val) => {
    handleQueryString();
    navigate(val);
  };
  return (
    <div style={{ width: "100%" }}>
      <center className="mainAdmin_layout_topBar_container">
        <div className="mainAdmin_layout_topBar">
          <div className="topBar_left">
            <p
              className={`mainAdmin_layout_TopBarItem ${
                planCreationSelected ? "mainAdmin_layout_topBar_selected" : ""
              }`}
              onClick={() => handleRoute("/admin-dashboard/plan_creation")}
            >
              Plan Creation
            </p>
            <p
              className={`mainAdmin_layout_TopBarItem ${
                accountSelected ? "mainAdmin_layout_topBar_selected" : ""
              }`}
              onClick={() => handleRoute("/admin-dashboard/account")}
            >
              Acount
            </p>
            <p
              className={`mainAdmin_layout_TopBarItem ${
                usersSelected ? "mainAdmin_layout_topBar_selected" : ""
              }`}
              onClick={() => handleRoute("/admin-dashboard/users")}
            >
              Users
            </p>
            <p
              className={`mainAdmin_layout_TopBarItem ${
                plansSelected ? "mainAdmin_layout_topBar_selected" : ""
              }`}
              onClick={() => handleRoute("/admin-dashboard/plans")}
            >
              Plans
            </p>
            <p
              className={`mainAdmin_layout_TopBarItem ${
                regexSelected ? "mainAdmin_layout_topBar_selected" : ""
              }`}
              onClick={() => handleRoute("/admin-dashboard/regex")}
            >
              Regex
            </p>
          </div>
          <div className="topBar_right">
            {plansSelected ? (
              <div className="mainAdmin_layout_TopBarItem mainAdmin_layout_download">
                <FontAwesomeIcon
                  icon="fa-solid fa-plus"
                  onClick={() => {
                    console.log("clicked");
                  }}
                  color="black"
                />
                Add New
              </div>
            ) : (
              <></>
            )}
            {regexSelected ? (
              <></>
            ) : (
              <div className="mainAdmin_layout_TopBarItem mainAdmin_layout_download ">
                <FontAwesomeIcon icon="fa-solid fa-download" color="#00796B" />
                DOWNLOAD
              </div>
            )}
            <FontAwesomeIcon
              icon="fa-solid fa-arrow-right"
              className="mainAdmin_layout_TopBarItem"
              style={{ color: "#707070", fontSize: "20px" }}
              onClick={() => handleRoute("/")}
            />
          </div>
        </div>
      </center>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: plansSelected || regexSelected ? "80px" : "160px",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default MainAdminLayout;
