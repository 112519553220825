import React, { useState, useCallback, useEffect } from "react";
import Input from "../../../components/Input";
import "../adminLayoutStyle.css";
import { env } from "../../../config.js";
import { useNavigate } from "react-router-dom";

function Users() {
  const [data, setData] = useState({
    organisation: "",
    username: "",
    email: "",
    phone: "",
    name: "",
    lastName: "",
    password: "",
    isAdmin: "False",
  });
  const [organisations, setOrganisations] = useState();
  let navigate = useNavigate();

  const handleRoute = useCallback(
    (val) => {
      navigate(val);
    },
    [navigate]
  );

  const handleCheckUser = async () => {
    await fetch(`${env.REACT_APP_API_HOST}checkUser/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.user === 0) {
          handleRoute("/scan/scenario-scan");
        }
        if (response.isAuthenticated === "False") handleRoute("/login");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleGetOrganisations = async () => {
    await fetch(`${env.REACT_APP_API_HOST}get_organizations/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        setOrganisations(response);
        const newData = { ...data };
        newData["organisation"] = response[0];
        setData(newData);
        if (response.isAuthenticated === "False") handleRoute("/login");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    handleCheckUser();
    handleGetOrganisations();
  }, []);
  const handleChange = (e, tag) => {
    const newData = { ...data };
    newData[tag] = e.target.value;
    setData(newData);
  };

  const handleValidation = async () => {
    await fetch(`${env.REACT_APP_API_HOST}admin_registerUser/`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response.isAuthenticated === "False") handleRoute("/login");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleSelectChange = (e, tag) => {
    const newData = { ...data };
    newData[tag] = e.target.value;
    setData(newData);
  };

  return (
    <div className="account_container">
      <div className="admin_users_selectConatiner">
        <label htmlFor="" className="admin_users_selectLabel">
          Organisation
        </label>
        <select
          onChange={(e) => handleSelectChange(e, "organisation")}
          value={data.organisation}
          className="admin_users_select"
          name=""
          id=""
        >
          {organisations?.map((elem, index) => (
            <option key={index} value={elem}>
              {elem}
            </option>
          ))}
        </select>
      </div>
      <Input
        handleChange={handleChange}
        h2Title={"Username"}
        value={data.username}
        tag="username"
      />
      <Input
        handleChange={handleChange}
        h2Title={"Email"}
        value={data.email}
        tag="email"
      />
      <Input
        handleChange={handleChange}
        h2Title={"Phone"}
        value={data.phone}
        tag="phone"
      />
      <Input
        handleChange={handleChange}
        h2Title={"Name"}
        value={data.name}
        tag="name"
      />
      <Input
        handleChange={handleChange}
        h2Title={"Last name"}
        value={data.lastName}
        tag="lastName"
      />
      <Input
        handleChange={handleChange}
        h2Title={"Password"}
        value={data.password}
        tag="password"
      />
      <div className="admin_users_selectConatiner">
        <label htmlFor="" className="admin_users_selectLabel">
          Is Admin
        </label>
        <select
          onChange={(e) => handleSelectChange(e, "isAdmin")}
          value={data.isAdmin}
          className="admin_users_select"
          name=""
          id=""
        >
          <option value={"True"}>true</option>
          <option value={"False"}>false</option>
        </select>
      </div>
      <button
        onClick={handleValidation}
        className="mainAdimn_common_buttonContainer"
      >
        VALIDATE & SAVE
      </button>
    </div>
  );
}

export default Users;
