import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useCallback } from "react";
import { env } from "../../../config.js";
import { useNavigate } from "react-router-dom";

const plansData = [
  {
    accountID: "123",
    accountName: "Company A",
    creationDate: "10/11/2012",
    currentPlan: "Plan A",
    accountActive: "No",
    accountPoints: "0",
  },
  {
    accountID: "456",
    accountName: "Company B",
    creationDate: "10/11/2012",
    currentPlan: "Plan B",
    accountActive: "Yes",
    accountPoints: "12",
  },
  {
    accountID: "123",
    accountName: "Company B",
    creationDate: "10/11/2012",
    currentPlan: "Plan B",
    accountActive: "Yes",
    accountPoints: "123",
  },
];

function Plans() {
  const [data, setData] = useState();
  let navigate = useNavigate();

  const handleRoute = useCallback(
    (val) => {
      navigate(val);
    },
    [navigate]
  );

  const handleCheckUser = async () => {
    await fetch(`${env.REACT_APP_API_HOST}checkUser/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.user === 0) {
          handleRoute("/scan/scenario-scan");
        }
        if (response.isAuthenticated === "False") handleRoute("/login");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleGetorganisationsPlan = async () => {
    await fetch(`${env.REACT_APP_API_HOST}get_organizationsPlanInfo/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        if (response.isAuthenticated === "False") handleRoute("/login");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    handleCheckUser();
    handleGetorganisationsPlan();
  }, []);

  return (
    <div className="admin_plansContainer">
      <div className="admin_plans_topSection_container">
        <div className="admin_plans_topSection">
          <select
            className="plans_select"
            name=""
            id=""
            placeholder="Select Field"
          >
            <option disabled selected>
              Select Field
            </option>
            <option value="1">1</option>
          </select>
          <input
            placeholder="Search Keyword"
            className="plans_input"
            type="text"
          />
          <button className="plans_searchInput"> Search</button>
        </div>
      </div>
      <div className="plans_tableCointainer__" style={{ width: "100%" }}>
        <div className="plans_tableCointainer">
          <table className="plans_table">
            <thead className="plans_table_thead">
              <tr className="plans_table_thead_tr">
                <th>Account ID</th>
                <th>Account Name</th>
                <th>Creation Date</th>
                <th>Current Plan</th>
                <th>Account Active</th>
                <th>Account Points</th>
              </tr>
            </thead>
            <tbody className="plans_table_tbody">
              {data?.map((elem, index) => (
                <tr key={index} className="plans_table_tbody_tr">
                  <td>{elem.accountID}</td>
                  <td>{elem.accountName}</td>
                  <td>{elem.creationDate}</td>
                  <td>{elem.currentPlan}</td>
                  <td>{elem.accountActive ? "Yes" : "No"}</td>
                  <td>{elem.accountPoints}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="plans_saveButton_container">
        <button className="mainAdimn_common_buttonContainer plans_saveButton">
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default Plans;
