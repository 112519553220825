import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";

import CorporateFareSharpIcon from "@mui/icons-material/CorporateFareSharp";
import InviteUser from "./InviteUser";
import OrganizationUsers from "./OrganizationUsers";
import { useState } from "react";

export default function Organization() {
  return (
    <>
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: green[400] }}>
              <CorporateFareSharpIcon />
            </Avatar>
          }
          title={<Typography variant="body2">My Organization</Typography>}
        ></CardHeader>

        <CardContent>
          <InviteUser />
          <OrganizationUsers />
        </CardContent>
      </Card>
    </>
  );
}
