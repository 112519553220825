export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function stringToDateFormat(dateString, showTime = false) {
  const dateOptions = {
    dateStyle: "medium",
  };
  const timeOptions = {
    timeStyle: "short",
  };
  const date = new Date(dateString);
  if (showTime)
    return `${date.toLocaleDateString(
      undefined,
      dateOptions
    )} at ${date.toLocaleTimeString(undefined, timeOptions)}`;
  return date.toLocaleDateString(undefined, dateOptions);
}

export function removeEventFromDataLayer(eventName) {
  window.dataLayer = [...window.dataLayer.filter((e) => e.event !== eventName)];
}

export const modalBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#ffffff",
  boxShadow: 24,
  p: 3,
  borderRadius: "5px",
  border: 0,
  textAlign: "center",
};

export const errorModalBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  opacity: 0.9,
  color: "#f00",
  boxShadow: 24,
  p: 3,
  borderRadius: "5px",
  border: 0,
  textAlign: "center",
};
