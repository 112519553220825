import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { blue, green, grey } from "@mui/material/colors";
import { useCallback, useState } from "react";

import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlaylistAddSharpIcon from "@mui/icons-material/PlaylistAddSharp";
import ValidationStepConditon from "./ValidationStepCondition";

export default function ValidationStep({
  id = "",
  count,
  technologies,
  filters,
  instruction,
  removeInstruction,
}) {
  const [conditions, setConditions] = useState([...instruction.conditions]);
  const types = ["Command", "URL"];

  const handleAddCondition = () => {
    const newCondition = {};
    setConditions((conditions) => [...conditions, newCondition]);
  };

  const removeCondition = (index) => {
    setConditions((cs) => {
      cs.splice(index, 1);
      return [...cs];
    });
  };
  return (
    <>
      <Card sx={{ width: "100%" }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: grey[200] }}>
              <Typography variant="h6" color={green[500]}>
                {count}
              </Typography>
            </Avatar>
          }
          action={
            <Tooltip title="Remove Validation Instruction">
              <IconButton
                color="error"
                onClick={() => {
                  removeInstruction(count - 1);
                }}
              >
                <DeleteOutlineSharpIcon />
              </IconButton>
            </Tooltip>
          }
        />
        <CardContent>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id={"type-label" + id}>Type</InputLabel>
                  <Select
                    labelId={`type-label${id}`}
                    id={`type-select${id}`}
                    label="Type"
                    name={`instructionType:${id}`}
                    defaultValue={instruction.type ?? ""}
                  >
                    {types.map((el, i) => (
                      <MenuItem key={i} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id={`filter-label${id}`}>Filter</InputLabel>
                  <Select
                    labelId={`filter-label${id}`}
                    id={`filter-select${id}`}
                    label="Type"
                    name={`instructionFilter:${id}`}
                    defaultValue={instruction.filter}
                  >
                    {filters.map((el, i) => (
                      <MenuItem key={i} value={el.name}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  defaultValue={instruction.type_specifier}
                  fullWidth
                  label="Value"
                  name={`instructionTypeSpecifier:${id}`}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id={"tech-label"}>Technology</InputLabel>
                  <Select
                    labelId="tech-label"
                    id="tech-select"
                    label="Technology"
                    name={`instructionTech:${id}`}
                    defaultValue={instruction.technology ?? ""}
                  >
                    {technologies.map((el, i) => (
                      <MenuItem key={i} value={el.technology_id}>
                        {el.technology_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ bgcolor: grey[200] }}
              >
                Conditions ({conditions.length})
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Tooltip title="Add Condition">
                    <IconButton
                      size="large"
                      color="primary"
                      onClick={handleAddCondition}
                    >
                      <PlaylistAddSharpIcon fontSize="6rem" />
                    </IconButton>
                  </Tooltip>
                </Box>
                {conditions.map((el, i) => (
                  <ValidationStepConditon
                    key={i}
                    index={i}
                    instructionId={id}
                    condition={el}
                    filters={filters}
                    removeCondition={removeCondition}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
