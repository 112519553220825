import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { env } from "../../config.js";
import { useState } from "react";

const EmailNotificationAction = ({ notification, isEnabled }) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(isEnabled);

  const changeSettingHandler = (e) => {
    setLoading(true);
    fetch(`${env.REACT_APP_API_HOST}user/email-notifications/`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        notification_id: notification.id,
        is_enabled: e.target.checked,
      }),
    })
      .then((res) => res.json)
      .then(() => {
        setLoading(false);
        setStatus(e.target.checked);
      });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Box
        sx={{
          flexGrow: 1,
          flexDirection: "column",
          height: "100%",
          justifyItems: "center",
        }}
      >
        <Typography variant="subtitle" gutterBottom>
          {notification.name}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {notification.description}
        </Typography>
      </Box>
      <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
        {!loading ? (
          <Switch onChange={changeSettingHandler} defaultChecked={status} />
        ) : (
          <CircularProgress size={20} />
        )}
      </Box>
    </Box>
  );
};
export default EmailNotificationAction;
