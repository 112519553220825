import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import { useEffect, useState } from "react";

import RoomPreferencesSharpIcon from "@mui/icons-material/RoomPreferencesSharp";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import SnackBar from "../SnackBar";
import { env } from "../../config";

export default function ScanPreferences({
  preferred_browser,
  JS_objects,
  default_crawl_dimensions,
  screenData,
}) {
  const [loading, setLoading] = useState(false);
  const [saveMessage, setSaveMessage] = useState(null);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const res = await fetch(
        `${env.REACT_APP_API_HOST}change_browser_js_object/`,
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            preferred_browser: data.get("preferred_browser"),
            JS_objects: data.get("JS_objects"),
            default_crawl_dimensions: data.get("default_crawl_dimensions"),
          }),
        }
      );
      const resData = await res.json();
      if (!res.ok) throw new Error("Changes were not saved!");
      setSaveMessage({ message: "Saved Successfully", status: "success" });
    } catch (resError) {
      setSaveMessage({
        message: "There was a problem saving your changes",
        status: "error",
      });
    } finally {
      setLoading(false);
      setTimeout(() => setSaveMessage(null), 5000);
    }
  };

  return (
    <>
      <Card component="form" onSubmit={handleSubmit}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: orange[300] }}>
              <RoomPreferencesSharpIcon />
            </Avatar>
          }
          title={<Typography variant="body1">Scan Preferences</Typography>}
          action={
            <>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveSharpIcon />}
                >
                  Save
                </Button>
              )}
            </>
          }
        />
        <CardContent>
          {saveMessage && (
            <Alert severity={saveMessage.status} sx={{ my: 1 }}>
              {saveMessage.message}
            </Alert>
          )}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Preferred Browser</InputLabel>
              <Select
                name="preferred_browser"
                label="Preferred Browser"
                defaultValue={preferred_browser}
              >
                <MenuItem value="Chrome">Chrome</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="JavaScript Objects"
              name="JS_objects"
              id="JS_objects"
              defaultValue={JS_objects}
            ></TextField>

            <FormControl fullWidth>
              <InputLabel>Scan Screen Dimensions</InputLabel>
              <Select
                label="Defined Screen Dimensions"
                name="default_crawl_dimensions"
                defaultValue={default_crawl_dimensions}
              >
                {screenData.map((screen, i) => (
                  <MenuItem key={i} value={screen.screen_id}>
                    {screen.screen_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
