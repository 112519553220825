import "./scans.css";

import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { formatBytes, stringToDateFormat } from "../../utils";
import { green, grey, red } from "@mui/material/colors";
import { useEffect, useState } from "react";

import ArticleSharpIcon from "@mui/icons-material/ArticleSharp";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import DoneAllSharpIcon from "@mui/icons-material/DoneAllSharp";
import EditNoteSharpIcon from "@mui/icons-material/EditNoteSharp";
import { NavLink } from "react-router-dom";
import SaveAltSharpIcon from "@mui/icons-material/SaveAltSharp";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import WarningSharpIcon from "@mui/icons-material/WarningSharp";
import { env } from "../../config";

export default function ScanReportVersionCard({
  id,
  status,
  pages,
  commands,
  version,
  creation_date,
  log,
  status_report_logs,
  user_notes,
  setVersions,
  setSelectedBulk,
  total_validations,
  successful_validations,
  failed_validations,
  report_size,
  reloadVersions,
}) {
  const [startDelete, setStartDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteComplete, setDeleteComplete] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [display, setDisplay] = useState("contents");
  const [startCancel, setStartCancel] = useState(false);
  const [showUserNotes, setShowUserNotes] = useState(false);
  const [userNotes, setUserNotes] = useState(null);
  const [updatingNotes, setUpdatingNotes] = useState(false);
  const [updatingNotesMessage, setUpdatingNotesMessage] = useState(null);
  const [cancelMessage, setCancelMessage] = useState(null);
  const [cancelling, setCancelling] = useState(false);
  const [reportData, setReportData] = useState({
    id,
    status,
    pages,
    commands,
    version,
    creation_date,
    log,
    status_report_logs,
    user_notes,
    total_validations,
    successful_validations,
    failed_validations,
    report_size,
  });

  const getReport = async () => {
    const response = await fetch(`${env.REACT_APP_API_HOST}reports/${id}/`, {
      method: "GET",
      credentials: "include",
    });
    const resData = await response.json();
    if (response.ok) {
      if (resData.status.toLowerCase() !== "running") reloadVersions();
      setReportData(() => ({ ...resData }));
    }
  };

  useEffect(() => {
    if (status.toLowerCase() === "running") {
      getReport();
    }
  }, [status]);

  useEffect(() => {
    setTimeout(() => {
      if (reportData.status.toLowerCase() === "running") {
        getReport();
      }
    }, 10000);
    return () => {};
  }, [reportData]);

  const handleUserNotesChange = (e) => {
    const notes = e.target.value;
    setUserNotes(notes);
  };

  const handleUpdateUserNotes = async () => {
    setUpdatingNotes(true);
    try {
      const response = await fetch(`${env.REACT_APP_API_HOST}reports/${id}/`, {
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify({ user_notes: userNotes }),
      });
      const resData = await response.json();
      if (!response.ok) throw new Error(resData.error);
      setUserNotes(userNotes);
      setUpdatingNotesMessage({ status: "success", message: resData.message });
    } catch (resError) {
      setUserNotes(user_notes);
      setUpdatingNotesMessage({ status: "error", message: resError });
    } finally {
      setUpdatingNotes(false);
    }
  };

  const handleBulkSelect = (e) => {
    const checked = e.target.checked;
    setSelectedBulk({ id, checked });
  };

  const handleStartCancel = () => {
    setStartCancel(true);
  };

  const handleCancel = async () => {
    setCancelling(true);
    try {
      const response = await fetch(
        `${env.REACT_APP_API_HOST}cancel_report/${id}`,
        { method: "GET", credentials: "include" }
      );
      const resData = await response.json();
      if (!response.ok) throw new Error("Error stopping this report");
      setCancelMessage(`Report stopped!`);
      reloadVersions();
    } catch (resError) {
      setCancelMessage(resError);
    } finally {
      setTimeout(() => {
        setCancelling(false);
        setStartCancel(false);
      }, 2000);
    }
  };

  const handleStartDelete = () => {
    setStartDelete(true);
  };

  const handleClose = () => {
    setStartDelete(false);
    setDeleteError(false);
    setStartCancel(false);
    setShowUserNotes(false);
    setUpdatingNotesMessage(null);
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `${env.REACT_APP_API_HOST}deleteReport/${id}`,
        { method: "GET", credentials: "include" }
      );
      const resData = response.json();
      if (!response.ok) throw new Error(resData.error);
      setDeleteComplete(true);
      setVersions(true);
      setTimeout(() => {
        setStartDelete(false);
        setDeleteComplete(false);
      }, 2000);
    } catch (resError) {
      setDeleteError(`${resError}`);
    } finally {
      setDeleting(false);
    }
  };

  const statusIcon = (
    <>
      {
        {
          completed: (
            <Tooltip title="Complete">
              <DoneAllSharpIcon color="success" />
            </Tooltip>
          ),
          failed: (
            <Tooltip title="Failed">
              <WarningSharpIcon color="error" />
            </Tooltip>
          ),
          running: (
            <Tooltip title="Running">
              <CircularProgress size={"1.5rem"} />
            </Tooltip>
          ),
        }[reportData.status.toLowerCase()]
      }
    </>
  );

  const userNotesModal = (
    <>
      <Dialog open={showUserNotes} onClose={handleClose} fullWidth>
        <DialogTitle>{`${version} User Notes`}</DialogTitle>
        <DialogContent>
          {updatingNotesMessage && (
            <Typography color={updatingNotesMessage.status}>
              {updatingNotesMessage.message}
            </Typography>
          )}

          <Box py={1}>
            <TextField
              id="outlined-multiline-static"
              label="Notes"
              multiline
              rows={4}
              placeholder="Optional Notes"
              fullWidth
              defaultValue={userNotes ?? user_notes}
              onChange={handleUserNotesChange}
              disabled={updatingNotes}
            />
          </Box>
        </DialogContent>
        {updatingNotes && <LinearProgress />}
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose}>
            Close
          </Button>
          <Button
            disabled={!userNotes}
            variant="contained"
            color="success"
            autoFocus
            startIcon={<SaveSharpIcon />}
            onClick={handleUpdateUserNotes}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  const cancelModal = (
    <>
      <Dialog open={startCancel} onClose={handleClose}>
        <DialogTitle>{`Stop ${version}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You're about to stop {version}. Do you want to proceed?
          </DialogContentText>
          <Box>
            <Typography variant="overline">{cancelMessage}</Typography>
          </Box>
        </DialogContent>
        {cancelling && <LinearProgress color="error" />}
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="error"
            startIcon={<CancelSharpIcon />}
            onClick={handleCancel}
            autoFocus
            variant="contained"
            disabled={cancelling}
          >
            Stop
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  const deleteModal = (
    <>
      <Dialog open={startDelete} onClose={handleClose}>
        <DialogTitle>{`Delete ${version} Report?`}</DialogTitle>
        {deleteComplete ? (
          <>
            <DialogContent>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <TaskAltIcon fontSize="large" color="success" />
                <Typography>Deleted</Typography>
              </Box>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogContent>
              <Box textAlign={"center"}>
                <Typography align="center" color={red[600]} variant="subtitle">
                  {deleteError}
                </Typography>
              </Box>
              <DialogContentText id="alert-dialog-description">
                You're about to delete {version}. Do you want to proceed?
              </DialogContentText>
            </DialogContent>
            {!deleting ? (
              <DialogActions>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  color="error"
                  startIcon={<DeleteOutlineSharpIcon />}
                  onClick={handleDelete}
                  autoFocus
                  variant="contained"
                >
                  Delete
                </Button>
              </DialogActions>
            ) : (
              <>
                <LinearProgress color="error" />
              </>
            )}
          </>
        )}
      </Dialog>
    </>
  );

  return (
    <>
      {reportData && (
        <Box width={"100%"} height={"100%"} display={display}>
          {userNotesModal}
          {deleteModal}
          {cancelModal}
          <Card square elevation={2} sx={{ height: "100%" }}>
            <Box display={"flex"} py={1} px={1} columnGap={1}>
              <Box flexGrow={1} flexDirection={"column"} display={"flex"}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  borderBottom={1}
                  borderColor={"#ccc"}
                >
                  <Box display={"flex"} height={"auto"} alignItems={"center"}>
                    <Checkbox onChange={handleBulkSelect} />

                    {!(reportData.status.toLowerCase() === "running") ? (
                      <NavLink to={`/report/tagsummary?id=${id}`}>
                        <Typography
                          variant="overline"
                          color="primary"
                          sx={{
                            ":hover": { textDecoration: "underline" },
                            fontWeight: 600,
                          }}
                        >
                          {version}
                        </Typography>
                      </NavLink>
                    ) : (
                      <>
                        <Typography
                          variant="overline"
                          sx={{
                            fontWeight: 600,
                          }}
                        >
                          {version}
                        </Typography>
                      </>
                    )}
                  </Box>
                  {statusIcon}
                </Box>
                <Typography variant="caption">
                  {stringToDateFormat(creation_date, true)}
                </Typography>
                {reportData.status.toLowerCase() === "running" ? (
                  <>
                    <Tooltip title={reportData.status_report_logs}>
                      <Typography
                        variant="caption"
                        color={"#0508b7"}
                        className="statusText"
                      >
                        {reportData.status_report_logs}
                      </Typography>
                    </Tooltip>
                    <Box
                      alignItems={"center"}
                      justifyContent={"center"}
                      display={"flex"}
                      py={0.5}
                    >
                      <CancelSharpIcon
                        color="error"
                        title="Cancel Report"
                        sx={{ cursor: "pointer" }}
                        onClick={handleStartCancel}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography variant="overline">
                      {" "}
                      {formatBytes(reportData.report_size)}
                    </Typography>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      mb={1}
                    >
                      <Typography variant="caption">
                        Pages: {reportData.pages}
                      </Typography>
                      <Typography variant="caption">
                        Commands :{reportData.commands}
                      </Typography>
                    </Box>

                    <Typography variant="caption">
                      Validations: {reportData.total_validations}
                    </Typography>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      mb={1}
                    >
                      <Typography variant="caption">
                        Successful: {reportData.successful_validations}
                      </Typography>
                      <Typography variant="caption">
                        Failed :{reportData.failed_validations}
                      </Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"center"} gap={3}>
                      <Box>
                        <Tooltip title="Download Report">
                          <SaveAltSharpIcon />
                        </Tooltip>
                      </Box>
                      <Box
                        onClick={() => setShowUserNotes(true)}
                        sx={{ cursor: "pointer" }}
                      >
                        <Tooltip title="Report Notes">
                          <EditNoteSharpIcon
                            sx={{ color: green[600], cursor: "pointer" }}
                          />
                        </Tooltip>
                      </Box>
                      <Box>
                        <Tooltip title="Report Log">
                          <a href={log} target="_blank" rel="noreferrer">
                            <ArticleSharpIcon sx={{ color: grey[400] }} />
                          </a>
                        </Tooltip>
                      </Box>
                      <Box
                        onClick={handleStartDelete}
                        sx={{ cursor: "pointer" }}
                      >
                        <Tooltip title="Delete Report">
                          <DeleteOutlineSharpIcon color="error" />
                        </Tooltip>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Card>
        </Box>
      )}
    </>
  );
}
