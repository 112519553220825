import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import ReferralDashboard from "./ReferralDashboard";
import { env } from "../../config";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

export default function Referrals() {
  const [loading, setLoading] = useState(false);
  const [referralInfo, setReferralInfo] = useState(null);
  const [cashoutForm, setCashoutForm] = useState(false);
  const [cashoutLoading, setCashoutLoading] = useState(false);
  const [cashoutMessage, setCashoutMessage] = useState(null);

  const handleCashoutOpen = () => {
    setCashoutForm(true);
  };
  const handleCashoutClose = () => {
    setCashoutForm(false);
  };

  const getReferralInfo = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${env.REACT_APP_API_HOST}referral/`, {
        method: "GET",
        credentials: "include",
      });
      const resData = await res.json();
      if (!res.ok) throw new Error(resData.error);
      setReferralInfo(resData);
    } catch (resError) {
      setReferralInfo(null);
    } finally {
      setLoading(false);
    }
  };

  const handleJoinProgram = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${env.REACT_APP_API_HOST}referral/`, {
        method: "POST",
        credentials: "include",
      });
      const resData = await res.json();
      if (!res.ok) throw new Error(resData.error);
      setReferralInfo(resData);
    } catch (resError) {
    } finally {
      setLoading(false);
    }
  };

  const handleCashoutRequest = async (event) => {
    event.preventDefault();
    setCashoutLoading(true);
    setCashoutMessage({
      severity: "info",
      message: "Sending your cashout request",
    });
    const fd = new FormData(event.currentTarget);
    const data = {
      address: fd.get("address"),
      comments: fd.get("comments"),
    };
    try {
      const res = await fetch(`${env.REACT_APP_API_HOST}referral/cashout/`, {
        method: "post",
        credentials: "include",
        body: JSON.stringify(data),
      });
      const resData = await res.json();
      if (!res.ok) throw new Error(resData.error);
      setCashoutMessage({ severity: "success", message: resData.message });
      setTimeout(() => {
        handleCashoutClose();
      }, 3000);
      getReferralInfo();
    } catch (resError) {
      setCashoutMessage({ severity: "error", message: resError.message });
    } finally {
      setCashoutLoading(false);
    }
  };

  useEffect(() => {
    getReferralInfo();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            my={10}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Box
            display={"flex"}
            width={"100%"}
            mt={2}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="h5" color="primary">
              Taglab Referral Program
            </Typography>

            <Box>
              <Button
                onClick={handleCashoutOpen}
                size="large"
                variant="contained"
                startIcon={<LocalAtmIcon />}
              >
                Cashout
              </Button>
            </Box>
          </Box>
          <Box id={0} aria-labelledby="dashboard-panel">
            {referralInfo ? (
              <ReferralDashboard {...referralInfo} />
            ) : (
              <>
                <Paper sx={{ p: 3, mt: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button onClick={handleJoinProgram}>
                      Join the Referral Program
                    </Button>
                  </Box>
                </Paper>
              </>
            )}
          </Box>
        </>
      )}

      <Dialog
        component="form"
        onSubmit={handleCashoutRequest}
        open={cashoutForm}
        onClose={handleCashoutClose}
        fullWidth
      >
        <DialogTitle>Earnings Cashout</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
            {cashoutMessage && (
              <Alert severity={cashoutMessage.severity}>
                {cashoutMessage.message}
              </Alert>
            )}
            {cashoutLoading ? (
              <LinearProgress color="info" />
            ) : (
              <>
                <Typography color="info">
                  Request payment for your current earnings.
                </Typography>
                <TextField
                  required
                  fullWidth
                  label="Paypal Address"
                  size="small"
                  name="address"
                />
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Comments"
                  size="small"
                  name="comments"
                />
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: 2,
            }}
          >
            <Button
              color="error"
              variant="contained"
              size="small"
              onClick={handleCashoutClose}
              type="button"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="small"
              color="primary"
            >
              Cashout
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}
