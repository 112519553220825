import "./ModalElemStyle.css";

import { Box, Modal } from "@mui/material";

import React from "react";
function ModalElem({
  message,
  setOpenModalElemInfo,
  openModalElemInfo,
  err,
  setError,
}) {
  const handleCloseModalElem = () => {
    setOpenModalElemInfo(false);
    setError(null);
  };

  return (
    <Modal
      open={openModalElemInfo}
      onClose={handleCloseModalElem}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <div style={{ textAlign: "center" }}>{message}</div>
          <ul style={{ marginLeft: "50px" }}>
            {err && err.length > 0 ? (
              err.map((elem, index) => <li key={index}>{elem}</li>)
            ) : (
              <></>
            )}
          </ul>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <button
              style={{ cursor: "pointer" }}
              className="scanNamingButton"
              onClick={handleCloseModalElem}
            >
              Ok
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 120,
  bgcolor: "#ffffff",
  boxShadow: 24,
  p: 3,
  borderRadius: "5px",
  border: 0,
};

export default ModalElem;
