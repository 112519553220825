import "./styleSheet.css";

import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logoMax from "../../assets/logoMax.png";
import logoMin from "../../assets/logoMin.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function SideBar({ sideBarvisible, setSideBarVisible }) {
  let navigate = useNavigate();
  const [sideBarClass, setSideBarClass] = useState("");
  const [arrowIcon, setArrowIcon] = useState(
    "fa-solid fa-circle-chevron-right"
  );
  const [logo, setLogo] = useState(logoMin);
  const [scanActive, setScanActive] = useState("");
  const [reportActive, setReportActive] = useState("");
  const [scheduleActive, setScheduleActive] = useState("");
  const handleSideBarToggle = () => {
    if (sideBarClass.includes("sideBar_containerAfterClick")) {
      setSideBarClass("sideBar_containerBeforeClick");
      setArrowIcon("fa-solid fa-circle-chevron-right");
      setLogo(logoMin);
      setSideBarVisible(false);
    } else {
      setSideBarClass("sideBar_containerAfterClick");
      setArrowIcon("fa-solid fa-circle-chevron-left");
      setLogo(logoMax);
      setSideBarVisible(true);
    }
  };
  useEffect(() => {
    handleQueryString();
  });
  const handleQueryString = () => {
    setScanActive("");
    setScheduleActive("");
    setReportActive("");
    const queryString = window.location.href;
    const active = "sidBar_button_selected";
    if (
      queryString.includes("scenario-scan") ||
      queryString.includes("crawl-scan")
    ) {
      setScanActive(active);
    } else if (queryString.includes("schedule")) {
      setScheduleActive(active);
    } else if (queryString.includes("scans-reports")) {
      setReportActive(active);
    }
  };
  const handleRoute = (val) => {
    handleQueryString();
    navigate(val);
  };

  return (
    <div className={"sideBar_containerBeforeClick " + sideBarClass}>
      <div className="sideBar_menuContainer">
        <div className="sideBar_title">
          <img src={logo} alt="" />
        </div>
        <div className="sidBar_buttons">
          <div
            className={`sideBar_menu sidBar_Item ${scanActive}`}
            onClick={() => handleRoute("/scan/scenario-scan")}
          >
            <FontAwesomeIcon icon="fa-solid fa-desktop" size="2x" />
            {sideBarvisible ? <p>Scan</p> : <></>}
          </div>
          <div
            className={`sideBar_menu sidBar_Item ${reportActive}`}
            onClick={() => handleRoute("/scans-reports")}
          >
            <FontAwesomeIcon icon="fa-solid fa-chart-simple" size="2x" />
            {sideBarvisible ? <p>Report--</p> : <></>}
          </div>
          <div
            className={`sideBar_menu sidBar_Item ${scheduleActive} under_construction_Icon`}
            // onClick={() => handleRoute("/schedule")}
            style={{
              color: "rgb(204, 204, 204)",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              cursor: "pointer",
              position: "relative",
            }}
            data-text="Under Construction"
          >
            <FontAwesomeIcon icon="fa-regular fa-clock" size="2x" />
            {sideBarvisible ? <p>Schedule</p> : <></>}
          </div>
        </div>
        <div className="sidBar_Item" onClick={handleSideBarToggle}>
          <FontAwesomeIcon className="sideBarIcon" icon={arrowIcon} />
        </div>
      </div>
    </div>
  );
}

export default SideBar;
