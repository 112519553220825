import "./scenarioStyle.css";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AddSharpIcon from "@mui/icons-material/AddSharp";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import { DialogHeader } from "@material-tailwind/react";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import FileCopySharpIcon from "@mui/icons-material/FileCopySharp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GetAppSharpIcon from "@mui/icons-material/GetAppSharp";
import ImportJson from "../../components/importJson";
import MadalElem from "../../components/Modal";
import MoreVertSharpIcon from "@mui/icons-material/MoreVertSharp";
import PlayCircleOutlineSharpIcon from "@mui/icons-material/PlayCircleOutlineSharp";
import Popover from "@mui/material/Popover";
import ScanLaunch from "../../components/Scans/ScanLaunch.jsx";
import ScenarioScanTable from "./ScenarioScanTable";
import SnackBar from "../../components/SnackBar";
import { env } from "../../config.js";
import { errorModalBoxStyle } from "../../utils";
import { green } from "@mui/material/colors";
import { updateScenario } from "../../services/scenarioService";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#ffffff",
  boxShadow: 24,
  p: 3,
  borderRadius: "5px",
  border: 0,
  textAlign: "center",
};

function ScenarioScan() {
  const [selected, setSelected] = useState("getUrl");
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);
  const [openModalRenameScan, setOpenModalScanRename] = React.useState(false);
  const [scanName, setScanName] = useState("");
  const [scanFile, setScanFile] = useState(null);
  const [scenarios, setScenarios] = useState([]);
  const handleOpenScanRename = () => {
    setScanRenameChange(scenarios[selectedScanIndex].scan_name);
    setOpenModalScanRename(true);
  };
  const handleCloseModalRenameScan = () => setOpenModalScanRename(false);
  const [selectedScanIndex, setSelectedScanIndex] = useState(0);
  const [onEdit, setOnEdit] = useState(false);
  const [selectedRowToEdit, setSelectedRowToEdit] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [errNewScan, setErrorNewScan] = useState(false);
  const [openScanDeleteConf, setOpenScanDeleteConf] = useState(false);
  const [scanRenameChange, setScanRenameChange] = useState(false);
  const [errMsgNewScan, setErrorMsgNewScan] = useState(
    "This field is required!"
  );
  const [filteredScanData, setFilteredScanData] = useState([]);
  const [selectedFilteredBoolean, setSelectedFilteredBoolean] = useState("");
  const snackBarMessage = "Launching Scenario...";
  const [StepsCount, setStepsCount] = useState({
    click: 1,
    getUrl: 1,
    sleep: 1,
    validate: 1,
    input: 1,
    submit: 1,
    scroll: 1,
    execute_Javascript: 1,
    Bulk_URL_Open: 1,
  });
  const [openModalElemInfo, setOpenModalElemInfo] = useState(false);
  const [ModaInfoMessage, setModalInfoMessage] = useState("");
  const [err, setError] = useState([]);
  const [scroll, setScroll] = useState("element");
  const [scrollLocation, setScrollLocation] = useState("Start");

  const [updateScenarioError, setUpdateScenarioError] = useState(null);
  const [updateScenarioErrorModal, setUpdateScenarioErrorModal] =
    useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const activeScenarioId = searchParams.get("activeScenario");
    if (activeScenarioId) {
      const scenario = scenarios.find((s, i) => {
        if (+s.scan_id === +activeScenarioId) {
          handleScanSelecting(i, s.scan_name);
        }
        return +s.scan_id === +activeScenarioId;
      });
    }
  }, [scenarios]);

  let navigate = useNavigate();

  const handleRoute = useCallback(
    (val) => {
      navigate(val);
    },
    [navigate]
  );

  const handleStepsCountChange = (addFlag, index) => {
    if (addFlag) StepsCount[selected] += 1;
    else
      StepsCount[scenarios[selectedScanIndex].instructions[index].action] -= 1;

    setStepsCount(StepsCount);
  };
  const handleSearchFiltring = (e) => {
    setSelectedFilteredBoolean(true);
    const filteredData = scenarios.filter((elem) => {
      return elem.scan_name
        .toLowerCase()
        .includes(e?.target?.value.toLowerCase());
    });
    setFilteredScanData(filteredData);
  };
  const refreshStepNames = useCallback(
    (data) => {
      const newStepsCount = {
        click: 1,
        getUrl: 1,
        sleep: 1,
        validate: 1,
        input: 1,
        submit: 1,
        scroll: 1,
        execute_Javascript: 1,
        Bulk_URL_Open: 1,
      };
      const newScanData = [...data];
      data[selectedScanIndex].instructions.forEach((element) => {
        const regex = new RegExp(`^${element.action}_[0-9]*$`);
        if (regex.test(element.items.step_name)) {
          element.items.step_name =
            element.action + "_" + newStepsCount[element.action];
          newStepsCount[element.action] += 1;
        }
      });
      setScenarios(newScanData);
      setStepsCount(newStepsCount);
    },
    [selectedScanIndex]
  );

  const getScanData = useCallback(async () => {
    await fetch(`${env.REACT_APP_API_HOST}get_scans/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.isAuthenticated === "False") handleRoute("/login");
        setScenarios(response);
        setFilteredScanData(response);
        refreshStepNames(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [handleRoute, refreshStepNames]);

  useEffect(() => {
    setStepsCount();
    getScanData();
  }, []);

  const handleScanSelecting = (index, scanName) => {
    if (selectedFilteredBoolean) {
      for (var i = 0; i < scenarios.length; i++) {
        if (scenarios[i].scan_name === scanName) {
          setSelectedScanIndex(i);
          handleSearchFiltring("");
          setSelectedFilteredBoolean(false);
          return;
        }
      }
    }
    setSelectedScanIndex(index);
    setSearchParams({ activeScenario: scenarios[index].scan_id });
  };

  const updateScanDataInstructions = async () => {
    if (StepsCount) {
      document.getElementById("step_name").value =
        selected + "_" + StepsCount[selected];
    }
    const selectedElem = scenarios[selectedScanIndex];
    if (!selectedElem) return;
    const update = await updateScenario(selectedElem);
    const response = await update.json();
    if (update.ok) {
      if (response.isAuthenticated === "False") handleRoute("/login");
    } else {
      setUpdateScenarioError(response.error);
      setUpdateScenarioErrorModal(true);
    }
  };

  const handleAddinstruction = () => {
    const index = selectedScanIndex;
    const newElementGetUrl = {
      action: "getUrl",
      items: {
        url: "",
        step_name: "",
        sensitiveCheckBox: "",
        mandatoryCheckBox: "",
      },
    };
    const newElementSleep = {
      action: "sleep",
      items: {
        value: "",
        step_name: "",
        sensitiveCheckBox: "",
        mandatoryCheckBox: "",
      },
    };
    const newElementValidate = {
      action: "validate",
      items: {
        value: "",
        step_name: "",
        sensitiveCheckBox: "",
        mandatoryCheckBox: "",
      },
    };
    const newElementInput = {
      action: "input",
      items: {
        input_attr_name: "",
        input_attr_value: "",
        input_tag: "",
        input_index: "",
        input_text: "",
        step_name: "",
        sensitiveCheckBox: "",
        mandatoryCheckBox: "",
      },
    };
    const newElementClick = {
      action: "click",
      items: {
        click_attr_name: "",
        click_attr_value: "",
        click_tag: "",
        click_index: "",
        step_name: "",
        sensitiveCheckBox: "",
        mandatoryCheckBox: "",
      },
    };
    const newElementSubmit = {
      action: "submit",
      items: {
        step_name: "",
        sensitiveCheckBox: "",
        mandatoryCheckBox: "",
        screenShotCheckBox: "",
      },
    };
    let newElementScroll = {
      action: "scroll",
      items: {
        step_name: "",
        scroll_to: "",
        scroll_location: "",
        scroll_attr_name: "",
        scroll_attr_value: "",
        scroll_tag_name: "",
        scroll_index: "",
        sensitiveCheckBox: "",
        mandatoryCheckBox: "",
        screenShotCheckBox: "",
      },
    };

    const newElementExecuteJavascript = {
      action: "execute_Javascript",
      items: {
        step_name: "",
        jsCode: "",
        sensitiveCheckBox: "",
        mandatoryCheckBox: "",
        screenShotCheckBox: "",
      },
    };
    const newElementBulkURLOpen = {
      action: "Bulk_URL_Open",
      items: {
        step_name: "",
        URLs: "",
        sensitiveCheckBox: "",
        mandatoryCheckBox: "",
        screenShotCheckBox: "",
      },
    };
    //!! new commands
    if (selected === "submit") {
      const clickKeys = Object.keys(newElementSubmit.items);
      clickKeys.forEach((key) => {
        if (
          key === "sensitiveCheckBox" ||
          key === "mandatoryCheckBox" ||
          key === "screenShotCheckBox"
        ) {
          if (!!document.getElementById(key)) {
            newElementSubmit.items[key] = document.getElementById(key).checked;
          }
        } else newElementSubmit.items[key] = document.getElementById(key).value;
      });
      const newInstructions = [...scenarios];
      newInstructions[index].instructions.push(newElementSubmit);
      setScenarios(newInstructions);
    }
    if (selected === "scroll") {
      const clickKeys = Object.keys(newElementScroll.items);
      clickKeys.forEach((key) => {
        if (
          key === "sensitiveCheckBox" ||
          key === "mandatoryCheckBox" ||
          key === "screenShotCheckBox"
        ) {
          if (!!document.getElementById(key)) {
            newElementScroll.items[key] = document.getElementById(key).checked;
          }
        } else if (key === "scroll_to") {
          newElementScroll.items[key] = scroll;
        } else if (key === "scroll_location") {
          newElementScroll.items[key] = scrollLocation;
        } else {
          newElementScroll.items[key] = document.getElementById(key)?.value;
        }
      });
      const newInstructions = [...scenarios];
      newInstructions[index].instructions.push(newElementScroll);
      setScenarios(newInstructions);
    }
    if (selected === "execute_Javascript") {
      const clickKeys = Object.keys(newElementExecuteJavascript.items);
      clickKeys.forEach((key) => {
        if (
          key === "sensitiveCheckBox" ||
          key === "mandatoryCheckBox" ||
          key === "screenShotCheckBox"
        ) {
          if (!!document.getElementById(key)) {
            newElementExecuteJavascript.items[key] =
              document.getElementById(key).checked;
          }
        } else
          newElementExecuteJavascript.items[key] =
            document.getElementById(key).value;
      });
      const newInstructions = [...scenarios];
      newInstructions[index].instructions.push(newElementExecuteJavascript);
      setScenarios(newInstructions);
    }
    if (selected === "Bulk_URL_Open") {
      const clickKeys = Object.keys(newElementBulkURLOpen.items);
      clickKeys.forEach((key) => {
        if (
          key === "sensitiveCheckBox" ||
          key === "mandatoryCheckBox" ||
          key === "screenShotCheckBox"
        ) {
          if (!!document.getElementById(key)) {
            newElementBulkURLOpen.items[key] =
              document.getElementById(key).checked;
          }
        } else
          newElementBulkURLOpen.items[key] = document.getElementById(key).value;
      });
      const newInstructions = [...scenarios];
      newInstructions[index].instructions.push(newElementBulkURLOpen);
      setScenarios(newInstructions);
    }
    //!!end of new commands
    if (selected === "click") {
      const clickKeys = Object.keys(newElementClick.items);

      clickKeys.forEach((key) => {
        if (
          key === "sensitiveCheckBox" ||
          key === "mandatoryCheckBox" ||
          key === "screenShotCheckBox"
        ) {
          if (!!document.getElementById(key)) {
            newElementClick.items[key] = document.getElementById(key).checked;
          }
        } else newElementClick.items[key] = document.getElementById(key).value;
      });
      const newInstructions = [...scenarios];
      newInstructions[index].instructions.push(newElementClick);
      setScenarios(newInstructions);
    }
    if (selected === "validate") {
      const clickKeys = Object.keys(newElementValidate.items);
      clickKeys.forEach((key) => {
        if (
          key === "sensitiveCheckBox" ||
          key === "mandatoryCheckBox" ||
          key === "screenShotCheckBox"
        ) {
          if (!!document.getElementById(key)) {
            newElementValidate.items[key] =
              document.getElementById(key).checked;
          }
        } else
          newElementValidate.items[key] = document.getElementById(key).value;
      });
      const newInstructions = [...scenarios];
      newInstructions[index].instructions.push(newElementValidate);
      setScenarios(newInstructions);
    }
    if (selected === "getUrl") {
      const clickKeys = Object.keys(newElementGetUrl.items);
      clickKeys.forEach((key) => {
        if (
          key === "sensitiveCheckBox" ||
          key === "mandatoryCheckBox" ||
          key === "screenShotCheckBox"
        ) {
          if (!!document.getElementById(key)) {
            newElementGetUrl.items[key] = document.getElementById(key).checked;
          }
        } else newElementGetUrl.items[key] = document.getElementById(key).value;
      });
      const newInstructions = [...scenarios];
      newInstructions[index].instructions.push(newElementGetUrl);
      setScenarios(newInstructions);
    }
    if (selected === "sleep") {
      const clickKeys = Object.keys(newElementSleep.items);
      clickKeys.forEach((key) => {
        if (
          key === "sensitiveCheckBox" ||
          key === "mandatoryCheckBox" ||
          key === "screenShotCheckBox"
        ) {
          if (!!document.getElementById(key)) {
            newElementSleep.items[key] = document.getElementById(key).checked;
          }
        } else newElementSleep.items[key] = document.getElementById(key).value;
      });
      const newInstructions = [...scenarios];
      newInstructions[index].instructions.push(newElementSleep);
      setScenarios(newInstructions);
    }
    if (selected === "input") {
      const clickKeys = Object.keys(newElementInput.items);
      clickKeys.forEach((key) => {
        if (
          key === "sensitiveCheckBox" ||
          key === "mandatoryCheckBox" ||
          key === "screenShotCheckBox"
        ) {
          newElementInput.items[key] = document.getElementById(key).checked;
        } else newElementInput.items[key] = document.getElementById(key).value;
      });
      const newInstructions = [...scenarios];
      newInstructions[index].instructions.push(newElementInput);
      setScenarios(newInstructions);
    }

    updateScanDataInstructions();
  };

  const handleRemoveInstruction = (i) => {
    const newScanData = [...scenarios];
    const elementDeleted = newScanData[selectedScanIndex].instructions[i];
    newScanData[selectedScanIndex].instructions.splice(i, 1);
    let elementDeleted_action = elementDeleted.action;
    const regex = new RegExp(`^${elementDeleted_action}_[0-9]*$`);
    const newInstructions = [];
    var counter = 1;
    newScanData[selectedScanIndex].instructions.forEach((element) => {
      const temp_element = element;
      if (
        element.action === elementDeleted_action &&
        regex.test(element.items.step_name)
      ) {
        temp_element.items.step_name = element.action + "_" + counter;
        counter += 1;
      }
      newInstructions.push(temp_element);
    });

    if (newInstructions.length > 0)
      newScanData[selectedScanIndex].instructions = newInstructions;
    setScenarios(newScanData);
    updateScanDataInstructions();
  };

  const handleDuplicateScan = () => {
    console.log(scenarios[selectedScanIndex].scan_id);
    const scan_name_to_duplicate = scenarios[selectedScanIndex].scan_name;
    console.log(scan_name_to_duplicate);
    const scan_names = [];
    scenarios.forEach((element) => {
      scan_names.push(element.scan_name);
    });
    var counter = 1;
    var new_name = scan_name_to_duplicate + " (" + counter + ")";
    while (true) {
      new_name = scan_name_to_duplicate + " (" + counter + ")";
      if (!scan_names.includes(new_name)) {
        break;
      }
      counter += 1;
    }
    addScan(new_name, scenarios[selectedScanIndex].instructions);
    //handlePopOverExit();
  };

  const handleExportScan = (id) =>
    fetch(`${env.REACT_APP_API_HOST}export_scan/`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ scan_id: scenarios[selectedScanIndex].scan_id }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${scenarios[selectedScanIndex].scan_name}.json`
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });

  const handleRemoveScan = async () => {
    console.log(scenarios[selectedScanIndex].scan_id);
    await fetch(`${env.REACT_APP_API_HOST}delete_scan/`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(scenarios[selectedScanIndex].scan_id),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response.isAuthenticated === "False") handleRoute("/login");
      })
      .catch(function (error) {
        console.log(error);
      });
    const newScanData = [...scenarios];
    newScanData.splice(selectedScanIndex, 1);
    setScenarios(newScanData);
    handleCloseModalRenameScan();
  };

  const HandleScanDeleteConf = () => {
    handleCloseScanDeleteConf();
    handleRemoveScan();
  };

  const handleCloseScanDeleteConf = () => {
    setOpenScanDeleteConf(false);
  };

  const handleOpenScanDeleteConf = (handleClose) => {
    setOpenScanDeleteConf(true);
    handleClose();
  };

  const handleScanRename = (index) => {
    console.log(scanRenameChange);
    const newScanData = [...scenarios];
    newScanData[index].scan_name = scanRenameChange;
    setScenarios(newScanData);
    handleCloseModalRenameScan();
    updateScanDataInstructions();
  };

  const handleScanNameButtonRename = () => {
    handleScanRename(selectedScanIndex);
  };
  const handleAddScenario = () => {
    setOpenModal(true);
  };

  const handleScanUniqueNames = (scan_name) => {
    for (var i = 0; i < scenarios.length; i++) {
      if (scenarios[i].scan_name.trim() === scan_name.trim()) {
        setErrorMsgNewScan("Scan name must be unique!");
        setErrorNewScan(true);
        return "true";
      } else {
        setErrorNewScan(false);
      }
    }
    return;
  };
  const addScan = (scanName, new_instructions, json_file = null) => {
    const data = new FormData();
    data.append("scan_name", scanName);
    data.append("instructions", JSON.stringify(new_instructions));
    if (json_file) {
      data.append("instructions_json", json_file);
    }

    fetch(`${env.REACT_APP_API_HOST}add_scan/`, {
      method: "POST",
      credentials: "include",
      body: data,
    })
      .then(async (res) => {
        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.error);
        }
        return res.json();
      })
      .then((response) => {
        if (response.isAuthenticated === "False") handleRoute("/login");
        const newScanData = [...scenarios];
        newScanData.push({
          scan_name: scanName,
          instructions: response.instructions,
          scan_id: response.scan_id.toString(),
        });
        // setScenarios(newScanData);
        getScanData();
        handleCloseModal();
      })
      .catch((err) => {
        setErrorNewScan(true);
        setErrorMsgNewScan(err.message);
      });
  };

  const handleScanNameButton = async (e) => {
    e.preventDefault();
    const checkName = handleScanUniqueNames(scanName);
    if (checkName === "true") return;
    if (scanName.trim().length === 0) {
      setErrorMsgNewScan("This field is required!");
      setErrorNewScan(true);
      return;
    } else {
      setErrorNewScan(false);
    }
    addScan(scanName, [], scanFile);
  };

  const handleScanNameChange = (e) => {
    setScanName(e.target.value);
  };
  const handleScanRenameChange = (e) => {
    setScanRenameChange(e.target.value);
  };
  const handlechangeOrder = (type, index) => {
    if (type === "up") {
      const newScanData = [...scenarios];
      let element = newScanData[selectedScanIndex].instructions[index];
      const toIndex = index - 1;
      if (toIndex >= 0) {
        newScanData[selectedScanIndex].instructions.splice(index, 1);
        newScanData[selectedScanIndex].instructions.splice(toIndex, 0, element);
        setScenarios(newScanData);
      }
    } else {
      const newScanData = [...scenarios];
      let element = newScanData[selectedScanIndex].instructions[index];
      const toIndex = index + 1;
      newScanData[selectedScanIndex].instructions.splice(index, 1);
      newScanData[selectedScanIndex].instructions.splice(toIndex, 0, element);
      setScenarios(newScanData);
    }
    console.log(scenarios[selectedScanIndex].instructions);
    updateScanDataInstructions();
  };

  const handleEditInstruction = (index) => {
    const selectedInstructionToEdit =
      scenarios[selectedScanIndex]["instructions"][index];
    setSelected(selectedInstructionToEdit["action"]);
    setSelectedRowData(selectedInstructionToEdit["items"]);
    setOnEdit(true);
    setSelectedRowToEdit(index);
  };
  const handleChangeInstructionData = (e, tag) => {
    const newScanData = [...scenarios];
    if (e.target.type === "checkbox") {
      newScanData[selectedScanIndex]["instructions"][selectedRowToEdit][
        "items"
      ][tag] = e.target.checked;
    } else {
      newScanData[selectedScanIndex]["instructions"][selectedRowToEdit][
        "items"
      ][tag] = e.target.value;
    }
    setScenarios(newScanData);
  };
  const handleChangesSave = () => {
    setOnEdit(false);
    setSelectedRowToEdit(null);
    setSelectedRowData(null);
    refreshStepNames(scenarios);
  };

  useEffect(() => {
    if (!StepsCount) {
      setStepsCount({
        click: 1,
        getUrl: 1,
        sleep: 1,
        validate: 1,
        input: 1,
        submit: 1,
        scroll: 1,
        execute_Javascript: 1,
        Bulk_URL_Open: 1,
      });
    }
    const handleClean = (id) => {
      const elem = document.getElementById(id);
      if (!elem) return;
      elem.value = "";
    };
    const clickInstructions = [
      "click_attr_name",
      "click_attr_value",
      "click_tag",
      "click_index",
      "step_name",
      "sensitiveCheckBox",
      "mandatoryCheckBox",
      "screenShotCheckBox",
    ];
    const inputInstructions = [
      "input_attr_name",
      "input_attr_value",
      "input_tag",
      "input_index",
      "input_text",
      "step_name",
      "sensitiveCheckBox",
      "mandatoryCheckBox",
      "screenShotCheckBox",
    ];
    const submitInstructions = [
      "step_name",
      "sensitiveCheckBox",
      "mandatoryCheckBox",
      "screenShotCheckBox",
    ];
    let scrollInstructions;
    if (scroll === "element") {
      scrollInstructions = [
        "step_name",
        "scroll_to",
        "scroll_attr_name",
        "scroll_attr_value",
        "scroll_tag_name",
        "scroll_index",
        "sensitiveCheckBox",
        "mandatoryCheckBox",
        "screenShotCheckBox",
      ];
    } else if (scroll === "location") {
      scrollInstructions = [
        "step_name",
        "scroll_to",
        "scroll_location",
        "sensitiveCheckBox",
        "mandatoryCheckBox",
        "screenShotCheckBox",
      ];
    }
    const ExecuteJavascriptInstructions = [
      "step_name",
      "jsCode",
      "sensitiveCheckBox",
      "mandatoryCheckBox",
      "screenShotCheckBox",
    ];
    const BulkURLOpenInstructions = [
      "step_name",
      "URLs",
      "sensitiveCheckBox",
      "mandatoryCheckBox",
      "screenShotCheckBox",
    ];
    if (onEdit) return;
    if (selected === "getUrl") handleClean("url");
    if (selected === "click")
      clickInstructions.forEach((element) => {
        handleClean(element);
      });
    if (selected === "sleep" || selected === "validate") handleClean("value");
    if (selected === "input")
      inputInstructions.forEach((element) => {
        handleClean(element);
      });
    //!!! cleanup of new commands
    if (selected === "submit") {
      submitInstructions.forEach((element) => {
        handleClean(element);
      });
    }
    if (selected === "scroll") {
      scrollInstructions.forEach((element) => {
        handleClean(element);
      });
    }
    if (selected === "execute_Javascript") {
      ExecuteJavascriptInstructions.forEach((element) => {
        handleClean(element);
      });
    }
    if (selected === "Bulk_URL_Open") {
      BulkURLOpenInstructions.forEach((element) => {
        handleClean(element);
      });
    }
    //!!!end cleanup of new commands
    updateScanDataInstructions();
    return;
  }, [
    onEdit,
    selectedRowData,
    selectedRowToEdit,
    selected,
    // updateScanDataInstructions,
    StepsCount,
    setStepsCount,
  ]);

  const handleDuplicateScanStep = (index) => {
    const newScanData = [...scenarios];
    const newStepsCount = { ...StepsCount };
    const element = JSON.parse(
      JSON.stringify(newScanData[selectedScanIndex].instructions[index])
    );
    newScanData[selectedScanIndex].instructions.push(element);
    refreshStepNames(newScanData);
  };

  const handleScrollTypeChange = (e) => {
    setScroll(e.target.value);
  };
  const handleChangeScrollLocation = (e) => {
    setScrollLocation(e.target.value);
  };

  const handleScanImportFileChange = (e) => {
    setScanFile(e.target.files[0]);
  };
  ///////////////
  const [anchorEl, setAnchorEl] = useState(null);
  const [openIndex, setOpenIndex] = useState(null);
  const [filter, setFilter] = useState("");
  const [startLaunch, setStartLaunch] = useState(false);

  const handleStartLaunch = () => {
    setStartLaunch(true);
  };

  const resetSearchInput = (event) => {
    const inputElement =
      event.target.parentElement.parentElement.previousElementSibling;
    if (inputElement) {
      inputElement.value = "";
      setFilter("");
    }
  };

  const handleCloseActionMenu = () => {
    setAnchorEl(null);
    setOpenIndex(null);
  };
  return (
    <>
      {startLaunch && (
        <ScanLaunch
          scanId={scenarios[selectedScanIndex].scan_id}
          scanName={scenarios[selectedScanIndex].scan_name}
          scanType={"scenario"}
          setStartLaunch={setStartLaunch}
        />
      )}

      <Typography gutterBottom variant="h5">
        Scenarios
      </Typography>

      <Grid container spacing={2}>
        <Grid item md={3}>
          <Button
            variant="contained"
            fullWidth
            startIcon={<AddSharpIcon />}
            sx={{ mb: 2 }}
            onClick={handleAddScenario}
          >
            Add Scenario
          </Button>
          <Paper square sx={{ p: 1 }}>
            <Box
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 99,
                bgcolor: "#fff",
                py: 1,
              }}
            >
              <TextField
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                fullWidth
                size="small"
                label="Search"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(event) => {
                          resetSearchInput(event);
                        }}
                      >
                        <ClearSharpIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <List>
              {scenarios.map((s, i) => {
                if (
                  filter.length > 0 &&
                  !s.scan_name.toLowerCase().includes(filter.toLowerCase())
                )
                  return;
                return (
                  <ListItem
                    key={i}
                    onClick={() => handleScanSelecting(i, s.scan_name)}
                    disablePadding
                    divider
                    sx={{ bgcolor: selectedScanIndex === i ? green[200] : "" }}
                    secondaryAction={
                      <IconButton
                        aria-label="more"
                        id={`actions-btn-${i}`}
                        aria-haspopup="true"
                        onClick={(e) => {
                          setAnchorEl(e.currentTarget);
                          setOpenIndex(i);
                        }}
                      >
                        <MoreVertSharpIcon />
                      </IconButton>
                    }
                  >
                    <Menu
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={openIndex === i}
                      onClose={handleCloseActionMenu}
                    >
                      <MenuItem
                        onClick={() => {
                          handleCloseActionMenu();
                          handleOpenScanRename();
                        }}
                      >
                        <ListItemIcon>
                          <EditSharpIcon />
                        </ListItemIcon>
                        Rename
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleCloseActionMenu();
                          handleDuplicateScan();
                        }}
                      >
                        <ListItemIcon>
                          <FileCopySharpIcon />
                        </ListItemIcon>
                        Duplicate
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleCloseActionMenu();
                          handleExportScan();
                        }}
                      >
                        <ListItemIcon>
                          <GetAppSharpIcon />
                        </ListItemIcon>
                        Export
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleCloseActionMenu();
                          handleOpenScanDeleteConf();
                        }}
                      >
                        <ListItemIcon>
                          <DeleteOutlineSharpIcon />
                        </ListItemIcon>
                        Delete
                      </MenuItem>
                    </Menu>
                    <ListItemButton>
                      <ListItemText>
                        <Typography variant="body2">{s.scan_name}</Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </Grid>
        <Grid item md={9}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Paper square sx={{ p: 2, position: "sticky", zIndex: 99, top: 0 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6" fontWeight={400} color="primary">
                  {scenarios[selectedScanIndex]?.scan_name}
                </Typography>
                <Tooltip title="Launch">
                  <Button
                    onClick={handleStartLaunch}
                    variant="outlined"
                    startIcon={<PlayCircleOutlineSharpIcon />}
                  >
                    Launch
                  </Button>
                </Tooltip>
              </Box>
            </Paper>
            <Paper square>
              <ScenarioScanTable
                selected={selected}
                setSelected={setSelected}
                handleStepsCountChange={handleStepsCountChange}
                StepsCount={StepsCount}
                setStepsCount={setStepsCount}
                handleAddinstruction={handleAddinstruction}
                scanData={scenarios ? scenarios : []}
                selectedScanIndex={selectedScanIndex}
                handleRemoveInstruction={handleRemoveInstruction}
                handlechangeOrder={handlechangeOrder}
                handleEditInstruction={handleEditInstruction}
                onEdit={onEdit}
                selectedRowToEdit={selectedRowToEdit}
                handleChangesSave={handleChangesSave}
                handleChangeInstructionData={handleChangeInstructionData}
                selectedRowData={selectedRowData}
                handleDuplicateScanStep={handleDuplicateScanStep}
                setScroll={setScroll}
                scroll={scroll}
                handleScrollTypeChange={handleScrollTypeChange}
                scrollLocation={scrollLocation}
                setScrollLocation={setScrollLocation}
                handleChangeScrollLocation={handleChangeScrollLocation}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>

      <Dialog
        fullWidth
        open={openModalRenameScan}
        onClose={handleCloseModalRenameScan}
      >
        <DialogTitle>Scenario Rename</DialogTitle>
        <DialogContent>
          <TextField
            label="Scenario Name"
            fullWidth
            sx={{ mt: 2 }}
            type="text"
            name=""
            onChange={(e) => handleScanRenameChange(e)}
            defaultValue={scanRenameChange}
            required
          />
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: 2,
            }}
          >
            <Button
              onClick={handleCloseModalRenameScan}
              variant="contained"
              size="small"
              color="error"
            >
              Cancel
            </Button>
            <Button
              onClick={handleScanNameButtonRename}
              variant="contained"
              size="small"
              color="success"
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog open={openScanDeleteConf} onClose={handleCloseScanDeleteConf}>
        <DialogTitle>Delete Scenario</DialogTitle>
        <DialogContent>
          <Alert severity="warning">
            You are about to delete{" "}
            <Typography variant="subtitle1" fontWeight={600}>
              {scenarios[selectedScanIndex]?.scan_name}.
            </Typography>
            Would you like to proceed with the deletion?
          </Alert>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: 2,
            }}
          >
            <Button
              color="inherit"
              variant="contained"
              size="small"
              onClick={handleCloseScanDeleteConf}
            >
              Cancel
            </Button>
            <Button
              onClick={HandleScanDeleteConf}
              variant="contained"
              size="small"
              color="error"
            >
              Delete
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        component="form"
        noValidate={false}
        open={openModal}
        onClose={handleCloseModal}
        onSubmit={handleScanNameButton}
      >
        <DialogTitle>Create New Scenario</DialogTitle>
        <DialogContent
          sx={{
            mt: 1,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "end",
          }}
        >
          <Divider />
          {errNewScan && (
            <Alert sx={{ width: "100%" }} severity="error">
              {errMsgNewScan}
            </Alert>
          )}
          <TextField
            required
            fullWidth
            label="Name"
            type="text"
            name=""
            id="new_scenario_name"
            onChange={(e) => handleScanNameChange(e)}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: 1,
            }}
          >
            {scanFile && (
              <Typography variant="subtitle2">{scanFile.name}</Typography>
            )}
            <Button
              size="small"
              color="inherit"
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload JSON (optional)
              <VisuallyHiddenInput
                type="file"
                accept=".json"
                onChange={(e) => handleScanImportFileChange(e)}
              />
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: 2,
            }}
          >
            <Button
              size="small"
              variant="contained"
              color="error"
              type="button"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              color="success"
              type="submit"
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Modal
        open={updateScenarioErrorModal}
        onClose={() => {
          setUpdateScenarioErrorModal(false);
          setUpdateScenarioError(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={errorModalBoxStyle}>
          <Typography variant="h6">
            <FontAwesomeIcon
              icon="warning"
              className="mr-2 text-yellow-900"
            ></FontAwesomeIcon>
            Error
          </Typography>
          <p>{updateScenarioError}</p>
        </Box>
      </Modal>

      <MadalElem
        message={ModaInfoMessage}
        openModalElemInfo={openModalElemInfo}
        setOpenModalElemInfo={setOpenModalElemInfo}
        err={err}
        setError={setError}
      />
      <SnackBar message={snackBarMessage} />
    </>
  );
}

export default ScenarioScan;
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
