import { Box, Card, CardActionArea, Typography } from "@mui/material";
import { blue, green, grey, orange } from "@mui/material/colors";

import { stringToDateFormat } from "../../utils";
import { useSearchParams } from "react-router-dom";

export default function ScansReportsListCard({
  id,
  name,
  type,
  report_count,
  last_run,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentScanId = searchParams.get("scanId");
  const currentScanType = searchParams.get("scanType");

  const handlePrimaryAction = () => {
    setSearchParams({ scanId: id, scanType: type });
  };
  return (
    <Card square>
      <CardActionArea onClick={handlePrimaryAction}>
        <Box
          display={"flex"}
          borderBottom={1}
          borderColor={grey[400]}
          bgcolor={
            currentScanId == id && currentScanType === type
              ? green["100"]
              : "white"
          }
        >
          <Box
            height={"auto"}
            width={"10px"}
            bgcolor={type === "scenario" ? blue[400] : orange[500]}
          ></Box>
          <Box
            px={2}
            py={1}
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
          >
            <Typography width={"100%"} variant="subtitle">
              {name}
            </Typography>
            <Typography variant="caption" color={grey[500]}>
              {report_count} Report
              {report_count === 1 ? <></> : <>s </>}
            </Typography>
            {last_run && (
              <Typography variant="caption">
                Last Run: {stringToDateFormat(last_run, true)}
              </Typography>
            )}
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}
