const ScheduleIcon = ({ fill = "#969BA0", stroke = "#969BA0", ...props }) => {
  return (
    <svg
      width="26"
      height="28"
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3.25" y="7" width="19.5" height="17.5" rx="2" stroke={stroke} />
      <path
        d="M3.25 11C3.25 9.11438 3.25 8.17157 3.83579 7.58579C4.42157 7 5.36438 7 7.25 7H18.75C20.6356 7 21.5784 7 22.1642 7.58579C22.75 8.17157 22.75 9.11438 22.75 11V11.6667H3.25V11Z"
        fill={fill}
      />
      <path
        d="M7.58301 3.5L7.58301 7"
        stroke="#969BA0"
        strokeLinecap="round"
      />
      <path d="M18.417 3.5L18.417 7" stroke="#969BA0" strokeLinecap="round" />
      <rect
        x="7.58301"
        y="14"
        width="4.33333"
        height="2.33333"
        rx="0.5"
        fill={fill}
      />
      <rect
        x="7.58301"
        y="18.666"
        width="4.33333"
        height="2.33333"
        rx="0.5"
        fill={fill}
      />
      <rect
        x="14.083"
        y="14"
        width="4.33333"
        height="2.33333"
        rx="0.5"
        fill={fill}
      />
      <rect
        x="14.083"
        y="18.666"
        width="4.33333"
        height="2.33333"
        rx="0.5"
        fill={fill}
      />
    </svg>
  );
};
export default ScheduleIcon;
