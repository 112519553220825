import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";

import HomePageTag from "../../../components/reports/homePageTag";
import Loader from "../../../components/loader";
import ReportsNav from "../../../components/reports/reportsNav";
import ScanReportSummary from "../../ScanReportDetails/ScanReportSummary";
import Statistics from "../../../components/reports/statistics";
import { activeReportActions } from "../../../store";
import { env } from "../../../config";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

const TagSummary = () => {
  const [tagSummary, setTagSummary] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [id]);

  useEffect(() => {
    dispatch(
      activeReportActions.setActiveReport({
        uniqueTags: tagSummary?.unique_tags,
        reportName: tagSummary?.report_name,
      })
    );
  }, [tagSummary]);

  const getData = async (id) => {
    setIsLoading(true);
    setTagSummary(null);
    await fetch(`${env.REACT_APP_API_HOST}get_report_tags_summary/${id}`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        setIsLoading(false);
        if (response.Success === "True") {
          setTagSummary(response.data);
        }
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  return (
    <div>
      {isLoading && <Loader />}
      {!isLoading && tagSummary && (
        <>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <ScanReportSummary {...tagSummary} />
            </Grid>
            <Grid item md={9} width={"-webkit-fill-available"}>
              <HomePageTag tagSummary={tagSummary} />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
export default TagSummary;
