import { useEffect, useState } from "react";

import { LineChart } from "@mui/x-charts";
import { Typography } from "@mui/material";
import { green, orange, red } from "@mui/material/colors";

export default function ReferralsTrend({ referrals, upgrades, trendDays }) {
  const [trend, setTrend] = useState(null);
  const [upgradeTrend, setUpgradeTrend] = useState(null);
  const getDates = () => {
    const dates = [];
    const today = new Date();
    for (let i = trendDays; i > 0; i--) {
      const priorDate = new Date().setDate(today.getDate() - i);
      dates.push(new Date(priorDate).toLocaleDateString());
    }
    dates.push(today.toLocaleDateString());
    return dates;
  };
  const getRefTend = () => {
    const refTrend = {};
    getDates().forEach((date) => {
      let count = 0;
      referrals.forEach((ref) => {
        const refDate = new Date(ref.signup_date).toLocaleDateString();
        if (refDate === date) count++;
      });
      refTrend[date] = count;
    });
    return refTrend;
  };

  const getUpgrades = () => {
    const refTrend = {};
    getDates().forEach((date) => {
      let count = 0;
      upgrades.forEach((ref) => {
        const refDate = new Date(ref.upgrade_date).toLocaleDateString();
        if (refDate === date) count++;
      });
      refTrend[date] = count;
    });
    return refTrend;
  };

  useEffect(() => {
    setTrend(getRefTend());
    setUpgradeTrend(getUpgrades());
  }, [referrals, trendDays]);

  return (
    <>
      {trend && (
        <LineChart
          height={300}
          xAxis={[
            {
              scaleType: "point",
              data: Object.keys(trend),
            },
          ]}
          series={[
            {
              data: Object.values(trend),
              curve: "natural",
              area: true,
              color: orange[500],
              label: `Signups in the last ${trendDays} days`,
            },
            {
              data: Object.values(upgradeTrend),
              curve: "natural",
              area: true,
              color: green[400],
              label: `Upgrades in the last ${trendDays} days`,
            },
          ]}
        />
      )}
    </>
  );
}
