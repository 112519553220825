import React, { useState } from "react";
import TopBar from "./components/TopBar";
import SideBar from "./components/SideBar";
import "./mainStyle.css";
function Main({
  children,
  notifNumber,
  shakeNotif,
  setShakeNotif,
  setNotifNumber,
  get_notifications,
  notifs,
}) {
  const [sideBarvisible, setSideBarVisible] = useState(false);

  return (
    <div>
      <TopBar
        sideBarvisible={sideBarvisible}
        notifNumber={notifNumber}
        shakeNotif={shakeNotif}
        setShakeNotif={setShakeNotif}
        setNotifNumber={setNotifNumber}
        get_notifications={get_notifications}
        notifs={notifs}
      />
      <SideBar
        sideBarvisible={sideBarvisible}
        setSideBarVisible={setSideBarVisible}
      />
      <div
        className={`main_content_widthChange ${
          sideBarvisible ? "main_content" : "main_content_sideBarVisible"
        }`}
        style={{ marginLeft: sideBarvisible ? "200px" : "100px" }}
      >
        {children}
      </div>
    </div>
  );
}

export default Main;
