import { cloneElement, useState } from "react";

import AppSidebar from "./components/appSideBar";
import ReportsNav from "../components/reports/reportsNav";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Layout = ({ children, title, notifs, get_notifications }) => {
  const location = useLocation();
  const isReportRoute = location.pathname.includes("/report/");
  const isReportValidation = location.pathname.includes("/report/validation");

  const [vid, setVid] = useState(-1);
  const [childComponent, setChildComponent] = useState(children);

  useEffect(() => {
    if (isReportValidation) {
      const element = cloneElement(children, { ...{ vid: vid } });
      setChildComponent(element);
    } else {
      setChildComponent(children);
    }
  }, [vid, location.pathname]);

  return (
    <div className="bg-white-800">
      <div className="flex mt-16">
        <AppSidebar
          title={title}
          notifs={notifs}
          get_notifications={get_notifications}
        />
        <div className="bg-gradient-to-r from-bluish-gradient via-yellow-gradient to-yellow-gradient  w-full  overflow-auto">
          <div className="mainLayout">
            <div className="sm:mx-0 p-4 h-full">
              {isReportRoute && <ReportsNav setVid={setVid} />}
              {childComponent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Layout;
