import companyLogo from "../../assets/logo-final.png";
import smallIcon from "../../assets/smallLogo.svg";

const CompanyIcon = () => {
  return (
    <div className="flex items-center gap-4 ">
      <div className="rounded-lg bg-green p-3">
        <img src={smallIcon} />
      </div>
      <img src={companyLogo} className="h-[43px]" />
    </div>
  );
};
export default CompanyIcon;
