import "./PricingTable.css";

import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { green, grey, red, yellow } from "@mui/material/colors";
import { useCallback, useEffect, useState } from "react";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { CardFooter } from "@material-tailwind/react";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { env } from "../../config";

export default function PricingTable({ display = "default" }) {
  const [upgradeForm, setUpgradeForm] = useState(false);
  const [plans, setPlans] = useState([]);
  const [paidPlans, setPaidPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resMessage, setResMessage] = useState(null);
  const [features, setFeatures] = useState([]);

  const handleUpgradeFormClose = () => {
    setUpgradeForm(false);
  };

  const getPlans = async () => {
    const res = await fetch(`${env.REACT_APP_API_HOST}get_plans/`, {
      method: "get",
      credentials: "include",
    });
    const resData = await res.json();
    const paid = resData.filter((e) => e.amount !== 0);
    setPlans([...resData]);
    setPaidPlans([...paid]);
    const _features = [];
    resData.forEach((p) => {
      p.features.forEach((f) => {
        if (!_features.includes(f)) _features.push(f);
      });
    });
    setFeatures([..._features]);
  };

  const upgradeRequestSend = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResMessage(null);
    const fd = new FormData(e.currentTarget);
    const data = {
      plan: fd.get("plan"),
      comments: fd.get("comments"),
    };
    try {
      const res = await fetch(`${env.REACT_APP_API_HOST}upgrades/`, {
        method: "post",
        credentials: "include",
        body: JSON.stringify(data),
      });
      const resData = await res.json();
      if (!res.ok) throw new Error(resData.error);
      setResMessage({
        severity: "success",
        message:
          "Your request has been sent. Our team will process it shortly.",
      });
      setTimeout(() => {
        setResMessage(null);
        handleUpgradeFormClose();
      }, 3000);
    } catch (resError) {
      setResMessage({ severity: "error", message: resError.message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  const upgradeFormDialog = (
    <Dialog
      component="form"
      onSubmit={upgradeRequestSend}
      open={upgradeForm}
      onClose={handleUpgradeFormClose}
      fullWidth
    >
      <DialogTitle>Membership Upgrade</DialogTitle>
      {loading ? (
        <>
          <DialogContent>
            <Alert severity="info">Sending your upgrade request</Alert>
            <LinearProgress color="info" />
          </DialogContent>
        </>
      ) : (
        <>
          <DialogContent>
            {resMessage && (
              <Alert sx={{ mt: 2 }} severity={resMessage.severity}>
                {resMessage.message}
              </Alert>
            )}

            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}
            >
              <Typography variant="body2">
                Request an upgrade to a new plan.
              </Typography>
              <FormControl required size="small">
                <InputLabel>Plan</InputLabel>
                <Select name="plan" label="Plan">
                  {paidPlans.map((p, i) => (
                    <MenuItem key={i} value={p.plan_id}>
                      {p.plan}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Comments"
                size="small"
                name="comments"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                gap: 2,
              }}
            >
              <Button
                color="error"
                variant="contained"
                size="small"
                onClick={handleUpgradeFormClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="small"
                color="primary"
              >
                Next
              </Button>
            </Box>
          </DialogActions>
        </>
      )}
    </Dialog>
  );

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Paper
        sx={{
          width: "auto",
          display: "flex",

          flexDirection: "column",
          bgcolor: "#fff",
          overflow: "auto",
        }}
      >
        <Typography
          textAlign={"center"}
          sx={{ position: "sticky", left: 0 }}
          variant="h4"
          gutterBottom
          fontWeight={800}
        >
          {
            {
              default: `Packages and Pricing`,
              "no-auth": `Packages and Pricing`,
              referral: `50% Referral Reward on Our Packages`,
            }[display]
          }
        </Typography>
        <table>
          <thead>
            <tr>
              {["default", "no-auth"].includes(display) ? (
                <th className="feature_header">Plans</th>
              ) : (
                <th></th>
              )}
              {plans.map((p, pi) => (
                <th key={pi} className="plan_headers">
                  <Box
                    sx={{
                      width: "100%",
                      minWidth: "10rem",
                      height: "100%",
                      p: 0.5,
                    }}
                  >
                    <Typography variant="body1" fontWeight="600">
                      {p.plan}
                    </Typography>
                    <Typography
                      fontWeight={600}
                      variant="body2"
                      textAlign={"center"}
                      color="primary"
                    >
                      {
                        {
                          default:
                            p.amount === null
                              ? "Contact Sales"
                              : p.amount === 0
                              ? "Free Forever"
                              : `$${p.amount.toLocaleString()}`,
                          "no-auth":
                            p.amount === null
                              ? "Contact Sales"
                              : p.amount === 0
                              ? "Free Forever"
                              : `$${p.amount.toLocaleString()}`,
                          referral:
                            p.amount === 0
                              ? "-"
                              : !p.amount
                              ? "Custom Fee"
                              : `$${((p.amount * 3) / 2).toLocaleString()}`,
                        }[display]
                      }
                    </Typography>
                    <Typography
                      paragraph
                      variant="caption"
                      textAlign={"center"}
                    >
                      {
                        {
                          default:
                            p.amount === null
                              ? "custom billing"
                              : p.amount === 0
                              ? ""
                              : `per month billed quartely`,
                          "no-auth":
                            p.amount === null
                              ? "custom billing"
                              : p.amount === 0
                              ? ""
                              : `per month billed quartely`,
                          referral: p.amount === 0 ? "" : `on the 1st Quarter`,
                        }[display]
                      }
                    </Typography>
                  </Box>
                </th>
              ))}
            </tr>
          </thead>
          {["default", "no-auth"].includes(display) && (
            <tbody>
              {features.map((f, fi) => (
                <tr key={fi} className="hover:bg-lime-100">
                  <td className="feature_header border-b">{f}</td>
                  {plans.map((p, pi) => (
                    <td key={pi} className="border-b">
                      {p.features.includes(f) ? (
                        <CheckSharpIcon sx={{ color: green[600] }} />
                      ) : (
                        <CloseSharpIcon sx={{ color: red[600] }} />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
              <tr className="hover:bg-lime-100">
                <td className="feature_header border-b">Monthly Scan Points</td>
                {plans.map((p, pi) => (
                  <td key={pi} className="border-b">
                    <Typography sx={{ fontSize: 14 }}>
                      {p.points ? p.points.toLocaleString() : "Custom"}
                    </Typography>
                  </td>
                ))}
              </tr>
              <tr className="hover:bg-lime-100">
                <td className="feature_header border-b">Storage</td>
                {plans.map((p, pi) => (
                  <td key={pi} className="border-b">
                    <Typography sx={{ fontSize: 14 }}>
                      {p.storage ? p.storage : "Unlimited"}
                    </Typography>
                  </td>
                ))}
              </tr>
            </tbody>
          )}
        </table>
        <CardActions>
          {display === "default" && (
            <Box sx={{ mx: "auto" }}>
              <Button
                onClick={() => setUpgradeForm(true)}
                variant="contained"
                fullWidth
              >
                Get Started
              </Button>
            </Box>
          )}
        </CardActions>
      </Paper>

      {upgradeFormDialog}
    </Box>
  );
}
