import { forwardRef, useEffect, useMemo, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Chip from "@mui/material/Chip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Loader from "../../../components/loader";
import MaterialTable from "@material-table/core";
import { brown, blue, green, indigo, grey } from "@mui/material/colors";
import StyleSharpIcon from "@mui/icons-material/StyleSharp";
import CookieSharpIcon from "@mui/icons-material/CookieSharp";
import DataObjectSharpIcon from "@mui/icons-material/DataObjectSharp";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import CloseIcon from "@mui/icons-material/Close";

import {
  Popover,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  DialogContent,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Box,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Tabs,
  Tab,
  Grid,
  TextField,
  InputAdornment,
  Autocomplete,
  ListItemText,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Button,
  Switch,
  Tooltip,
} from "@mui/material";
import ReactJson from "react-json-view";
import Typography from "@mui/material/Typography";
import defaultTag from "../../../assets/default_tag.png";
import { env } from "../../../config";
import { useNavigate, useSearchParams } from "react-router-dom";
import HttpSharpIcon from "@mui/icons-material/HttpSharp";
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToolbarInternalButtons,
} from "material-react-table";

//TODO - Investigate why reports are being loaded twice
//TODO - Round All Averages

const ReportCommand = () => {
  const [jsObjectsFilter, setJsObjectsFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isCommand, setIsCommand] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [commandData, setCommandData] = useState([]);
  const [urlData, setUrlData] = useState([]);
  const [currentReport, setCurrentReport] = useState({});
  const [selectedJsObject, setSelectedJsObject] = useState(0);
  const [showLargeImg, setShowLargeImg] = useState(false);
  const handleClose = () => {
    setShowLargeImg(false);
  };
  const handleOpenLargeImg = () => {
    setShowLargeImg(true);
  };
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const changeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleViewChange = (event) => {
    switch (event.target.value) {
      case "pages":
        setIsCommand(false);
        break;
      case "commands":
        setIsCommand(true);
        break;
      default:
        setIsCommand(false);
    }
  };

  const pageSize = 10;

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  useEffect(() => {
    if (id) {
      setCurrentPage(1);
      setUrlData([]);
      setCommandData([]);
      getData(id);
    }
  }, [id]);

  useEffect(() => {
    if (currentPage) {
      fetchReportPage(id);
    }
  }, [currentPage]);

  const tasks = useMemo(() => {
    return (
      currentReport?.waterfall?.map((item) => ({
        method: item.method,
        status_code: item.status_code,
        endpoint: item.endpoint,
        start_time: item.start_time,
        end_time: item.end_time,
        regex_name: item.technology_name,
        regex_icon: item.technology_icon,
      })) ?? []
    );
  }, [currentReport]);

  const getData = async (id) => {
    setIsLoading(true);
    await fetch(
      `${env.REACT_APP_API_HOST}get_report_pages_commands/${id}?page=1&pageSize=${pageSize}`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setIsLoading(false);
        if (response.Success === "True") {
          /*setIsCommand(false)
          setCurrentReport(response.data.pages[0])
          setCommandData(response.data.commands)
          setUrlData(response.data.pages)*/
          let firstReport;
          if (response.data.pages.page === 1)
            firstReport =
              response.data.pages.data.length > 0
                ? {
                    ...response.data.pages.data[0],
                    ...{ reportType: "pages", index: 0 },
                  }
                : {
                    ...response.data.commands.data[0],
                    ...{ reportType: "commands", index: 0 },
                  };
          setIsCommand(false);
          setCurrentReport(firstReport);
          setCommandData([...commandData, ...response.data.commands.data]);
          setUrlData([...urlData, ...response.data.pages.data]);
          setTotalPages(
            response.data.pages.totalPages > response.data.commands.totalPages
              ? response.data.pages.totalPages
              : response.data.commands.totalPages
          );
          setCurrentPage(2);
          //fetchReportPage(id)
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      });
  };

  const fetchReportPage = async (id) => {
    if (totalPages <= currentPage) return;
    await fetch(
      `${env.REACT_APP_API_HOST}get_report_pages_commands/${id}?page=${currentPage}&pageSize=${pageSize}`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setIsLoading(false);
        if (response.Success === "True") {
          setCommandData([...commandData, ...response.data.commands.data]);
          setUrlData([...urlData, ...response.data.pages.data]);
          setCurrentPage(currentPage + 1);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const tags = useMemo(() => {
    return (
      currentReport?.tags?.map((item) => ({
        open: false,
        technologyName: item.technology_name,
        technologyIcon: item.techonology_icon,
        avgLoadTime:
          typeof item.avg_load_time === "number"
            ? item.avg_load_time.toFixed(2) + "ms"
            : "0ms",
        numberOfInstances: item.number_of_instances,
        instanceData: item.instances_data,
      })) ?? []
    );
  }, [currentReport]);
  const jsObject = useMemo(() => {
    return currentReport?.js_objects ?? [];
  }, [currentReport]);
  const errorsList = useMemo(() => {
    return currentReport?.errors_list ?? [];
  }, [currentReport]);

  const setReport = (report) => {
    setCurrentReport(report);
    setSelectedJsObject(0);
  };

  const waterFallTiming = useMemo(() => {
    const chartStart = Math.min(...tasks.map(({ start_time }) => start_time));
    const chartEnd = Math.max(...tasks.map(({ end_time }) => end_time));
    return { duration: chartEnd - chartStart, chartStart, chartEnd };
  }, [currentReport]);

  const cookies = useMemo(() => {
    return currentReport?.cookies ?? [];
  }, [currentReport]);

  const getSelectedJsObject = (data, index) => {
    if (data === undefined || data[index] === undefined) return {};
    //if(jsObjectsFilter === ''){
    try {
      const jsonObjectString = data[index].JS_object.replaceAll(
        "'",
        '"'
      ).replaceAll("True", "true");
      let jsObject = JSON.parse(jsonObjectString);
      if (jsObjectsFilter.length > 0) {
        const filtered = filterObj(jsObject, jsObjectsFilter);
        return filtered;
      }
      return jsObject;
    } catch (error) {
      console.log({ error });
      return {};
    }

    //}
    //else return {}
  };
  function filterObj(object, filter) {
    let result = [];
    Object.keys(object).forEach((key) => {
      if (
        key.toLowerCase().includes(filter.toLowerCase()) ||
        object[key].toString().toLowerCase().includes(filter.toLowerCase())
      ) {
        result.push(object);
      } else if (typeof object[key] === "object") {
        const filtered = filterObj(object[key], filter);
        if (filtered.length > 0) {
          filtered.forEach((item) => {
            result.push({ [key]: item });
          });
        }
      }
    });
    return result;
  }

  const extractPath = (urlString) => {
    try {
      const url = new URL(urlString);
      const path = `${url.pathname}${url.search}`;
      return path;
    } catch (_) {
      return urlString;
    }
  };

  const resourcesColumns = [
    {
      header: "Resource",
      accessorKey: "regex_name",
      size: 300,
      grow: 1,
      Cell: ({ row }) => (
        <div className="flex gap-4 items-center">
          <img
            src={row.original.regex_icon || defaultTag}
            alt="fallback-image.jpg"
            className="w-6"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = defaultTag;
            }}
          />
          <span className="truncate w-[200px]" title={row.original.endpoint}>
            {row.original.regex_name || row.original.endpoint}
          </span>
        </div>
      ),
      Header: ({ column }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {column.columnDef.header}
          <Tooltip title={"Toggle Technologies"}>
            <Switch onChange={handleFilterTechnologies} />
          </Tooltip>
        </Box>
      ),
    },
    {
      header: "Method",
      accessorKey: "method",
      size: 100,
      grow: 1,
    },
    {
      header: "Timing",
      accessorKey: "start_time",
      size: 250,
      grow: 2,
      Cell: ({ row }) => {
        const duration =
          ((row.original.end_time - row.original.start_time) * 100) /
          waterFallTiming.duration;
        const offset =
          ((row.original.start_time - waterFallTiming.chartStart) * 100) /
          waterFallTiming.duration;
        return (
          <div className="w-full pr-[22px]">
            <div
              className="bg-green h-2 rounded-lg"
              style={{
                width: `calc(22px + ${duration}%)`,
                marginLeft: `calc(${offset}%)`,
              }}
            ></div>
          </div>
        );
      },
      Footer: () => (
        <div className="w-full flex justify-between">
          <div className="">0ms</div>
          <div>
            {Math.max(...tasks.map(({ end_time }) => end_time)) -
              Math.min(...tasks.map(({ start_time }) => start_time))}
            ms
          </div>
        </div>
      ),
    },
    {
      header: "Status code",
      accessorKey: "status_code",
      Cell: ({ renderedCellValue }) => (
        <Chip
          label={renderedCellValue || "N/A"}
          sx={{ background: "#12b922", color: "white" }}
        />
      ),
      size: 100,
      grow: 1,
    },
    {
      header: "Duration",
      accessorKey: "end_time",
      grow: 1,
      size: 100,
      Cell: ({ row }) => `${row.original.end_time - row.original.start_time}ms`,
    },
  ];

  const [filteredTasks, setFilteredTasks] = useState(tasks);

  useEffect(() => {
    setFilteredTasks([...tasks]);
  }, [tasks]);

  const handleFilterTechnologies = (event) => {
    if (event.target.checked) {
      setFilteredTasks([...tasks.filter((e) => e.regex_name)]);
    } else {
      setFilteredTasks([...tasks]);
    }
  };

  const resourcesTable = useMaterialReactTable({
    columns: resourcesColumns,
    data: filteredTasks,
    initialState: { density: "compact" },
    enableColumnOrdering: true,
    enableFullScreenToggle: false,
    enableColumnResizing: true,
    renderTopToolbar: ({ table }) => (
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: green[400] }}>
            <StyleSharpIcon />
          </Avatar>
        }
        title={<Typography variant="h6">Resources Performance</Typography>}
        action={
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToolbarInternalButtons table={table} />
          </Box>
        }
      />
    ),
  });

  const imgDialog = (
    <>
      <Dialog open={showLargeImg} onClose={handleClose} fullScreen>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <img src={currentReport.screenshot} width={"100%"} />
        </DialogContent>
      </Dialog>
    </>
  );

  return (
    <div>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          {currentReport.screenshot && <>{imgDialog}</>}
          <Paper sx={{ mb: 2, position: "sticky", top: "0rem", zIndex: 99 }}>
            <CardHeader
              avatar={
                <Avatar>
                  <HttpSharpIcon />
                </Avatar>
              }
              title={
                <Typography>
                  {currentReport.page_title || currentReport.step_name}
                </Typography>
              }
              subheader={
                <Typography variant="caption">
                  {currentReport.page_url}
                </Typography>
              }
              action={
                <FormControl sx={{ mb: 2 }}>
                  <FormLabel>View by</FormLabel>
                  <RadioGroup
                    row
                    defaultValue={"pages"}
                    onChange={handleViewChange}
                  >
                    <FormControlLabel
                      value="pages"
                      control={<Radio />}
                      label="Page URL"
                    />
                    <FormControlLabel
                      value="commands"
                      control={<Radio />}
                      label="Commands"
                    />
                  </RadioGroup>
                </FormControl>
              }
            />

            <CardContent>
              {isCommand ? (
                <Autocomplete
                  id="commands"
                  options={commandData}
                  autoHighlight
                  getOptionLabel={(option) => option.step_name ?? ""}
                  renderOption={(props, option) => (
                    <ListItem {...props} component="li">
                      <ListItemIcon>
                        {
                          {
                            success: <CheckCircleOutlineIcon color="success" />,
                            failed: <HighlightOffSharpIcon color="error" />,
                          }[option.status]
                        }
                      </ListItemIcon>

                      {option.status === "failed" && (
                        <>
                          <Button
                            color="info"
                            onClick={() => {
                              navigate(`/report/log?id=${id}`);
                            }}
                          >
                            <Typography
                              variant="caption"
                              sx={{ textTransform: "capitalize" }}
                            >
                              View Log
                            </Typography>
                          </Button>
                        </>
                      )}
                      <ListItemText
                        primary={option.step_name ?? ""}
                        secondary={option.mandatory && `Mandatory Step`}
                        onClick={() => {
                          console.log(props);

                          if (option.status !== "failed") {
                            setReport({
                              ...commandData[props["data-option-index"]],
                              ...{
                                reportType: "commands",
                                index: props["data-option-index"],
                              },
                            });
                          }
                        }}
                      />
                    </ListItem>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Find Command" size="small" />
                  )}
                />
              ) : (
                <Autocomplete
                  id="pages"
                  options={urlData}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.page_url
                      ? `${extractPath(option.page_url)} - ${option.page_title}`
                      : ""
                  }
                  renderOption={(props, option) => (
                    <Box {...props} component="li">
                      <ListItemText
                        primary={option.page_title ?? ""}
                        secondary={option.page_url}
                        onClick={() => {
                          setReport({
                            ...urlData[props["data-option-index"]],
                            ...{
                              reportType: "pages",
                              index: props["data-option-index"],
                            },
                          });
                        }}
                      />
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Find Page" size="small" />
                  )}
                />
              )}
            </CardContent>

            <Tabs
              sx={{ bgcolor: grey[200], mt: 2 }}
              variant="fullWidth"
              value={activeTab}
              onChange={changeTab}
            >
              <Tab label="Overview" id="overview" aria-controls={0} />
              <Tab label="Tags & Parameters" id="tags" aria-controls={1} />
              <Tab label="Data Layer" id="datalayer" aria-controls={2} />
              <Tab label="Cookies" id="cookies" aria-controls={3} />
            </Tabs>
          </Paper>

          <Box role="tabpanel" hidden={activeTab !== 0}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item>
                    {currentReport.load_time && (
                      <Chip
                        label={
                          <>
                            Load Time: <b> {currentReport.load_time}ms</b>
                          </>
                        }
                        color="info"
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <Chip
                      label={
                        <>
                          Tags:
                          <b>{currentReport.number_of_tags || 0}</b>
                        </>
                      }
                      color="info"
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      label={
                        <>
                          JS Objects:{" "}
                          <b>{currentReport.number_of_js_objects || 0}</b>
                        </>
                      }
                      color="info"
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      label={
                        <>
                          Cookies: <b>{currentReport.number_of_cookies || 0}</b>
                        </>
                      }
                      color="info"
                    />
                  </Grid>
                  <Grid item>
                    <Chip
                      label={
                        <>
                          Errors: <b>{currentReport.number_of_errors || 0}</b>
                        </>
                      }
                      color="error"
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: 2,
                    justifyContent: "space-between",
                  }}
                >
                  {errorsList.length > 0 && (
                    <Box>
                      <Typography variant="body1">
                        {" "}
                        Errors & Warnings:{" "}
                        {errorsList.length > 0
                          ? currentReport.errors_list.length
                          : "N/A"}
                      </Typography>

                      <List>
                        {errorsList.map((item, index) => (
                          <ListItem key={index}>
                            <ListItemIcon>{item.type}</ListItemIcon>
                            <Typography variant="subtitle2">
                              {item.error_text}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      flexDirection: "column",
                    }}
                  >
                    {currentReport.screenshot && (
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={handleOpenLargeImg}
                      >
                        <img
                          src={currentReport.screenshot}
                          className="w-full aspec-video object-cover h-[200px]"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </CardContent>

              <MaterialReactTable table={resourcesTable} />
            </Card>
          </Box>
          <Box role="tabpanel" hidden={activeTab !== 1}>
            {tags.length > 0 && (
              <Card>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: indigo[500] }}>
                      <StyleSharpIcon />
                    </Avatar>
                  }
                  title={<Typography>Tags & Parameters</Typography>}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: "1.25rem",
                    }}
                  >
                    <Typography className="text-light-grey">
                      Technology
                    </Typography>
                    <Typography className="text-light-grey">
                      Avg. Load Time
                    </Typography>
                    <Typography className="text-light-grey">
                      Number of Instances
                    </Typography>
                  </Box>

                  {Array.isArray(currentReport.tags) && (
                    <div className="overflow-y-auto">
                      {tags.map((item) => (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <AccordianHeader data={item} />
                          </AccordionSummary>
                          <AccordionDetails>
                            {generateTable(item)}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </div>
                  )}
                </CardContent>
              </Card>
            )}
          </Box>
          <Box role="tabpanel" hidden={activeTab !== 2}>
            {jsObject.length > 0 && (
              <Card>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: blue[700] }}>
                      <DataObjectSharpIcon />
                    </Avatar>
                  }
                  title={<Typography>JS Objects</Typography>}
                  action={
                    <TextField
                      placeholder="Search Data Object"
                      size="small"
                      variant="standard"
                      type="text"
                      onChange={(event) => {
                        setJsObjectsFilter(event.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                />
                <CardContent>
                  <div className="flex mt-4">
                    <div className=" border-r border-r-grey-800 w-[300px] pr-2">
                      {jsObject.map((item, index) => (
                        <h3
                          onClick={() => {
                            setSelectedJsObject(index);
                          }}
                          className={`${
                            index === selectedJsObject
                              ? "bg-gradient text-white"
                              : "bg-gradient1 text-black"
                          }  px-2 py-2  cursor-pointer`}
                        >
                          {item.JS_object_name}
                        </h3>
                      ))}
                    </div>
                    <div className="flex-1">
                      <ReactJson
                        src={getSelectedJsObject(jsObject, selectedJsObject)}
                      />
                    </div>
                  </div>
                </CardContent>
              </Card>
            )}
          </Box>
          <Box role="tabpanel" hidden={activeTab !== 3}>
            {cookies.length > 0 && (
              <Card className="mt-4">
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: brown[400] }}>
                      <CookieSharpIcon />
                    </Avatar>
                  }
                  title={<Typography>Cookies</Typography>}
                  subheader={
                    <Typography variant="caption">
                      {currentReport.number_of_cookies || "N/A"}
                    </Typography>
                  }
                />
                <CardContent>
                  {cookies.map((item, i) => (
                    <div
                      key={i}
                      className="flex gap-2 mb-1 mr-3 border-b items-center py-3"
                    >
                      <div className="w-[250px] h-[35px] text-sm text-semibold pl-2">
                        {item.cookie_name}
                      </div>
                      <div className=" flex-1 h-[35px] text-sm text-light-grey">
                        {item.cookie_value}
                      </div>
                    </div>
                  ))}
                </CardContent>
              </Card>
            )}
          </Box>
        </>
      )}
    </div>
  );
};
export default ReportCommand;

const AccordianHeader = (data) => {
  const tag = data.data;
  return (
    <div className="flex justify-between items-center flex-1">
      <div className="flex gap-3 items-center">
        <img
          src={tag.technologyIcon || defaultTag}
          alt="fallback-image.jpg"
          class="w-10"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = defaultTag;
          }}
        />
        <h1 className="text-sm text-light-grey w-0 whitespace-nowrap">
          {" "}
          {tag.technologyName}{" "}
        </h1>
      </div>
      <h1 className="text-sm text-light-grey"> {tag.avgLoadTime} </h1>
      <h1 className="text-sm text-light-grey"> {tag.numberOfInstances} </h1>
    </div>
  );
};
const generateTable = (data) => {
  if (data === undefined) return;
  const table = data;

  let columnsShalow = [];
  let columns = [];
  //console.log({table});
  table.instanceData.forEach((element, index) => {
    // console.log({element});
    if (Object.values(element).length === 0) {
      table.instanceData.pop(index);
    }

    Object.keys(element).forEach((element) => {
      if (!columnsShalow.includes(element)) {
        columnsShalow.push(element);
        columns.push({
          title: element,
          dataIndex: element,
          field: element,
          key: element + Math.random(),
        });
      }
    });
  });
  return (
    <MaterialTable
      title=""
      columns={columns}
      data={table.instanceData}
      options={{
        search: true,
        filtering: true,
      }}
    />
  );
};
