import "gantt-task-react/dist/index.css";

import { useEffect, useMemo, useState } from "react";

import {
  Avatar,
  Box,
  CardContent,
  CardHeader,
  Paper,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import CustomAccordion from "../../layout/components/accordion/accordion";
import defaultTag from "../../assets/default_tag.png";
import LocalOfferSharpIcon from "@mui/icons-material/LocalOfferSharp";
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToolbarInternalButtons,
} from "material-react-table";
import { green, indigo } from "@mui/material/colors";
import StyleSharpIcon from "@mui/icons-material/StyleSharp";

const AccordianHeader = ({ data }) => {
  return (
    <div className="flex items-center justify-between gap-5 flex-1">
      <h1 className=" w-[60%]   p-1 text-sm"> {data?.error}</h1>
      <h1 className=" h-[30px]   p-1 text-sm">
        {" "}
        Error occurance: {data?.occurence}
      </h1>
    </div>
  );
};

const AccordianBody = ({ data }) => {
  return (
    <div className="flex flex-wrap px-1 justify-center">
      {data?.URLs &&
        data?.URLs.map((item) => (
          <h1 className=" w-full p-1  text-sm border border-b">{item}</h1>
        ))}
    </div>
  );
};

export default function HomePageTag({ tagSummary }) {
  const [accordion, setAccordion] = useState([]);

  useEffect(() => {
    if (tagSummary?.errors_list?.length) {
      let _accordion = tagSummary?.errors_list?.map((item) => ({
        ...item,
        open: false,
      }));

      setAccordion(_accordion ?? []);
    }
  }, [tagSummary]);

  const tasks = useMemo(() => {
    return tagSummary?.homepage_tags_waterfall ?? [];
  }, [tagSummary]);

  const waterFallTiming = useMemo(() => {
    const chartStart = Math.min(...tasks.map(({ start_time }) => start_time));
    const chartEnd = Math.max(...tasks.map(({ end_time }) => end_time));
    return { duration: chartEnd - chartStart, chartStart, chartEnd };
  }, [tagSummary]);

  const tagsColumns = useMemo(() => [
    {
      header: "Technology",
      accessorKey: "regex_name",
      grow: 1,
      Cell: ({ renderedCellValue, row }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <img
            src={row.original.regex_icon ?? defaultTag}
            alt=""
            className="w-6"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = defaultTag;
            }}
          />
          <span>{renderedCellValue}</span>
        </Box>
      ),
    },
    {
      header: "Method",
      accessorKey: "method",
      size: 100,
      grow: 1,
    },
    {
      header: "Timing",
      accessorKey: "start_time",
      size: 300,
      grow: 1,
      Cell: ({ row }) => {
        const duration =
          ((row.original.end_time - row.original.start_time) * 100) /
          waterFallTiming.duration;
        const offset =
          ((row.original.start_time - waterFallTiming.chartStart) * 100) /
          waterFallTiming.duration;
        return (
          <Box sx={{ width: "100%", borderRightWidth: 1, borderLeftWidth: 1 }}>
            <div
              className="bg-green h-2 rounded-lg"
              style={{
                width: `calc(22px + ${duration}%)`,
                marginLeft: `calc(${offset}%)`,
              }}
            ></div>
          </Box>
        );
      },
      Footer: () => (
        <>
          <div className="w-full flex justify-between">
            <div className="">0ms</div>
            <div>
              {tasks.reduce(
                (agg, row) => agg + row.end_time - row.start_time,
                0
              )}
              ms
            </div>
          </div>
        </>
      ),
    },
    {
      header: "Status code",
      accessorKey: "status_code",
      size: 100,
      grow: 1,
      Cell: ({ renderedCellValue, row }) => (
        <Chip
          label={renderedCellValue}
          size="small"
          sx={{ bgcolor: green[500], color: "#fff" }}
        />
      ),
    },
    {
      header: "Duration",
      accessorKey: "end_time",
      size: 100,
      grow: 1,
      Cell: ({ renderedCellValue, row }) =>
        `${row.original.end_time - row.original.start_time}ms`,
    },
  ]);

  const uniqueTagsColumns = [
    {
      header: "Technology name",
      accessorKey: "technology_name",
      Cell: ({ renderedCellValue, row }) => (
        <div className="flex gap-4 items-center">
          <img
            src={row.original.technology_icon || defaultTag}
            alt="fallback-image.jpg"
            className="w-6"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = defaultTag;
            }}
          />
          <span>{renderedCellValue}</span>
        </div>
      ),
    },
    {
      header: "Number of pages found",
      accessorKey: "pages",
      Cell: ({ renderedCellValue }) => (
        <>
          {renderedCellValue} / {tagSummary?.total_pages}
        </>
      ),
    },
    {
      header: "Avg. Load time",
      accessorKey: "loadTime",
    },
    {
      header: "Min. Load Time",
      accessorKey: "loadTimeMin",
    },
    {
      header: "Max. Load Time",
      accessorKey: "loadTimeMax",
    },
  ];

  const data = useMemo(() => {
    return (
      tagSummary?.unique_tags?.map((item) => ({
        technology_name: item.technology_name,
        technology_icon: item.technology_icon,
        loadTime: item.avg_load_time + "s",
        pages: item.number_of_pages,
        loadTimeMin: item.min_load_time + "s",
        loadTimeMax: item.max_load_time + "s",
      })) ?? []
    );
  }, [tagSummary]);

  const handleAccordion = (index) => {
    let _accordion = [...accordion];
    _accordion = _accordion.map((item, i) => ({
      ...item,
      open: i === index ? item.open : false,
    }));
    _accordion[index].open = !_accordion[index].open;
    setAccordion([..._accordion]);
  };

  const tagsTable = useMaterialReactTable({
    columns: tagsColumns,
    data: tasks,
    initialState: { density: "compact" },
    enableColumnOrdering: true,
    enableFullScreenToggle: false,
    enableColumnResizing: true,
    renderTopToolbar: ({ table }) => (
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: green[400] }}>
            <StyleSharpIcon />
          </Avatar>
        }
        title={
          <Typography variant="h6">Loaded Tags - Waterfall View</Typography>
        }
        action={
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToolbarInternalButtons table={table} />
          </Box>
        }
      />
    ),
  });

  const uniqueTagsTable = useMaterialReactTable({
    columns: uniqueTagsColumns,
    data: data,
    initialState: { density: "compact" },
    enableColumnOrdering: true,
    enableFullScreenToggle: false,
    renderTopToolbar: ({ table }) => (
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: indigo[600] }}>
            <LocalOfferSharpIcon />
          </Avatar>
        }
        title={
          <Typography variant="h6">
            Unique Tags ({tagSummary?.unique_tags?.length})
          </Typography>
        }
        action={
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToolbarInternalButtons table={table} />
          </Box>
        }
      />
    ),
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pb: 2 }}>
      {tasks.length > 0 && (
        <>
          <MaterialReactTable table={tagsTable} />
        </>
      )}
      <MaterialReactTable table={uniqueTagsTable} />
      <Paper>
        <CardHeader
          title={<Typography variant="h6"> Errors & Warnings</Typography>}
          action={
            <Typography variant="subtitle1">
              Total Issues : {tagSummary?.total_errors | "N/A"}
            </Typography>
          }
        />
        <CardContent>
          <div className="max-h-[300px] overflow-y-auto">
            {accordion.map((item, index) => (
              <CustomAccordion
                open={item.open}
                handleOpen={() => handleAccordion(index)}
                Header={<AccordianHeader index={index} data={item} />}
                Body={<AccordianBody data={item} />}
              />
            ))}
          </div>
        </CardContent>
      </Paper>
    </Box>
  );
}
