// import * as jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
import Navigation from "../components/Navigation";
import { env } from "../config";
const cookies = new Cookies();

const getAuth = () => {
  return cookies.get(env.TOKEN_NAME);
  // return (localStorage.getItem(TOKEN_NAME)) ? true : false;
};

const logout = () => {
  const cookies = new Cookies();
  localStorage.clear();
  cookies.remove(env.TOKEN_NAME, { path: "/" });
  Navigation("/auth");
};

// const getTokenExpirationDate = (token) => {
//   const decoded = jwt_decode(token);
//   if (decoded.exp === undefined) return null;

//   const date = new Date(0);
//   date.setUTCSeconds(decoded.exp);s
//   return date;
// };

// const isTokenExpired = (token) => {
//   if (!token) token = this.getToken(TOKEN_NAME);
//   if (!token) return true;

//   const date = this.getTokenExpirationDate(token);
//   // console.log("date : ",date);
//   if (date === undefined) return true;
//   return !(date.valueOf() > new Date().valueOf());
// };

const checkToken = () => {
  try {
    let token = getAuth();

    if (!token) {
      return false;
    }
    // let userData = getToken(TOKEN_NAME);
    // if (!userData || userData === undefined) {
    //   return false;
    // }
    // userData = JSON.parse(userData);
    // let date = getTokenExpirationDate(token);
    // let current = new Date();

    // if (date.valueOf() < current.valueOf()) {
    //   console.log("expired");
    //   logout();
    //   return false;
    // }

    // if (current.valueOf() >= date.valueOf() - 24 * 60 * 60000) {
    //   console.log("Time to refresh token");
    //   // refreshToken(token, userData);
    //   return false;
    // }
    else {
      console.log("Token is valid for more than 2 min");
      return true;
    }
  } catch (error) {
    console.log("CHECK TOKEN ERROR : ", error);
    return false;
  }
};

const checkRoute = (userType) => {
  try {
    let token = getAuth();
    // let token = getToken(TOKEN_NAME);
    if (!token) {
      return false;
    }
    // let userData = getToken('user_data');
    // if (!userData || userData === undefined) { return false; }
    // userData = JSON.parse(userData);
    // const decoded = jwt_decode(token);
    // if (userType === "admin" && !decoded.is_admin) {
    //   return false;
    // } else if (userType === "normal" && decoded.is_admin) {
    //   return false;
    // }
    // let date = getTokenExpirationDate(token);
    // let current = new Date();

    // if (date.valueOf() < current.valueOf()) {
    //   console.log("expired");
    //   return false;
    // }
    return true;
  } catch (error) {
    console.log("CHECK TOKEN ERROR : ", error);
    return false;
  }
};

const getCsrfToken = async () => {
  // const data = await API.get(`${env.REACT_APP_API_HOST}csrf/`);
  let csrfToken;
  const response = await fetch(`${env.REACT_APP_API_HOST}csrf/`, {
    credentials: "include",
  });
  const resData = await response.json();
  return resData;
};

const checkLogin = async () => {
  const response = await fetch(`${env.REACT_APP_API_HOST}login/`, {
    method: "GET",
    credentials: "include",
  });
  const resData = await response.json();
  return await resData.isAuthenticated;
};

const authServices = {
  getAuth,
  logout,
  checkToken,
  checkRoute,
  getCsrfToken,
  checkLogin,
};
export default authServices;
