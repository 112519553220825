import { useEffect, useState } from "react";
import { env } from "../../config";
import { DataGrid } from "@mui/x-data-grid";
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { Avatar, Card, CardHeader, Typography } from "@mui/material";
import ReceiptLongSharpIcon from "@mui/icons-material/ReceiptLongSharp";
import { green } from "@mui/material/colors";

export default function CashoutHistory() {
  const [history, setHistory] = useState([]);
  const getHistory = async () => {
    const res = await fetch(
      `${env.REACT_APP_API_HOST}referral/cashout-history/`,
      { method: "get", credentials: "include" }
    );
    const resData = await res.json();
    setHistory(() => resData.map((e, i) => ({ ...e, id: i })));
  };
  useEffect(() => {
    getHistory();
  }, []);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      valueGetter: (params) => {
        const d = new Date(params.row.date);
        return d
          ? `${d.toLocaleDateString()} @ ${d.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}`
          : "";
      },
    },
    {
      field: "processed",
      headerName: "Processed",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.processed ? (
            <CheckCircleOutlineSharpIcon color="success" />
          ) : (
            <HighlightOffSharpIcon color="error" />
          )}
        </>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      renderCell: (params) => (
        <>
          <Typography variant="overline">${params.row.amount}</Typography>
        </>
      ),
    },
  ];

  return (
    <>
      {history.length > 0 && (
        <Card>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: green[400] }}>
                <ReceiptLongSharpIcon />
              </Avatar>
            }
            title="Cashout History"
          />
          <DataGrid
            sx={{
              borderRadius: 0,
              "& .MuiDataGrid-menuIcon": {
                width: "auto",
                visibility: "visible",
              },
            }}
            columns={columns}
            autoHeight={true}
            rows={history}
            initialState={{
              rows: [],
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            hideFooterSelectedRowCount={true}
            rowSelection={false}
          />
        </Card>
      )}
    </>
  );
}
