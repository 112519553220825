import { useEffect, useMemo, useState, forwardRef } from "react";
import { useSearchParams } from "react-router-dom";
import Card from "../../../components/card";
import { env } from "../../../config";
import Loader from "../../../components/loader";
import CustomAccordion from "../../../layout/components/accordion/accordion";
import Table from "rc-table";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import MaterialTable from '@material-table/core';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Cookies = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [cookies, setCookies] = useState([]);

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [openSearchDialog, setOpenSearchDialog] = useState(false);

  const handleSearchDialogClickOpen = () => {
    console.log({allCookies});
    setOpenSearchDialog(true);
  };

  const handleSearchDialogClose = () => {
    setOpenSearchDialog(false);
  };
  
  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [id]);

  const getData = async (id) => {
    setIsLoading(true);
    await fetch(`${env.REACT_APP_API_HOST}get_report_cookies/${id}`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        setIsLoading(false);
        if (response.Success === "True") {
          setCookies(response.data)
          //setCookies({...response.data.pages[0], ...{reportType: 'pages'}})
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      });
  };

  const cookieList = useMemo(() => {
    return (
      cookies.map((item, index) => ({
        ...item,
        ...{open: false}
      })) ?? []
    );
  }, [cookies]);

  const allCookies = useMemo(() => {
    /*return (
      cookies.map((item, index) => (
        item.cookie_occurences.map((item, index) => (item))
      )) ?? []
    );*/
     let result = [];
        cookies.map((cookie) => {
        cookie.cookie_occurences.map((occurence) => {
           result.push({ cookie_name: cookie.cookie_name, ...occurence });
         });
       });
     return result;
  }, [cookies])

  const [accordion, setAccordion] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorE, setAnchorE] = useState(null);

  const handleAccordion = (index) => {
    let _accordion = [...cookieList];
    cookieList[index].open = !cookieList[index].open
    setAccordion([..._accordion]);
  };

  return (
    <div>
      {isLoading && <Loader />}
      
      {/* <ReportsNav /> */}
      {!isLoading && <Card>
        <div className="flex justify-between">
          <h1 className=" text-black p-2  font-semibold">
            Cookies list
          </h1>
          <div className="ml-4 mr-auto">
            <Button variant="outlined" onClick={handleSearchDialogClickOpen}>
              Search all
            </Button>
            <Dialog
              fullScreen={true}
              open={openSearchDialog}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleSearchDialogClose}
              aria-describedby="alert-dialog-slide-description"
            >
              
              <DialogTitle>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleSearchDialogClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                {"Search Cookies:"}
              </DialogTitle>
              <DialogContent>
                <MaterialTable
                  title=""
                  columns={filteringColumns}
                  data={allCookies}        
                  options={{
                    filtering: true
                  }}
                />
              </DialogContent>
            </Dialog>
          </div>
          <h1 className="p-2 text-light-grey font-semibold">
            Count: {cookies.length}
          </h1>
        </div>
        <div className="mt-3">
          {cookieList.map((item, index) => (

            
          <Accordion key={`cookies-accordion-${index}`} >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <AccordianHeader data={item} />
            </AccordionSummary>
            <AccordionDetails>
              <AccordianBody data={item} />
            </AccordionDetails>
          </Accordion>
          ))}
        </div>
      </Card>}
    </div>
  );
};
export default Cookies;



const filteringColumns = [
  {
    title: "Cookie Name",
    field: "cookie_name", //defaultGroupOrder: 0 
  },
  {
    title: "Cookie Source",
    field: "cookie_source",
  },
  {
    title: "Cookie Value",
    field: "cookie_value",
  },
  {
    title: "Path",
    field: "path",
  },
  {
    title: "Expire Date",
    field: "expire_date",
  },
  {
    title: "Domain",
    field: "domain",
  },
  {
    title: "Size",
    field: "size",
  },
  {
    title: "Http Only",
    field: "http_only",
    lookup: {false: 'false', true: 'true'}
  },
  {
    title: "Secure",
    field: "secure",
  },
  {
    title: "Same Site",
    field: "same_site",
  },
];

const columns = [
  {
    title: "Cookie Source",
    dataIndex: "cookie_source",
    field: "cookie_source",
    key: "cookie_source",
    width: 300,
    align: "left",
  },
  {
    title: "Cookie Value",
    dataIndex: "cookie_value",
    field: "cookie_value",
    key: "cookie_value",
    width: 100,
    align: "left",
  },
  {
    title: "Path",
    dataIndex: "path",
    field: "path",
    key: "path",
    width: 100,
    align: "left",
  },
  {
    title: "Expire Date",
    dataIndex: "expire_date",
    field: "expire_date",
    key: "expire_date",
    width: 100,
    align: "left",
  },
  {
    title: "Domain",
    dataIndex: "domain",
    field: "domain",
    key: "domain",
    width: 100,
    align: "left",
  },
  {
    title: "Size",
    dataIndex: "size",
    field: "size",
    key: "size",
    width: 100,
    align: "left",
  },
  {
    title: "Http Only",
    dataIndex: "http_only",
    field: "http_only",
    key: "http_only",
    width: 100,
    align: "left",
  },
  {
    title: "Secure",
    dataIndex: "secure",
    field: "secure",
    key: "secure",
    width: 100,
    align: "left",
  },
  {
    title: "Same Site",
    dataIndex: "same_site",
    field: "same_site",
    key: "same_site",
    width: 100,
    align: "left",
  },
];


const AccordianHeader = ({ data }) => {
  return (
    <div className="flex items-center gap-5 flex-1 mr-3">
      <h1 className="w-[300px] p-1 text-sm">{data.cookie_name}</h1>
      <h1 className="w-full p-1 text-sm">{data.cookie_value}</h1>
    </div>
  );
};

const parseCookieOccurences = (cookies) => {
  return cookies.map((cookie) => ({
    cookie_source: cookie.cookie_source,
    cookie_value: cookie.cookie_value,
    path: cookie.path,
    expire_date: cookie.expire_date,
    domain: cookie.domain,
    size: cookie.size+'kB',
    http_only: cookie.http_only +'',
    secure: cookie.secure +'',
    same_site: cookie.same_site
}))
}

const AccordianBody = ({data}) => {
  return (
    <div className="">
      {/* <div className="max-h-[500px] overflow-y-auto commands-pages-tag-report" >
        <Table columns={columns} data={parseCookieOccurences(data.cookie_occurences)} />
      </div> */}
      <MaterialTable
        title=""
        columns={columns}
        data={parseCookieOccurences(data.cookie_occurences)}        
        options={{
          filtering: true
        }}
      />
    </div>
  );
};
