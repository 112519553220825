import { useNavigate } from "react-router-dom";

function useNavigation({ navPage }) {
  let navigate = useNavigate();
  const handleNavigation = () => {
    navigate(navPage);
  };
  return handleNavigation();
}

export default useNavigation;
