import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import DeleteSweepSharpIcon from "@mui/icons-material/DeleteSweepSharp";
import { env } from "../../config";
import { red } from "@mui/material/colors";

function RemoveUser({ name, username, setRemoveUser }) {
  const [activeUser, setActiveUser] = useState(username);
  const [loading, setLoading] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);

  const handleRemoveUser = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${env.REACT_APP_API_HOST}organization-users/`, {
        method: "DELETE",
        credentials: "include",
        body: JSON.stringify({ username }),
      });
      const resData = await res.json();
      if (!res.ok) throw new Error(resData.error);
      setTimeout(() => {
        setRemoveUser("reload");
      }, 3000);
      setStateMessage({ status: "primary", message: resData.message });
    } catch (resError) {
      setStateMessage({ status: "error", message: resError.message });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setActiveUser(null);
    setRemoveUser(null);
  };
  return (
    <>
      <Dialog open={activeUser ? true : false} onClose={handleClose}>
        <DialogTitle>{`Removing ${name}`}</DialogTitle>
        <DialogContent>
          {stateMessage && (
            <Typography color={stateMessage.status} gutterBottom>
              {stateMessage.message}
            </Typography>
          )}
          <DialogContentText>
            {`You are about to remove ${name} from your organization. Do you want to proceed?`}
          </DialogContentText>
        </DialogContent>
        {loading && <LinearProgress />}
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="error"
            startIcon={<DeleteSweepSharpIcon />}
            autoFocus
            variant="contained"
            onClick={handleRemoveUser}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default function OrganizationUsers() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [removeUser, setRemoveUser] = useState(null);

  const getUsers = async () => {
    try {
      const res = await fetch(`${env.REACT_APP_API_HOST}organization-users/`, {
        method: "GET",
        credentials: "include",
      });
      const resData = await res.json();
      if (!res.ok) throw new Error(resData.error);
      setUsers([...resData]);
    } catch (resError) {
    } finally {
      setLoading(false);
    }
  };

  const handleSetRemoveUser = (username) => {
    if (username === "reload") {
      getUsers();
      setRemoveUser(null);
    } else {
      setRemoveUser(username);
    }
  };

  useEffect(() => {
    setLoading(true);
    getUsers();
  }, []);

  return (
    <>
      {loading ? (
        <Box p={3}>
          <LinearProgress />
        </Box>
      ) : (
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
          }}
          subheader={
            <ListSubheader>
              <Typography variant="h6">Users</Typography>
            </ListSubheader>
          }
        >
          {users.map((el, i) => (
            <React.Fragment key={i}>
              {removeUser === el.username && (
                <RemoveUser
                  name={`${el.first_name} ${el.last_name}`}
                  username={removeUser}
                  setRemoveUser={handleSetRemoveUser}
                />
              )}
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    sx={{ ":hover": { color: red[400] } }}
                    onClick={() => handleSetRemoveUser(el.username)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar src={el.profile_picture}></Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${el.first_name} ${el.last_name}`}
                  secondary={el.email}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      )}
    </>
  );
}
