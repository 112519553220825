import "./styleSheet.css";

import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { env } from "../../config.js";
import { useNavigate } from "react-router-dom";

function TopBar({
  sideBarvisible,
  notifNumber,
  shakeNotif,
  setShakeNotif,
  setNotifNumber,
  get_notifications,
  notifs,
}) {
  let navigate = useNavigate();
  const [crawlScanActive, setCrawlScanActive] = useState("");
  const [scenarioScanActive, setScenarioScanActive] = useState("");
  const [scan, setScan] = useState(true);
  const [schedule, setSchedule] = useState(false);
  const [report, setReport] = useState(false);
  const [userIcon, setUserIcon] = useState("");
  const [userInfo, setUserInfo] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const [check_clicks, setCheck_clicks] = useState(false);
  const handleShowNotif = () => {
    setShowNotif(!showNotif);
    if (shakeNotif) {
      setShakeNotif(!shakeNotif);
      setNotifNumber(null);
    }
  };

  useEffect(() => {
    if (!setCheck_clicks) return;
    const notificarion_containerID = document.getElementById(
      "notificarion_containerID"
    );
    if (showNotif) {
      document.addEventListener("click", (event) => {
        let outsideClick = event?.target?.parentElement?.id;
        if (!outsideClick) {
          outsideClick = "";
        }
        let click2 = event?.target?.parentElement?.className;
        if (!click2) {
          click2 = "";
        }
        if (click2 === "notif_elem" || click2 === "notif_elements") {
          setShowNotif(true);
          return;
        }
        if (outsideClick !== "notif_icon") setShowNotif(false);
      });
    }
    return document.removeEventListener("click", () => {});
  }, [showNotif]);

  const handleCheckUser = async () => {
    await fetch(`${env.REACT_APP_API_HOST}checkUser/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.user == 1) {
          setIsAdmin(true);
        }
        if (response.isAuthenticated === "False") handleRoute("/login");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleRoute = (val) => {
    handleQueryString();
    navigate(val);
  };

  useEffect(() => {
    let subscription = true;
    if (subscription) {
      get_notifications(handleRoute);
    }
    return () => (subscription = false);
  }, []);
  useEffect(() => {
    // handleCheckUser();
    handleQueryString();
  }, [window.location.href]);
  const handleQueryString = () => {
    setCrawlScanActive("");
    setScenarioScanActive("");
    setUserIcon("");
    setScan(false);
    setSchedule(false);
    setReport(false);
    setUserInfo(false);
    const queryString = window.location.href;
    const active = "topBar_button_selected";
    if (queryString.includes("scenario-scan")) {
      setScenarioScanActive(active);
      setScan(true);
    } else if (queryString.includes("crawl-scan")) {
      setCrawlScanActive(active);
      setScan(true);
    } else if (queryString.includes("report")) {
      setReport(true);
    } else if (queryString.includes("schedule")) {
      setSchedule(true);
    } else if (queryString.includes("/user-info")) {
      setUserIcon(active);
      setUserInfo(true);
    }
  };

  const handleLogout = async () => {
    await fetch(`${env.REACT_APP_API_HOST}logout/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        handleRoute("/login");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div
      className="topBar_container"
      style={{
        width: sideBarvisible ? "80%" : "90%",
        marginLeft: sideBarvisible ? "200px" : "100px",
      }}
    >
      <div className="topBar_left">
        {scan ? (
          <>
            {" "}
            <div
              onClick={() => handleRoute("/scan/scenario-scan")}
              className={`topBar_Item ${scenarioScanActive}`}
            >
              SCENARIO SCAN
            </div>
            <div
              onClick={() => handleRoute("/scan/crawl-scan")}
              className={`topBar_Item ${crawlScanActive}`}
            >
              CRAWL SCAN
            </div>
            {isAdmin ? (
              <div
                onClick={() => handleRoute("/admin-dashboard/account")}
                className={`topBar_Item`}
              >
                Admin Dashboard
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        {schedule ? (
          <div className="topBar_Item topBar_button_selected">SCHEDULE</div>
        ) : (
          <></>
        )}
        {report ? (
          <div className="topBar_Item topBar_button_selected">REPORTS</div>
        ) : (
          <></>
        )}
        {userInfo ? (
          <div className="topBar_Item topBar_button_selected">
            USER INFORMATION
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="topBar_right">
        <div style={{ position: "relative" }}>
          <div style={{ position: "relative" }}>
            <FontAwesomeIcon
              icon="fa-solid fa-bell"
              className="topBar_Item"
              id="notif_icon"
              size="2x"
              onClick={handleShowNotif}
              shake={shakeNotif}
            />
            {Number(notifNumber) !== 0 ? (
              <p className="number_notifs">{notifNumber}</p>
            ) : (
              <></>
            )}
          </div>
          {showNotif ? (
            <div
              id="notificarion_containerID"
              className="notificarion_container"
            >
              <FontAwesomeIcon
                icon="fa-solid fa-xmark"
                size="1x"
                className="notif_closeIcon"
                onClick={handleShowNotif}
              />
              <div className="notif_elements" style={{ marginTop: "10px" }}>
                {notifs?.map((elem, index) => (
                  <div className="notif_elem" key={index}>
                    <p className="notif_data">{elem.notification_content}</p>
                    <p className="notif_elemScan_date">{elem.creation_date}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <FontAwesomeIcon
          icon="fa-regular fa-user"
          className={`topBar_Item ${userIcon}`}
          size="2x"
          onClick={() => {
            handleRoute("/user-info");
          }}
        />
        <FontAwesomeIcon
          icon="fa-solid fa-arrow-right-from-bracket"
          className="topBar_Item"
          size="2x"
          onClick={() => handleLogout()}
        />
      </div>
    </div>
  );
}

export default TopBar;
