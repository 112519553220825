import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";

import GroupAddSharpIcon from "@mui/icons-material/GroupAddSharp";
import SendSharpIcon from "@mui/icons-material/SendSharp";
import { env } from "../../config";
import { green } from "@mui/material/colors";
import { useState } from "react";

export default function InviteUser() {
  const [loading, setLoading] = useState(false);
  const [inviteState, setInviteState] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setInviteState(null);
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    try {
      const res = await fetch(`${env.REACT_APP_API_HOST}invite/`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ email }),
      });
      const resData = await res.json();
      if (!res.ok) throw new Error(resData.error);
      setInviteState({ status: "primary", message: resData.message });
      event.target.reset();
    } catch (resError) {
      setInviteState({ status: "error", message: resError.message });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Card elevation={0}>
        <CardHeader
          title={
            <Typography variant="body1">
              Invite Users to your organisation
            </Typography>
          }
        ></CardHeader>
        <CardContent>
          {inviteState && (
            <Typography color={inviteState.status} variant="body2">
              {inviteState.message}
            </Typography>
          )}
          <Box component="form" noValidate={false} onSubmit={handleSubmit}>
            {loading ? (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                p={2}
              >
                <CircularProgress />
              </Box>
            ) : (
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoFocus
                InputProps={{
                  endAdornment: (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<SendSharpIcon />}
                    >
                      Send
                    </Button>
                  ),
                }}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
