import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";

import LockResetSharpIcon from "@mui/icons-material/LockResetSharp";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { env } from "../../config";
import { red } from "@mui/material/colors";
import { useState } from "react";

export default function ChangePassword({ user_email }) {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState(null);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const password = data.get("password");
    const password2 = data.get("password2");

    if (password !== password2) {
      setLoading(false);
      setPasswordMessage({
        status: "error",
        message: "Passwords don't match!",
      });
      return;
    }
    try {
      const res = await fetch(
        `${env.REACT_APP_API_HOST}change_user_password_email/`,
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            user_email,
            user_password: password,
          }),
        }
      );
      const resData = await res.json();
      if (!res.ok) throw new Error("error");
      setPasswordMessage({ status: "success", message: "Password Updated!" });
    } catch (resError) {
      setPasswordMessage({
        status: "error",
        message: "Error updating password",
      });
    } finally {
      setLoading(false);
      setTimeout(() => setPasswordMessage(null), 5000);
    }
  };
  return (
    <>
      <Card component="form" noValidate={false} onSubmit={handleSubmit}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[600] }}>
              <LockResetSharpIcon />
            </Avatar>
          }
          title={<Typography variant="body1">Change Password</Typography>}
          action={
            <>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveSharpIcon />}
                >
                  Update Password
                </Button>
              )}
            </>
          }
        />
        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {passwordMessage && (
            <Alert severity={passwordMessage.status} sx={{ my: 1 }}>
              {passwordMessage.message}
            </Alert>
          )}
          <TextField
            required
            fullWidth
            name="user_email"
            label="Email"
            defaultValue={user_email}
          />
          <FormControl required fullWidth id="password" variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              name="password"
              autoComplete="new-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <FormControl required fullWidth id="password2" variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password2">
              Confirm Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password2"
              name="password2"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
            />
          </FormControl>
        </CardContent>
      </Card>
    </>
  );
}
