import { env } from "../config";

export const removeReportsBULK = async (reports_ids) => {
  await fetch(`${env.REACT_APP_API_HOST}deleteReportBULK/`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(reports_ids),
  })
    .then((res) => res.json())
    .then((response) => {})
    .catch(function (error) {
      console.log(error);
    });
};

export const deleteReport = async (reportId) => {
  await fetch(`${env.REACT_APP_API_HOST}deleteReport/${reportId}`, {
    method: "GET",
    credentials: "include",
  })
    .then((res) => res.json())
    .then((response) => {
      console.log(response);
    });
};

export const cancelReport = async (reportId) => {
  await fetch(`${env.REACT_APP_API_HOST}cancel_report/${reportId}`, {
    method: "GET",
    credentials: "include",
  })
    .then((res) => res.json())
    .then((response) => {})
    .catch(function (error) {
      console.log(error);
    });
};
