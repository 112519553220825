import "./validations.css";

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { green, grey, orange, red } from "@mui/material/colors";

import AddSharpIcon from "@mui/icons-material/AddSharp";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import MuiAlert from "@mui/material/Alert";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import Snackbar from "@mui/material/Snackbar";
import ValidationStep from "./ValidationStep.jsx";
import { env } from "../../config.js";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Validations = () => {
  let navigate = useNavigate();
  const handleRoute = useCallback(
    (val) => {
      navigate(val);
    },
    [navigate]
  );
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [validations, setValidations] = useState([]);
  const [selectedValidationIndex, setSelectedValidationIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [technology, setTechnology] = useState([]);
  const [errNewValidation, setErrNewValidation] = useState(false);
  const [errNewValidationMessage, setErrNewValidationMessage] = useState(false);
  const [filter, setFilter] = useState("");

  const [indexValidationToDelete, setIndexValidationToDelete] = useState();
  const [closeValidationDeleteConf, setCloseValidationDeleteConf] =
    useState(false);

  const [loading, setLoading] = useState(false);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  const [validationName, setValidationName] = useState("");
  const handleCloseModal = () => setOpenModal(false);
  const handleCloseValidationDeleteConf = () =>
    setCloseValidationDeleteConf(false);
  const handleOpenValidationDeleteConf = (index) => {
    setIndexValidationToDelete(index);
    setCloseValidationDeleteConf(true);
  };

  const getValidations = async () => {
    await fetch(`${env.REACT_APP_API_HOST}get_validations/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        setValidations(response.data);
        if (response.isAuthenticated === "False") {
          handleRoute("/login");
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //!! get technology ["Google Analytics 1","Google Analytics 2"]
  const getTechnology = async () => {
    await fetch(`${env.REACT_APP_API_HOST}get_techologies`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        setTechnology(response.data);
        if (response.isAuthenticated === "False") {
          handleRoute("/login");
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getTechnology();
    getValidations();
  }, []);

  const handleValidationSelecting = (index) => {
    setSelectedValidationIndex(index);
  };
  const handleAddValidation = async (e) => {
    e.preventDefault();
    if (validationName.trim().length === 0) {
      setErrNewValidationMessage("input can't be empty!");
      setErrNewValidation(true);
      return;
    }
    /*const newValidations = [...validations];
    //! change if needed for new validation object and it's data
    newValidations.push({
      validation_name: validationName,
      data: [
        {
          type: "command",
          type_specifier: "click_1",
          technology: "",
          conditions: [],
        },
      ],
    });
    setValidations(newValidations);*/
    handleCloseModal();
    await fetch(`${env.REACT_APP_API_HOST}add_validation/`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ validation_name: validationName }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Success === "True") {
          console.log("saved!");
          getValidations();
        } else console.log("something wrong happened!");
        if (response.isAuthenticated === "False") {
          handleRoute("/login");
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    setValidationName("");
  };
  const handleValidationNameChange = (e) => {
    if (errNewValidation) setErrNewValidation(false);
    setValidationName(e.target.value);
  };

  const handleAddInstructionData = () => {
    const newValidations = [...validations];
    if (!newValidations[selectedValidationIndex]["data"])
      newValidations[selectedValidationIndex]["data"] = [];
    newValidations[selectedValidationIndex].data.push({
      type: "Command",
      type_specifier: "click_1",
      technology: "",
      conditions: [],
    });
    setValidations(newValidations);
  };

  const resetSearchInput = (event) => {
    console.log(
      event.target.parentElement.parentElement.previousElementSibling
    );

    const inputElement =
      event.target.parentElement.parentElement.previousElementSibling;
    if (inputElement) {
      inputElement.value = "";
      setFilter("");
    }
  };

  const HandleValidationDeleteConf = async () => {
    await fetch(`${env.REACT_APP_API_HOST}delete_validation/`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        validation_id: validations[indexValidationToDelete].validation_id,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.Success === "True") {
          console.log("saved!");
          ////
          const newValidationList = [...validations];
          newValidationList.splice(indexValidationToDelete, 1);
          setValidations(newValidationList);
        } else console.log("something wrong happened!");
        handleCloseValidationDeleteConf();
        if (response.isAuthenticated === "False") {
          handleRoute("/login");
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleInstructionDelete = (index) => {
    const newValidations = [...validations];
    newValidations[selectedValidationIndex]["data"].splice(index, 1);
    setValidations(newValidations);
  };

  ///////////////////////////////////////////
  const [validationFilters, setValidationFilters] = useState([]);
  const [newValidationName, setNewValidationName] = useState(undefined);
  const [editValidation, setEditValidation] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editError, setEditError] = useState(null);

  const handleCloseEditValidation = () => {
    setEditValidation(false);
    setEditLoading(false);
    setEditError(null);
  };

  const getValidationFilters = async () => {
    try {
      const res = await fetch(`${env.REACT_APP_API_HOST}validations/filters/`, {
        method: "GET",
        credentials: "include",
      });
      const resData = await res.json();
      if (!res.ok) throw new Error(resData.error);
      setValidationFilters(resData);
    } catch (resError) {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const validationId = validations[selectedValidationIndex]?.validation_id;
    const data = new FormData(e.currentTarget);
    const dataObj = {};
    let count = 0;
    data.forEach((value, key) => {
      console.log(key, value);
      if (key.startsWith("instruction")) {
        const instruction = key.split(":")[0];
        const stepId = key.split(":")[1];
        dataObj[stepId] = { ...dataObj[stepId] };
        dataObj[stepId][instruction] = value;
      }
      if (key.startsWith("condition")) {
        const condition = key.split(":")[0];
        const stepId = key.split(":")[1];
        const conditionId =
          key.split(":")[2].length > 0 ? key.split(":")[2] : `new_${stepId}`;
        dataObj[stepId][conditionId] = {
          ...dataObj[stepId][conditionId],
        };
        dataObj[stepId][conditionId][condition] = value;
      }
      count++;
    });
    const sendObj = { validationId, data: dataObj };
    try {
      const res = await fetch(
        `${env.REACT_APP_API_HOST}validations/save-instructions/`,
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify(sendObj),
        }
      );
      const resData = await res.json();
      if (!res.ok) throw new Error(resData.error);
      setOpenSnackBar(true);
    } catch (resError) {
    } finally {
      setLoading(false);
    }
  };

  const handleEditValidation = async () => {
    setEditLoading(true);
    try {
      const data = {
        validationName: newValidationName,
        validationId: validations[selectedValidationIndex].validation_id ?? "",
      };

      if (data.validationName === undefined) {
        throw new Error("Name has not changed.");
      }
      const res = await fetch(`${env.REACT_APP_API_HOST}validations/rename/`, {
        method: "post",
        credentials: "include",
        body: JSON.stringify(data),
      });
      const resData = await res.json();
      if (!res.ok) throw new Error(resData.error);
      setValidations((vals) => {
        vals[selectedValidationIndex].validation_name = newValidationName;
        return vals;
      });

      handleCloseEditValidation();
      setOpenSnackBar(true);
    } catch (resError) {
      console.log(resError);
      setEditError(resError.message);
    } finally {
      setEditLoading(false);
    }
  };

  useEffect(() => {
    getValidationFilters();
  }, []);

  return (
    <>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Validation Data Saved Successfully
        </Alert>
      </Snackbar>
      <Typography variant="h5" display={"flex"} gutterBottom>
        Validations
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Button
            onClick={() => setOpenModal(true)}
            variant="contained"
            fullWidth
            size="small"
            sx={{ mb: 2 }}
            startIcon={<AddSharpIcon />}
          >
            Add Validation
          </Button>

          <Paper square sx={{ p: 1, position: "sticky", top: 1, zIndex: 99 }}>
            <TextField
              onChange={(event) => {
                setFilter(event.target.value);
              }}
              fullWidth
              size="small"
              label="Search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(event) => {
                        resetSearchInput(event);
                      }}
                    >
                      <ClearSharpIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <List>
              {validations.map((el, i) => {
                if (
                  filter.length > 0 &&
                  !el.validation_name
                    .toLowerCase()
                    .includes(filter.toLowerCase())
                )
                  return;
                return (
                  <ListItem
                    key={i}
                    onClick={() => handleValidationSelecting(i)}
                    disablePadding
                    sx={{
                      borderTop: i > 0 ? 1 : 0,
                      borderColor: grey[400],
                      bgcolor: selectedValidationIndex === i ? green[200] : "",
                    }}
                    secondaryAction={
                      <Tooltip title={`Delete ${el.validation_name}`}>
                        <IconButton
                          sx={{ ":hover": { color: red[400] } }}
                          onClick={() => handleOpenValidationDeleteConf(i)}
                        >
                          <DeleteOutlineSharpIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <ListItemButton>
                      <ListItemText>{el.validation_name}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box
            component="form"
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
            }}
            onSubmit={handleSubmit}
          >
            <Paper sx={{ p: 2, position: "sticky", top: 0, zIndex: 99 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Typography variant="h6" fontWeight={400} color="primary">
                    {validations[selectedValidationIndex]?.validation_name}
                  </Typography>
                  <IconButton
                    color="succcess"
                    onClick={() => setEditValidation(true)}
                  >
                    <EditSharpIcon></EditSharpIcon>
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    gap: 3,
                  }}
                >
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        startIcon={<AddSharpIcon />}
                        onClick={handleAddInstructionData}
                      >
                        Add Instruction
                      </Button>
                      <Button
                        size="small"
                        type="submit"
                        variant="contained"
                        startIcon={<SaveSharpIcon />}
                      >
                        Save
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </Paper>

            {validations[selectedValidationIndex]?.data?.map((v, i) => (
              <ValidationStep
                key={i}
                id={v.id ?? `new_${i}`}
                count={i + 1}
                technologies={technology}
                filters={validationFilters}
                instruction={v}
                removeInstruction={handleInstructionDelete}
              />
            ))}
          </Box>
        </Grid>
      </Grid>

      <Dialog
        fullWidth
        open={editValidation}
        onClose={handleCloseEditValidation}
      >
        <DialogTitle>Rename Validation</DialogTitle>
        <DialogContent>
          {editError && <Alert severity="error"> {editError}</Alert>}
          <TextField
            sx={{ mt: 2 }}
            label={"Validation Name"}
            fullWidth
            multiline
            onChange={(e) => setNewValidationName(e.target.value)}
            rows={3}
            name="validationName"
            defaultValue={validations[selectedValidationIndex]?.validation_name}
          ></TextField>
        </DialogContent>
        <DialogActions>
          {editLoading ? (
            <Box sx={{ py: 2, px: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseEditValidation}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleEditValidation}
                startIcon={<SaveSharpIcon />}
              >
                Save
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              marginLeft: "3%",
              marginBottom: "20px",
            }}
          >
            <Typography>Validation title</Typography>
          </div>
          <form action="" onSubmit={handleAddValidation}>
            <input
              type="text"
              name=""
              id="new_scenario_name"
              onChange={(e) => handleValidationNameChange(e)}
              style={{
                width: "90%",
                border: "1px solid",
                height: "35px",
                borderRadius: "10px",
              }}
            />
            {errNewValidation ? <p>{errNewValidationMessage}</p> : <></>}
            <div className="scanNamingButton_container">
              <input className="scanNamingButton" type="submit" value="Save" />
            </div>
          </form>
        </Box>
      </Modal>
      <Modal
        open={closeValidationDeleteConf}
        onClose={handleCloseValidationDeleteConf}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ marginLeft: "3%", marginBottom: "20px" }}>
            <p>
              You are about to delete <br />{" "}
              <b className="deleteModalWrap">
                {" "}
                {validations[indexValidationToDelete]?.validation_name}
              </b>
              . <br /> Would you like to proceed with the deletion?
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <button
              style={{ cursor: "pointer" }}
              className="scanNamingButton"
              onClick={HandleValidationDeleteConf}
            >
              Yes
            </button>
            <button
              style={{ cursor: "pointer" }}
              className="scanNamingButton"
              onClick={handleCloseValidationDeleteConf}
            >
              No
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#ffffff",
  boxShadow: 24,
  p: 3,
  borderRadius: "5px",
  border: 0,
  textAlign: "center",
};

export default Validations;
