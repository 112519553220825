import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { blue, green, grey, orange, red } from "@mui/material/colors";

import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import EditNoteSharpIcon from "@mui/icons-material/EditNoteSharp";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import { NavLink } from "react-router-dom";
import PlayCircleOutlineSharpIcon from "@mui/icons-material/PlayCircleOutlineSharp";
import ScanDelete from "../../components/Scans/ScanDelete";
import ScanLaunch from "../../components/Scans/ScanLaunch";
import { stringToDateFormat } from "../../utils";
import { useState } from "react";

export default function ScanReportsCard({
  name,
  type,
  last_run,
  report_count,
  id,
}) {
  const [startDelete, setStartDelete] = useState(false);
  const [startLaunch, setStartLaunch] = useState(false);

  const handleStartDelete = () => {
    setStartDelete(true);
  };
  const handleStartLaunch = () => {
    setStartLaunch(true);
  };
  return (
    <Box>
      {startDelete && (
        <ScanDelete
          scanId={id}
          scanName={name}
          scanType={type}
          setStartDelete={setStartDelete}
        />
      )}
      {startLaunch && (
        <ScanLaunch
          scanId={id}
          scanName={name}
          scanType={type}
          setStartLaunch={setStartLaunch}
        />
      )}
      <Paper sx={{ borderRadius: 0, position: "relative" }}>
        <Box sx={{ p: 1.5, display: "flex", flexDirection: "column" }}>
          <NavLink to={`?scanId=${id}&scanType=${type}`} title="Open">
            <Typography
              variant="subtitle2"
              color="primary"
              gutterBottom
              pt={1}
              fontWeight={600}
              sx={{ ":hover": { textDecoration: "underline" } }}
            >
              {name}
            </Typography>
          </NavLink>
          <Typography color={orange[500]} variant="caption">
            Last run date: {last_run ? stringToDateFormat(last_run) : "-"}
          </Typography>
          <Typography color={grey[600]} variant="caption" gutterBottom>
            {report_count} Report{report_count != 1 && <>s</>}
          </Typography>
          <Grid container columns={6} spacing={1}>
            <Grid item>
              <Tooltip title="Delete">
                <DeleteOutlineSharpIcon
                  sx={{ color: red[400], cursor: "pointer" }}
                  onClick={handleStartDelete}
                />
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Edit">
                <NavLink
                  to={
                    type === "crawl"
                      ? `/scan/crawl-scan?activeCrawl=${id}`
                      : `/scan/scenario-scan?activeScenario=${id}`
                  }
                >
                  <EditNoteSharpIcon
                    sx={{
                      color: grey[500],
                    }}
                  />
                </NavLink>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Launch">
                <PlayCircleOutlineSharpIcon
                  sx={{ color: green[400], cursor: "pointer" }}
                  onClick={handleStartLaunch}
                />
              </Tooltip>
            </Grid>
            <Grid item flexGrow={1}>
              <Box display={"flex"} flexDirection={"row-reverse"}>
                <Tooltip title="View Reports">
                  <NavLink to={`?scanId=${id}&scanType=${type}`}>
                    <FileOpenOutlinedIcon
                      sx={{
                        color: grey[800],
                      }}
                    />
                  </NavLink>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          px={2}
          bgcolor={type === "scenario" ? blue[400] : orange[400]}
          display={"flex"}
          alignItems={"center"}
          position={"absolute"}
          right={0}
          top={0}
        >
          <Typography
            color={"white"}
            textTransform={"capitalize"}
            variant="caption"
          >
            {type}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
}
