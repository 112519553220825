import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Fade,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useRef, useEffect, useState } from "react";

import Copyright from "../../components/Copyright";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import WarningAmberSharpIcon from "@mui/icons-material/WarningAmberSharp";
import { env } from "../../config";
import { red } from "@mui/material/colors";
import ReCAPTCHA from "react-google-recaptcha";
import { removeEventFromDataLayer } from "../../utils";

export default function SignupForm() {
  const navigate = useNavigate();
  const [createOrg, setCreateOrg] = useState(false);
  const [invitation, setInvitation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [countries, setCountries] = useState([]);
  const [formFocused, setFormFocused] = useState(false);
  const recaptcha = useRef();

  const referrer = localStorage.getItem("taglabReferrer") ?? null;
  const getCountries = async () => {
    const res = await fetch(`${env.REACT_APP_API_HOST}countries/`, {
      method: "GET",
      credentials: "include",
    });
    const data = await res.json();
    if (res.ok) {
      setCountries([...data]);
    }
  };

  useEffect(() => {
    getCountries();
    return () => {
      removeEventFromDataLayer("registration-start");
      removeEventFromDataLayer("registration-step-1");
    };
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      setError("Please verify you're not a robot!");
      return;
    }
    setLoading(true);
    // get data and convert to JSON string
    const data = new FormData(event.currentTarget);
    const dataObj = { referrer: referrer };
    data.forEach((v, k) => {
      dataObj[k] = v;
    });

    // send data
    try {
      const response = await fetch(`${env.REACT_APP_API_HOST}register/`, {
        method: "POST",
        credentials: "include",
        mode: "cors",
        body: JSON.stringify(dataObj),
      });
      const resData = await response.json();
      if (!response.ok) throw new Error(resData.error);
      localStorage.setItem(
        "taglabUser",
        JSON.stringify({
          profilePicture: resData.profile_picture,
          firstName: resData.first_name,
          username: resData.username,
          organization: resData.organization,
          plan: resData.plan_name,
          points_available: resData.points_available,
          referral: resData.referral,
          referrer: resData.referrer,
        })
      );
      window.dataLayer.push({
        event: "registration-step-1",
        username: resData.username,
        organization: resData.organization,
        plan: resData.plan_name,
        points_available: resData.points_available,
        referral: resData.referral,
        referrer: resData.referrer,
      });
      setError(null);
      localStorage.removeItem("taglabReferrer");
      navigate("/user-info");
    } catch (resError) {
      setError(`${resError}`);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateOrg = () => {
    setCreateOrg(true);
    setInvitation(false);
  };
  const handleInvitation = () => {
    setInvitation(true);
    setCreateOrg(false);
  };
  const handleFormFocus = () => {
    if (!formFocused) {
      window.dataLayer.push({ event: "registration-start" });
      setFormFocused(true);
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        {error && (
          <Box
            sx={{ my: 2, p: 1 }}
            bgcolor={red[100]}
            display={"flex"}
            alignItems={"center"}
            gap={2}
          >
            <WarningAmberSharpIcon size="large" color="error" />
            <Typography variant="caption" color="error">
              {error}
            </Typography>
          </Box>
        )}

        <Box
          component="form"
          noValidate={false}
          onSubmit={handleSubmit}
          onFocus={handleFormFocus}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                required
                fullWidth
                id="username"
                label="Email Address"
                name="username"
                autoComplete="email"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl
                size="small"
                required
                fullWidth
                id="password"
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  name="password"
                  autoComplete="new-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 4, mb: 2 }}>
            <Chip label="Organization" />
          </Divider>

          <Grid container columns={2} marginBottom={4}>
            <Grid
              item
              xs={1}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Chip
                color="primary"
                label="Create New Organization"
                clickable
                onClick={handleCreateOrg}
              />
            </Grid>
            <Grid
              item
              xs={1}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Chip
                color="secondary"
                label="I have an Invitation Code"
                clickable
                onClick={handleInvitation}
              ></Chip>
            </Grid>
          </Grid>
          {createOrg && (
            <Fade in={createOrg}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    name="organizationName"
                    label="Organization Name"
                    id="organizationName"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Organization Country
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      name="organizationCountry"
                      label="Organization Country"
                      id="organizationCountry"
                    >
                      {countries.map((c, i) => (
                        <MenuItem key={i} value={c}>
                          {c}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Fade>
          )}
          {invitation && (
            <Grid container spacing={2}>
              <Fade in={invitation}>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    name="invitation"
                    label="Invitation Code"
                    id="invitation"
                  />
                </Grid>
              </Fade>
            </Grid>
          )}
          <Divider sx={{ mt: 4, mb: 4 }}></Divider>

          <Typography variant="caption">
            By signing up or logging in with credentials associated to my
            identity, I acknowledge that I have read, understood, and agreed to
            the service{" "}
            <Link
              href="https://taglab.net/terms-and-conditions/"
              target="_blank"
              color="primary"
            >
              terms and conditions
            </Link>{" "}
            and{" "}
            <Link
              href="https://taglab.net/privacy-policy/"
              target="_blank"
              color={"primary"}
            >
              privacy policy
            </Link>
            .
          </Typography>

          {!loading ? (
            <Box
              sx={{
                mt: 3,
                mb: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReCAPTCHA
                ref={recaptcha}
                sitekey={process.env.REACT_APP_SITE_KEY}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
            </Box>
          ) : (
            <Box
              display={"flex"}
              justifyContent={"center"}
              sx={{ mt: 3, mb: 2 }}
            >
              <CircularProgress color="primary" />
            </Box>
          )}
          <Grid container justifyContent="flex-end">
            <Grid item>
              <NavLink to="?authMode=login">
                <Typography variant="body2" color="primary">
                  Already have an account? Sign in
                </Typography>
              </NavLink>
            </Grid>
          </Grid>
        </Box>

        {/* <Divider sx={{ mt: 4, mb: 4 }}>
          <Chip label="or sign up with" />
        </Divider>

        <SocialAuth /> */}
      </Box>
      <Copyright sx={{ mt: 4 }} />
    </Container>
  );
}
