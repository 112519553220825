import { Grid, List, Box, ListItem, Paper, Typography } from "@mui/material";
import sendImage from "../../assets/referrals/send.png";
import claimImage from "../../assets/referrals/claim.png";
import earnImage from "../../assets/referrals/earn.png";
import trendImage from "../../assets/referrals/trend.png";

export default function AboutReferrals() {
  return (
    <Paper sx={{ my: 2, p: 2 }}>
      <Typography paragraph variant="h6">
        Welcome to TAGLAB's Referral Program - Your Gateway to Earning Big!
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        Copy Unlock a world of earnings by simply referring your friends to
        TAGLAB, an estimated everage of $750 for each friend with upgraded
        membership. Here's how:
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              px: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 3,
                height: 150,
              }}
            >
              <img src={sendImage} width={100}></img>
            </Box>
            <Typography fontWeight={600}>
              1. Share Your Unique Referral Link.
            </Typography>
            <Typography variant="subtitle2">
              Ask your friends to subscribe to TAGLAB through your own link
            </Typography>
          </Box>
        </Grid>
        <Grid item md={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              px: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 3,
                height: 150,
              }}
            >
              <img src={trendImage}></img>
            </Box>

            <Typography fontWeight={600}>
              2. Track Subscriptions and Ensure Paying Users.
            </Typography>
            <Typography variant="subtitle2">
              Monitor your referrals and keep an eye on their subscription
              status, help them through the subscription process. Your success
              is directly tied to theirs!
            </Typography>
          </Box>
        </Grid>
        <Grid item md={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              px: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 3,
                height: 150,
              }}
            >
              <img src={earnImage}></img>
            </Box>

            <Typography fontWeight={600}>
              3. Earn 50% of the License Term.
            </Typography>
            <Typography variant="subtitle2">
              As your referred users make payments for their chosen license
              term, you earn big – a whopping 50%! Enjoy substantial commissions
              as your network grows and embraces TAGLAB.
            </Typography>
          </Box>
        </Grid>
        <Grid item md={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              px: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 3,
                height: 150,
              }}
            >
              <img src={claimImage}></img>
            </Box>

            <Typography fontWeight={600}>
              4. Claim and Receive Your Funds:
            </Typography>
            <Typography variant="subtitle2">
              It's easy to claim your earnings. Once your referred users make
              their payments, simply navigate to your dashboard and claim your
              well-deserved funds. We believe in hassle-free rewards!
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
