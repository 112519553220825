import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Account from "./pages/AdminDashboard/Components/Account";
import Auth from "./pages/Auth/Auth";
import Cookies from "./pages/Report/cookies/cookies";
import CrawlScan from "./pages/CrawlScan";
import CronSchedule from "./pages/CronSchedule";
import CronScheduleDetails from "./pages/CronScheduleDetails";
import EmailVerification from "./pages/EmailVerification.jsx";
import Home from "./pages/Home";
import Layout from "./layout/layout";
import Login from "./pages/Login";
import Main from "./layout/Main.jsx";
import MainAdminLayout from "./pages/AdminDashboard/MainAdminLayout";
import NotFind from "./pages/NotFind";
import PlanCreation from "./pages/AdminDashboard/Components/PlanCreation";
import Plans from "./pages/AdminDashboard/Components/Plans";
import React from "react";
import Refer from "./pages/Referral/Refer.jsx";
import Referrals from "./pages/Referral/Referrals.jsx";
import Regex from "./pages/AdminDashboard/Components/Regex";
import ReportCommand from "./pages/Report/reportCommand/reportCommand";
import ReportLog from "./pages/Report/Log/ReportLog.jsx";
import ReportNew from "./pages/Report/ReportNew/reportNew";
import ScanReportDetails from "./pages/ScanReportDetails/ScanReportDetails.jsx";
import ScansReports from "./pages/ScansReports/ScansReports";
import ScenarioScan from "./pages/ScenarioScan";
import Schedule from "./pages/Schedule";
import TagSummary from "./pages/Report/TagSummary/TagSummary";
import Tutorial from "./pages/Tutorial/Tutorial.jsx";
import UserInformation from "./pages/UserInformation";
import Users from "./pages/AdminDashboard/Components/Users";
import Validation from "./pages/Report/validation/validation";
import Validations from "./pages/Validations/Validations";

const RoutesComponent = ({
  notifNumber,
  shakeNotif,
  setShakeNotif,
  setNotifNumber,
  get_notifications,
  notifs,
}) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/auth" element={<Auth />} />
        <Route
          exact
          path="/validations"
          element={
            <Layout
              notifNumber={notifNumber}
              shakeNotif={shakeNotif}
              setShakeNotif={setShakeNotif}
              setNotifNumber={setNotifNumber}
              get_notifications={get_notifications}
              notifs={notifs}
            >
              <Validations />
            </Layout>
          }
        />

        <Route
          exact
          path="/scans-reports"
          element={
            <Layout
              notifNumber={notifNumber}
              shakeNotif={shakeNotif}
              setShakeNotif={setShakeNotif}
              setNotifNumber={setNotifNumber}
              get_notifications={get_notifications}
              notifs={notifs}
            >
              <ScansReports />
            </Layout>
          }
        />
        <Route
          exact
          path="/scans-reports/details"
          element={
            <Layout
              notifNumber={notifNumber}
              shakeNotif={shakeNotif}
              setShakeNotif={setShakeNotif}
              setNotifNumber={setNotifNumber}
              get_notifications={get_notifications}
              notifs={notifs}
            >
              <ScanReportDetails />
            </Layout>
          }
        />
        <Route
          exact
          path="/report/tagsummary"
          element={
            <Layout
              title="Tags Summary"
              notifNumber={notifNumber}
              shakeNotif={shakeNotif}
              setShakeNotif={setShakeNotif}
              setNotifNumber={setNotifNumber}
              get_notifications={get_notifications}
              notifs={notifs}
            >
              {/* <ReportNew /> */}
              <TagSummary />
            </Layout>
          }
        />
        <Route
          exact
          path="/report/commands"
          element={
            <Layout
              title="Pages / commands"
              notifNumber={notifNumber}
              shakeNotif={shakeNotif}
              setShakeNotif={setShakeNotif}
              setNotifNumber={setNotifNumber}
              get_notifications={get_notifications}
              notifs={notifs}
            >
              {/* <RepLayoutort /> */}
              <ReportCommand />
            </Layout>
          }
        />
        <Route
          exact
          path="/report/validation"
          element={
            <Layout
              title="Validations"
              notifNumber={notifNumber}
              shakeNotif={shakeNotif}
              setShakeNotif={setShakeNotif}
              setNotifNumber={setNotifNumber}
              get_notifications={get_notifications}
              notifs={notifs}
            >
              {/* <RepLayoutort /> */}
              <Validation />
            </Layout>
          }
        />
        <Route
          exact
          path="/report/cookies"
          element={
            <Layout
              title="Cookies"
              notifNumber={notifNumber}
              shakeNotif={shakeNotif}
              setShakeNotif={setShakeNotif}
              setNotifNumber={setNotifNumber}
              get_notifications={get_notifications}
              notifs={notifs}
            >
              {/* <RepLayoutort /> */}
              <Cookies />
            </Layout>
          }
        />
        <Route
          exact
          path="/report/log"
          element={
            <Layout
              title="Log"
              notifNumber={notifNumber}
              shakeNotif={shakeNotif}
              setShakeNotif={setShakeNotif}
              setNotifNumber={setNotifNumber}
              get_notifications={get_notifications}
              notifs={notifs}
            >
              <ReportLog />
            </Layout>
          }
        />
        <Route
          exact
          path="/scan/scenario-scan"
          element={
            <Layout
              notifNumber={notifNumber}
              shakeNotif={shakeNotif}
              setShakeNotif={setShakeNotif}
              setNotifNumber={setNotifNumber}
              get_notifications={get_notifications}
              notifs={notifs}
            >
              <ScenarioScan />
            </Layout>
          }
        />
        <Route
          exact
          path="/schedule"
          element={
            <Layout
              notifNumber={notifNumber}
              shakeNotif={shakeNotif}
              setShakeNotif={setShakeNotif}
              setNotifNumber={setNotifNumber}
              get_notifications={get_notifications}
              notifs={notifs}
            >
              <CronSchedule />
            </Layout>
          }
        />
        <Route
          exact
          path="/schedule/:scheduleId"
          element={
            <Layout
              notifNumber={notifNumber}
              shakeNotif={shakeNotif}
              setShakeNotif={setShakeNotif}
              setNotifNumber={setNotifNumber}
              get_notifications={get_notifications}
              notifs={notifs}
            >
              <CronScheduleDetails />
            </Layout>
          }
        />
        <Route
          exact
          path="/scan/crawl-scan"
          element={
            <Layout
              notifNumber={notifNumber}
              shakeNotif={shakeNotif}
              setShakeNotif={setShakeNotif}
              setNotifNumber={setNotifNumber}
              get_notifications={get_notifications}
              notifs={notifs}
            >
              <CrawlScan />
            </Layout>
          }
        />
        <Route
          exact
          path="/scan/scenario-scan"
          element={
            <Main
              notifNumber={notifNumber}
              shakeNotif={shakeNotif}
              setShakeNotif={setShakeNotif}
              setNotifNumber={setNotifNumber}
              get_notifications={get_notifications}
              notifs={notifs}
            >
              <ScenarioScan />
            </Main>
          }
        />
        {/* <Route
          exact
          path="/user-info"
          element={
            <Main
              notifNumber={notifNumber}
              shakeNotif={shakeNotif}
              setShakeNotif={setShakeNotif}
              setNotifNumber={setNotifNumber}
              get_notifications={get_notifications}
              notifs={notifs}
            >
              <UserInformation />
            </Main>
          }
        /> */}
        <Route
          exact
          path="/user-info"
          element={
            <Layout
              notifNumber={notifNumber}
              shakeNotif={shakeNotif}
              setShakeNotif={setShakeNotif}
              setNotifNumber={setNotifNumber}
              get_notifications={get_notifications}
              notifs={notifs}
            >
              <UserInformation />
            </Layout>
          }
        />
        <Route
          exact
          path="/referrals"
          element={
            <Layout
              notifNumber={notifNumber}
              shakeNotif={shakeNotif}
              setShakeNotif={setShakeNotif}
              setNotifNumber={setNotifNumber}
              get_notifications={get_notifications}
              notifs={notifs}
            >
              <Referrals />
            </Layout>
          }
        />
        <Route
          exact
          path="/tutorial"
          element={
            <Layout
              notifNumber={notifNumber}
              shakeNotif={shakeNotif}
              setShakeNotif={setShakeNotif}
              setNotifNumber={setNotifNumber}
              get_notifications={get_notifications}
              notifs={notifs}
            >
              <Tutorial />
            </Layout>
          }
        />
        <Route
          exact
          path="/email-verification"
          element={<EmailVerification />}
        />
        <Route path="/affiliates/:referralId" element={<Refer />}></Route>
        <Route
          exact
          path="/admin-dashboard/account"
          element={
            <MainAdminLayout>
              <Account />
            </MainAdminLayout>
          }
        />
        <Route
          exact
          path="/admin-dashboard/users"
          element={
            <MainAdminLayout>
              <Users />
            </MainAdminLayout>
          }
        />
        <Route
          exact
          path="/admin-dashboard/plans"
          element={
            <MainAdminLayout>
              <Plans />
            </MainAdminLayout>
          }
        />
        <Route
          exact
          path="/admin-dashboard/regex"
          element={
            <MainAdminLayout>
              <Regex />
            </MainAdminLayout>
          }
        />
        <Route
          exact
          path="admin-dashboard/plan_creation"
          element={
            <MainAdminLayout>
              <PlanCreation />
            </MainAdminLayout>
          }
        />
        <Route path="/404" element={<NotFind />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </BrowserRouter>
  );
};
export default RoutesComponent;
