import "./userInfoStyle.css";

import {
  Avatar,
  Box,
  Grid,
  LinearProgress,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { blue, grey } from "@mui/material/colors";

import AvatarEditPopup from "../../components/user/avatarEditPopup";
import ChangePassword from "../../components/user/ChangePassword.jsx";
import CorporateFareSharpIcon from "@mui/icons-material/CorporateFareSharp";
import EmailNotificationSettings from "../../components/EmailNotificationSettings/EmailNotificationSettings";
import Organization from "../../components/Organization/Organization.jsx";
import ReportHistory from "../../components/user/ReportHistory.jsx";
import ScanPreferences from "../../components/user/ScanPreferences.jsx";
import Typography from "@mui/material/Typography";
import { env } from "../../config.js";
import { formatBytes } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authStateActions } from "../../store/index.js";

function DataDisplay({ data, title }) {
  return (
    <Box
      sx={{
        borderBottom: 1,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="subtitle1">{data}</Typography>
      <Typography variant="overline">{title}</Typography>
    </Box>
  );
}

function UserInformation() {
  const [userData, setUserData] = useState(null);
  const [screenData, setScreenData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const dispatch = useDispatch();

  let navigate = useNavigate();
  const handleRoute = useCallback(
    (val) => {
      navigate(val);
    },
    [navigate]
  );

  const checkCrawlPoints = async () =>
    await fetch(`${env.REACT_APP_API_HOST}user_profile/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        return res.text();
      })
      .then((response) => JSON.parse(response))
      .then((response) => {
        if (response.isAuthenticated === "False") {
          handleRoute("/login");
          return;
        }
        setUserData(response.UserInfo);

        localStorage.setItem(
          "taglabUser",
          JSON.stringify({
            firstName: response.UserInfo.user_first_name,
            profilePicture: response.UserInfo.profile_picture,
          })
        );
        //console.log({userInfo : response.UserInfo});
      });

  const getScreenData = () =>
    fetch(`${env.REACT_APP_API_HOST}get_screen_dimensions/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => setScreenData(data));

  useEffect(() => {
    checkCrawlPoints();
    getScreenData();
    dispatch(authStateActions.updateCheckEmailVerification());
  }, []);

  const changeTab = (event, newValue) => {
    setActiveTab(newValue);
  };
  return (
    <>
      {userData && (
        <>
          <Paper sx={{ position: "sticky", top: 0, zIndex: 99, mb: 2 }}>
            <Grid container columnSpacing={4} sx={{ p: 2 }}>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", gap: 4, alignItems: "center" }}
              >
                <AvatarEditPopup currentAvatar={userData?.profile_picture} />
                <Box
                  sx={{
                    flexGrow: 1,
                    gap: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    fontWeight={600}
                    color={`primary`}
                    variant="h6"
                    gutterBottom
                  >
                    {userData?.user_name}
                  </Typography>
                  <DataDisplay data={userData?.fullname ?? ""} title={"Name"} />
                  <DataDisplay
                    data={userData?.user_email ?? ""}
                    title={"email"}
                  />
                  <DataDisplay
                    data={
                      (() =>
                        new Date(
                          userData?.account_created
                        ).toLocaleDateString())() ?? ""
                    }
                    title={"Date Created"}
                  />
                  <DataDisplay
                    data={
                      (() =>
                        new Date(
                          userData?.plan_end_date.slice(0, 10)
                        ).toLocaleDateString())() ?? ""
                    }
                    title={"Account Expiry"}
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{ display: "flex", gap: 4, alignItems: "center" }}
              >
                <Avatar sx={{ width: 80, height: 80, bgcolor: blue[400] }}>
                  <CorporateFareSharpIcon sx={{ width: 40, height: 40 }} />
                </Avatar>
                <Box
                  sx={{
                    flexGrow: 1,
                    gap: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    fontWeight={600}
                    color={`primary`}
                    gutterBottom
                    variant="h6"
                  >
                    {userData?.organization_name}
                  </Typography>
                  <DataDisplay
                    data={userData?.plan_name ?? ""}
                    title={"Plan"}
                  />
                  <Box sx={{ textAlign: "right" }}>
                    {((userData?.plan_points_initial -
                      userData?.plan_points_current) /
                      userData?.plan_points_initial) *
                      100 >=
                    90 ? (
                      <LinearProgress
                        color="error"
                        variant="determinate"
                        value={
                          ((userData?.plan_points_initial -
                            userData?.plan_points_current) /
                            userData?.plan_points_initial) *
                            100 >
                          100
                            ? 100
                            : ((userData?.plan_points_initial -
                                userData?.plan_points_current) /
                                userData?.plan_points_initial) *
                              100
                        }
                      />
                    ) : (
                      <LinearProgress
                        variant="determinate"
                        value={
                          ((userData?.plan_points_initial -
                            userData?.plan_points_current) /
                            userData?.plan_points_initial) *
                          100
                        }
                      />
                    )}
                    <Typography variant="overline">
                      Points Used:{" "}
                      {(
                        userData?.plan_points_initial -
                        userData?.plan_points_current
                      ).toLocaleString()}
                      /{userData?.plan_points_initial.toLocaleString()} points
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: "right" }}>
                    {(userData?.total_disk_consumption /
                      userData.plan_storage) *
                      100 >=
                    90 ? (
                      <LinearProgress
                        color="error"
                        variant="determinate"
                        value={
                          (userData?.total_disk_consumption /
                            userData.plan_storage) *
                            100 >
                          100
                            ? 100
                            : (userData?.total_disk_consumption /
                                userData.plan_storage) *
                              100
                        }
                      />
                    ) : (
                      <LinearProgress
                        color="info"
                        variant="determinate"
                        value={
                          (userData?.total_disk_consumption /
                            userData.plan_storage) *
                          100
                        }
                      />
                    )}

                    <Typography variant="overline">
                      Disk Used:{" "}
                      {formatBytes(userData?.total_disk_consumption) +
                        "/" +
                        formatBytes(userData.plan_storage)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Tabs
              sx={{ bgcolor: grey[200], mt: 2 }}
              variant="fullWidth"
              value={activeTab}
              onChange={changeTab}
            >
              <Tab label="User Preferences" id="user-tab" aria-controls={0} />
              <Tab label="Account History" id="history-tab" aria-controls={1} />
              <Tab label="My Organization" id="org-tab" aria-controls={2} />
            </Tabs>
          </Paper>

          <Box
            role="tabpanel"
            id="user"
            hidden={activeTab !== 0}
            sx={{
              display: activeTab === 0 ? "flex" : "none",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {userData && screenData.length > 0 && (
              <ScanPreferences
                preferred_browser={userData.preferred_browser}
                JS_objects={userData.JS_objects}
                default_crawl_dimensions={userData.default_crawl_dimensions}
                screenData={screenData}
              />
            )}
            <EmailNotificationSettings />
            <ChangePassword user_email={userData.user_email} />
          </Box>
          <Box role="tabpanel" id="history" hidden={activeTab !== 1}>
            <ReportHistory />
          </Box>
          <Box role="tabpanel" id="org" hidden={activeTab !== 2}>
            <Organization />
          </Box>
        </>
      )}
    </>
  );
}

export default UserInformation;
