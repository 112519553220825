import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import MarkEmailReadSharpIcon from "@mui/icons-material/MarkEmailReadSharp";
import authServices from "../services/authService";
import { env } from "../config";
import { grey } from "@mui/material/colors";
import loginLogo from "../assets/logo-final.png";
import { useSelector } from "react-redux";

export default function EmailVerificationModal() {
  const [resending, setResending] = useState(false);
  const [resendState, setResendState] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [show, setShow] = useState(false);
  const checkVerification = useSelector(
    (state) => state.authState.checkEmailVerification
  );

  const handleResendLink = async () => {
    setResending(true);
    setResendState(null);
    try {
      const res = await fetch(`${env.REACT_APP_API_HOST}email-verification/`, {
        method: "POST",
        credentials: "include",
      });
      const resData = await res.json();
      if (!res.ok) throw new Error(resData.error);
      setResendState({ status: "primary", message: resData.message });
    } catch (resError) {
      setResendState({ status: "error", message: resError.message });
    } finally {
      setResending(false);
    }
  };

  const handleReload = () => {
    window.location.reload();
  };

  const handleRevoke = () => {
    fetch(`${env.REACT_APP_API_HOST}logout/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        localStorage.removeItem("taglabUser");
        handleReload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    const login = authServices.checkLogin();
    login.then((isLoggedIn) => {
      if (isLoggedIn) {
        fetch(`${env.REACT_APP_API_HOST}email-verification/`, {
          method: "GET",
          credentials: "include",
        })
          .then((res) => res.json())
          .then((data) => {
            setUserEmail(data.email);
            setShow(!data.verified);
          })
          .catch(() => {
            setShow(false);
          });
      } else {
        setShow(false);
      }
    });
    return () => {};
  }, [checkVerification]);

  return (
    <>
      <Dialog fullScreen open={show} sx={{ bgcolor: grey[200] }}>
        <DialogTitle>
          <img src={loginLogo} width="150" />
        </DialogTitle>
        <DialogContent sx={{ bgcolor: grey[200] }}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: grey[200],
            }}
          >
            <Card fullScreen>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: "#12b922" }}>
                    <MarkEmailReadSharpIcon />
                  </Avatar>
                }
                title={<Typography variant="h6">Email Verification</Typography>}
              ></CardHeader>

              <CardContent>
                {resendState && (
                  <Typography gutterBottom color={resendState.status}>
                    {resendState.message}
                  </Typography>
                )}
                <Typography paragraph>
                  Verify your email to continue. Go to your email
                  <pre>
                    <Typography variant="subtitle2" color={"primary"}>
                      {userEmail}
                    </Typography>
                  </pre>
                  and click the link provided. If you did not receive a link,
                  click on resend.
                </Typography>
              </CardContent>
              <CardActions>
                {resending ? (
                  <>
                    <Button>
                      <CircularProgress />
                    </Button>
                  </>
                ) : (
                  <>
                    <Button onClick={handleRevoke}>
                      Return to Sign Up Page
                    </Button>
                    <Button onClick={handleReload}>Reload Page</Button>
                    <Button onClick={handleResendLink}>Resend Link</Button>
                  </>
                )}
              </CardActions>
            </Card>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
