import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { NavLink, useSearchParams } from "react-router-dom";
import { green, grey } from "@mui/material/colors";
import { useEffect, useState } from "react";

import ScanReportsCard from "./ScanReportsCard";
import ScanReportsVersions from "./ScanReportsVersions";
import ScansFilter from "./ScansFilter";
import ScansReportsListCard from "./ScansReportListCard";
import { env } from "../../config";

export default function ScansReports() {
  const [scans, setScans] = useState([]);
  const [displayScans, setDisplayScans] = useState([]);
  const [scanFilters, setScanFilters] = useState({
    alphabeticalSort: null,
    sortByDate: null,
  });
  const [loadingScans, setLoadingScans] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedScan, setSelectedScan] = useState(null);

  const getScans = async () => {
    try {
      const response = await fetch(`${env.REACT_APP_API_HOST}scans/`, {
        method: "GET",
        credentials: "include",
      });
      const resData = await response.json();
      if (!response.ok) throw new Error(response.statusText);
      setScans(resData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingScans(false);
    }
  };

  // Sorts and Filters
  const sort = () => {
    let newScans = scans;
    const search = scanFilters["searchTerm"] ?? "";
    const alphabeticalSort = scanFilters["alphabeticalSort"] ?? null;
    const sortByDate = scanFilters["sortByDate"] ?? null;
    const displayCrawls = scanFilters["displayCrawls"] ?? true;
    const displayScenarios = scanFilters["displayScenarios"] ?? true;

    if (search.length > 0) {
      newScans = newScans.filter((e) =>
        e.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    switch (alphabeticalSort) {
      case "ascending":
        newScans = newScans.toSorted((a, b) => {
          const A = a.name[0].toLowerCase();
          const B = b.name[0].toLowerCase();
          return A < B ? -1 : B > A ? 1 : 0;
        });
        break;
      case "descending":
        newScans = newScans.toSorted((a, b) => {
          const A = a.name[0].toLowerCase();
          const B = b.name[0].toLowerCase();
          return A > B ? -1 : B < A ? 1 : 0;
        });
        break;
    }
    switch (sortByDate) {
      case "ascending":
        newScans = newScans.toSorted(
          (a, b) => new Date(a.last_run) - new Date(b.last_run)
        );
        break;
      case "descending":
        newScans = newScans.toSorted(
          (a, b) => new Date(b.last_run) - new Date(a.last_run)
        );
        break;
    }

    if (!displayCrawls) {
      newScans = newScans.filter((el) => el.type !== "crawl");
    }
    if (!displayScenarios) {
      newScans = newScans.filter((el) => el.type !== "scenario");
    }

    // console.log(newScans);
    setDisplayScans(newScans);
  };

  useEffect(() => {
    setLoadingScans(true);
    getScans();
  }, []);

  useEffect(() => {
    if (!loadingScans) sort();
  }, [scanFilters, scans]);

  // Selected Scan
  useEffect(() => {
    const selectedScanId = searchParams.get("scanId");
    const selectedScanType = searchParams.get("scanType");
    const scan = scans.find(
      (el) => el.id == selectedScanId && el.type === selectedScanType
    );
    setSelectedScan(scan);
  }, [searchParams, scans]);

  useEffect(() => {
    if (!selectedScan) setScanFilters({});
  }, [selectedScan]);

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography variant="h5" display={"flex"} gutterBottom>
          {selectedScan ? (
            <>
              <NavLink to={"."}>
                <Typography
                  variant="h5"
                  color="primary"
                  display={"flex"}
                  sx={{ ":hover": { textDecoration: "underline" } }}
                >
                  Reports
                </Typography>
              </NavLink>{" "}
              &nbsp;/&nbsp;{selectedScan.name}
            </>
          ) : (
            <>Reports</>
          )}
        </Typography>
        {scans.length > 0 && !selectedScan && (
          <ScansFilter setScanFilters={setScanFilters} type={1} />
        )}
      </Box>

      {selectedScan ? (
        <>
          <Grid container columnSpacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              sx={{
                height: "calc(100vh - 150px)",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <Paper
                square
                sx={{
                  position: "sticky",
                  top: 0,
                  bgcolor: "white",
                  zIndex: 999,
                }}
              >
                <Box
                  px={2}
                  py={1}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <ScansFilter setScanFilters={setScanFilters} type={2} />
                  {/* <Typography variant="overline" gutterBottom color={grey[600]}>
                    {displayScans.length} Scans
                  </Typography> */}
                </Box>
              </Paper>
              {displayScans.map((e, i) => (
                <ScansReportsListCard key={i} {...e} />
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              lg={9}
              pb={2}
              sx={{
                height: "calc(100vh - 140px)",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <ScanReportsVersions {...selectedScan} />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {loadingScans ? (
            <Box display={"block"}>
              <Typography variant="subtitle">Loading Scans</Typography>
              <LinearProgress />
            </Box>
          ) : (
            <Grid
              container
              alignItems="center"
              columns={{ xs: 2, sm: 2, md: 6, lg: 12, xl: 10 }}
              spacing={2}
              mt={1}
            >
              {displayScans.map((e, i) => (
                <Grid
                  key={i}
                  item
                  width={"100%"}
                  xs={1}
                  sm={1}
                  md={2}
                  lg={3}
                  xl={2}
                >
                  <ScanReportsCard {...e} />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </>
  );
}
