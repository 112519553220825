import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { NavLink } from "react-router-dom";
import { env } from "../../config";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export default function ForgotPasswordForm() {
  const [loading, setLoading] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const recaptcha = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    const data = new FormData(event.currentTarget);
    try {
      const res = await fetch(`${env.REACT_APP_API_HOST}forgot-password/`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ username: data.get("username") }),
      });
      const resData = await res.json();
      if (!res.ok) throw new Error(resData.error);
      setStateMessage({ status: "info", message: resData.message });
      event.target.reset();
    } catch (resError) {
      setStateMessage({ status: "error", message: resError.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5" gutterBottom>
        Request Password Reset
      </Typography>

      {stateMessage && (
        <Typography variant="body2" color={stateMessage.status}>
          {stateMessage.message}
        </Typography>
      )}
      <Box
        component="form"
        noValidate={false}
        onSubmit={handleSubmit}
        sx={{ mt: 1 }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
        />
        {loading ? (
          <>
            <Box display={"flex"} justifyContent={"center"} py={2}>
              <CircularProgress />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              mt: 3,
              mb: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReCAPTCHA
              ref={recaptcha}
              sitekey={process.env.REACT_APP_SITE_KEY}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ mt: 3, mb: 2 }}
            >
              Request Reset
            </Button>
          </Box>
        )}

        <Grid container>
          <Grid item xs></Grid>
          <Grid item>
            <NavLink to="?authMode=login">
              <Typography variant="body2" color="primary">
                {"Cancel and return to login"}
              </Typography>
            </NavLink>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
