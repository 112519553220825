import { Spinner } from "@material-tailwind/react";

const Loader = () => {
  return (
    <div className="h-[70vh] w-full">
      <div className="flex justify-center items-center h-full">
        <Spinner className="h-12 w-12" color="indigo" />
      </div>
    </div>
  );
};

export default Loader;
