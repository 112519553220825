import React, { useState, useCallback, useEffect } from "react";
import Input from "../../../components/Input";
import "../adminLayoutStyle.css";
import { env } from "../../../config.js";
import { useNavigate } from "react-router-dom";

function PlanCreation() {
  const [data, setData] = useState({
    plan_name: "",
    amount: "",
    notes: "",
    plan_points: "",
  });
  let navigate = useNavigate();

  const handleRoute = useCallback(
    (val) => {
      navigate(val);
    },
    [navigate]
  );
  const handleChange = (e, tag) => {
    const newData = { ...data };
    newData[tag] = e.target.value;
    setData(newData);
  };
  const handleCheckUser = async () => {
    await fetch(`${env.REACT_APP_API_HOST}checkUser/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.user === 0) {
          handleRoute("/scan/scenario-scan");
        }
        if (response.isAuthenticated === "False") handleRoute("/login");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    handleCheckUser();
  }, []);

  const handleSavePlan = async () => {
    await fetch(`${env.REACT_APP_API_HOST}admin_registerPlan/`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response.isAuthenticated === "False") handleRoute("/login");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="account_container">
      <Input
        handleChange={handleChange}
        h2Title={"Plan Name"}
        value={data.plan_name}
        tag="plan_name"
      />
      <Input
        handleChange={handleChange}
        h2Title={"Amount"}
        value={data.amount}
        tag="amount"
        number={true}
      />
      <Input
        handleChange={handleChange}
        h2Title={"Notes"}
        value={data.notes}
        tag="notes"
      />
      <Input
        handleChange={handleChange}
        h2Title={"Plan points"}
        value={data.plan_points}
        tag="plan_points"
      />
      <button
        onClick={handleSavePlan}
        className="mainAdimn_common_buttonContainer"
      >
        VALIDATE & SAVE
      </button>
    </div>
  );
}

export default PlanCreation;
