import React,{useCallback, useEffect} from "react";
import "./homeStyle.css";
import { useNavigate } from "react-router-dom";


function Home() {
  let navigate = useNavigate();

  const handleRoute = useCallback((val) => {
    navigate(val);
  },[navigate]);
  useEffect(() => {
    handleRoute('/login');
  }, [handleRoute]);
  
  return (
    <div></div>
  );
}

export default Home;
