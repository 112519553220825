import React from "react";
import "./inputStyle.css";
function Input({ handleChange, value, h2Title, tag, number }) {
  return (
    <div className="commonInput_container">
      <label className="commonInput_title">{h2Title}</label>
      <input
        type={`${number ? "number" : "text"}`}
        onChange={(e) => handleChange(e, tag)}
        className="commonInput_style"
        value={value}
      />
    </div>
  );
}

export default Input;
