import "./CronBuilder.css";
import "./CronSchedule.css";
import "../../components/inputStyle.css";

import { Box, Modal } from "@mui/material";
import Cron, { HEADER } from "react-cron-generator";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import Card from "../../components/card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { env } from "../../config.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function CronSchedule() {
  const [cron, setCron] = useState({ value: "" });
  const [cronName, setCronName] = useState("");
  const [cronEnabled, setCronEnabled] = useState(true);
  const [cronOneOff, setCronOneOff] = useState(false);
  const [cronStart, setCronStart] = useState("");
  const [cronExpiry, setCronExpiry] = useState("");
  const [filters, setFilters] = useState([]);
  const [reportsList, setReportsList] = useState([]);
  const [typeFilter, setTypeFilter] = useState(["c", "s"]);
  const [reportsSearchQuery, setReportsSearchQuery] = useState("");
  const [alfaFiltersIcon, setAlfaFiltersIcon] = useState(
    "fa-solid fa-arrow-down-a-z"
  );
  const [selectedFilteredBoolean, setSelectedFilteredBoolean] = useState(false);
  const [selectedScan, setSelectedScan] = useState(null);
  const [saveCronError, setSaveCronError] = useState(null);
  const [scheduledTasks, setScheduledTasks] = useState([]);
  const [editSchedule, setEditSchedule] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);

  const [deletingCron, setDeletingCron] = useState(false);
  const [deteteStatus, setDeleteStatus] = useState(null);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const handleCloseSaveModal = () => setOpenSaveModal(false);
  const handleOpenSaveModal = () => setOpenSaveModal(true);

  const options = {
    headers: [HEADER.DAILY, HEADER.WEEKLY, HEADER.MONTHLY],
  };

  let navigate = useNavigate();
  const handleRoute = useCallback(
    (val) => {
      navigate(val);
    },
    [navigate]
  );

  const getScheduledTasks = useCallback(async () => {
    await fetch(`${env.REACT_APP_API_HOST}scheduled_tasks/`, {
      method: "GET",
      credentials: "include",
    })
      .then(async (res) => {
        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.error);
        }
        return res.json();
      })
      .then((data) => {
        setScheduledTasks([...data]);
        handleCloseSaveModal();
      })
      .catch((err) => {
        // handleRoute("/login");
      });
  }, []);

  const getScanData = useCallback(async () => {
    await fetch(`${env.REACT_APP_API_HOST}get_crawls_scans/`, {
      method: "GET",
      credentials: "include",
    })
      .then(async (res) => {
        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.error);
        }
        return res.json();
      })
      .then(async (response) => {
        if (response.isAuthenticated === "False") {
          handleRoute("/login");
          return;
        }

        const scans = response.scans.map((item) => ({
          type: "scan",
          id: item.scan_id,
          name: item.scan_name,
          validation_file_name: item.validation_file_name,
          instructions: item.instructions,
        }));
        const crawls = response.crawls.map((item) => ({
          type: "crawl",
          id: item.crawler_id,
          name: item.crawl_name,
          validation_file_name: item.validation_file_name,
          include_subdomain: item.include_subdomain,
          include_urls_with_qm_hashtag: item.include_urls_with_qm_hashtag,
          scenario_to_run_before: item.scenario_to_run_before,
          uri_regex: item.uri_regex,
          url_to_crawl: item.url_to_crawl,
          crawler_limit: item.crawler_limit,
        }));
        const reports = [...scans, ...crawls];
        setReportsList(reports);
      });
  }, []);

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      getScanData(subscribe);
      getScheduledTasks(subscribe);
      // checkUser();
    }
    return () => (subscribe = false);
  }, [getScanData]);

  const filterAlphabetically = () => {
    const copyList = [...reportsList];
    if (filters.includes("alfa")) {
      setAlfaFiltersIcon(
        alfaFiltersIcon === "fa-solid fa-arrow-down-z-a"
          ? "fa-solid fa-arrow-down-a-z"
          : "fa-solid fa-arrow-down-z-a"
      );
      setReportsList(copyList.reverse());
    } else {
      setAlfaFiltersIcon("fa-solid fa-arrow-down-a-z");
      filters.push("alfa");
      const filteredList = copyList.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      setReportsList(filteredList);
    }
  };
  const handleTypeFilter = (event, newFormats) => {
    setTypeFilter(newFormats);
  };

  const handleSearchFiltring = (e) => {
    setSelectedFilteredBoolean(true);
  };

  const cronNameChangeHandler = (e) => {
    setCronName(e.target.value);
  };

  const cronStartChangeHandler = (e) => {
    setCronStart(e.target.value);
  };
  const cronExpiryChangeHandler = (e) => {
    setCronExpiry(e.target.value);
  };
  const cronOneOffChangeHandler = (e) => {
    setCronOneOff(!cronOneOff);
  };
  const cronEnabledChangeHandler = (e) => {
    setCronEnabled(!cronEnabled);
  };

  const closeCronEditorHandler = () => {
    setSelectedScan(null);
    setEditSchedule(null);
  };
  const cronFormat = (_cron) => {
    // format to fit support values
    const cronArr = _cron.replaceAll("?", "*").split(" ");
    let formattedCron = "";
    if (cronArr.length === 7) {
      cronArr.slice(1, 6).forEach((c) => {
        formattedCron += `${c} `;
      });
      return formattedCron.trim();
    }
    cronArr.forEach((c) => {
      formattedCron += `${c} `;
    });
    return formattedCron.trim();
  };

  const cronHandler = (e) => {
    setCron({ value: e });
  };
  const scanSelectHandler = ({ name, type, id }) => {
    setSelectedScan({ name, type, id });
    setEditSchedule(null);
    setCronOneOff(false);
  };

  const selectEditScheduleHandler = (schedule) => {
    setSelectedScan(null);
    setEditSchedule(schedule);
    setCron({
      value: `0 ${schedule.crontab} *`,
    });
    setCronName(schedule.name);
    setCronOneOff(schedule.one_off);
    setCronStart(schedule.creation_date);
    setCronExpiry(schedule.expires);
    setCronEnabled(schedule.enabled);
  };

  const cronSaveHandler = () => {
    setSaveCronError(null);
    setOpenSaveModal(true);
    if ((cronName === "" || cronStart === "") && !editSchedule) {
      setSaveCronError("Required fields are empty");
      return;
    }

    const scanId = selectedScan
      ? selectedScan.id
      : editSchedule.scan
      ? editSchedule.scan.scan_id
      : editSchedule.crawler.crawler_id;
    const scanType = selectedScan
      ? selectedScan.type
      : editSchedule.scan
      ? "scan"
      : editSchedule.crawler
      ? "crawl"
      : "";
    const cronData = {
      name: cronName,
      description: "",
      enabled: cronEnabled,
      crontab: cronFormat(cron.value),
      start_time: cronStart,
      expires: cronExpiry,
      one_off: cronOneOff,
      scan_id: scanId,
      scan_type: scanType,
    };
    console.log(cronData);
    if (selectedScan) {
      fetch(`${env.REACT_APP_API_HOST}scheduled_tasks/`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(cronData),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          closeCronEditorHandler();
          getScheduledTasks();
        });
    }
    if (editSchedule) {
      fetch(`${env.REACT_APP_API_HOST}scheduled_tasks/${editSchedule.id}/`, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(cronData),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          closeCronEditorHandler();
          getScheduledTasks();
        });
    }
  };

  const cronDeleteHandler = async () => {
    setDeletingCron(true);
    await fetch(
      `${env.REACT_APP_API_HOST}scheduled_tasks/${editSchedule.id}/`,
      {
        method: "DELETE",
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((d) => {
        setDeleteStatus(d.message);
        getScheduledTasks();
        setTimeout(() => {
          setDeletingCron(false);
          setDeleteStatus(null);
          handleCloseModal();
          setEditSchedule(null);
        }, 1000);
      });
  };

  const formatDateTime = (e) => {
    const d = new Date(e);
    return d.toDateString();
  };

  return (
    <div className="grid grid-cols-5 gap-3">
      <Card>
        <input
          type="text"
          placeholder="Search"
          className="scenarioScan_left_input hidden"
          onChange={handleSearchFiltring}
        />
        <input
          type="text"
          placeholder="Search"
          className="scenarioScan_left_input py-2"
          onChange={(e) => {
            setReportsSearchQuery(e.target.value.toLowerCase());
          }}
        />
        <div className="flex justify-between w-full px-4">
          <FontAwesomeIcon
            icon={alfaFiltersIcon}
            onClick={() => filterAlphabetically()}
            className="hover:text-white cursor-pointer self-start p-1 rounded-lg hover:bg-green/50"
          />
          <div>
            <ToggleButtonGroup
              value={typeFilter}
              onChange={handleTypeFilter}
              aria-label="text formatting"
            >
              <ToggleButton
                value="c"
                aria-label="c"
                className="reportTypeFilterToggle"
              >
                <p>C</p>
              </ToggleButton>
              <ToggleButton
                value="s"
                aria-label="s"
                className="reportTypeFilterToggle"
              >
                <p>S</p>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div>
          {reportsList.map((elem, index) => {
            if (elem.type === "scan" && !typeFilter.includes("s")) return;
            if (elem.type === "crawl" && !typeFilter.includes("c")) return;
            if (!elem.name.toLowerCase().includes(reportsSearchQuery)) return;
            return (
              <div
                key={index}
                className="hover:bg-gray-200 py-1 px-1 cursor-pointer"
                onClick={() =>
                  scanSelectHandler({
                    name: elem.name,
                    type: elem.type,
                    id: elem.id,
                  })
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "5px",
                    heigth: "100%",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      backgroundColor: "#12b922",
                      color: "white",
                      heigth: "100%",
                      fontWeight: "lighter",
                      padding: "1px 3px",
                      lineHeight: "19px",
                      borderRadius: "7px",
                      fontSize: "14px",
                    }}
                  >
                    {elem.type === "scan" ? "S" : "C"}
                  </p>
                  <p
                    title={elem.name}
                    className="report_left_table_item_p min-w-[85%]"
                  >
                    <strong>{elem.name}</strong>
                  </p>
                  <FontAwesomeIcon
                    icon="fa-solid fa-add"
                    className="text-green"
                  ></FontAwesomeIcon>
                </div>
              </div>
            );
          })}
        </div>
      </Card>
      {selectedScan ? (
        <div className="col-span-4 transition-all delay-300">
          <Card className="mb-4 flex items-center justify-between gap-1">
            <h2
              style={{ color: "#00796B" }}
              className="relative font-bold ml-[10px] max-w-[350px] text-[16px] whitespace-nowrap text-ellipsis "
            >
              {selectedScan.name}
              <br />
              <span className="font-light">New Schedule</span>
            </h2>
            <button
              onClick={closeCronEditorHandler}
              className="bg-white text-red-600 hover:text-white hover:bg-red-600 items-center justify-center px-2 py-1 rounded-xl"
            >
              <FontAwesomeIcon icon="fa-solid fa-xmark" className="mr-1" />{" "}
              Close
            </button>
          </Card>

          <Card className="mt-4">
            <div className="flex items-center">
              <div className="flex flex-auto flex-col mb-4 ">
                <label className=" text-gray-800 mb-2">
                  Schedule Name&nbsp;
                  <FontAwesomeIcon
                    icon="fa-solid fa-asterisk"
                    className="text-xs text-red-600"
                  />
                </label>
                <input
                  required
                  onChange={cronNameChangeHandler}
                  type="text"
                  className="commonInput_style rounded-xl"
                />
              </div>
              <div className="flex items-center mt-3">
                <label className=" switch">
                  <input
                    onChange={cronEnabledChangeHandler}
                    type="checkbox"
                    defaultChecked={cronEnabled}
                  />
                  <span className="slider round"></span>
                </label>
                <label className="cursor-pointer ml-2">Enabled</label>
              </div>
            </div>

            <div className="flex gap-3 mb-4">
              <div className="flex mt-2  flex-auto items-center">
                <label className=" switch">
                  <input
                    onChange={cronOneOffChangeHandler}
                    value={cronOneOff}
                    type="checkbox"
                  />
                  <span className="slider round"></span>
                </label>
                <label className=" cursor-pointer ml-2">One Off Task</label>
              </div>
              <div className="flex flex-grow flex-col mb-3 ">
                <label className=" text-gray-800 mb-2">
                  Start Time&nbsp;
                  <FontAwesomeIcon
                    icon="fa-solid fa-asterisk"
                    className="text-xs text-red-600"
                  />
                </label>
                <input
                  onChange={cronStartChangeHandler}
                  required
                  type="datetime-local"
                  className="commonInput_style rounded-xl"
                />
              </div>
              {!cronOneOff && (
                <div className="flex flex-col flex-grow">
                  <label className=" text-gray-800 mb-2">Expiry</label>
                  <input
                    onChange={cronExpiryChangeHandler}
                    type="datetime-local"
                    className="commonInput_style rounded-xl"
                  />
                </div>
              )}
            </div>
            {!cronOneOff && (
              <div className="py-4">
                <h4>Execution Schedule:</h4>
                <Cron
                  onChange={cronHandler}
                  value={cron.value}
                  showResultText={true}
                  showResultCron={false}
                  options={options}
                />
              </div>
            )}
            <div className="grid grid-cols-2 gap-3">
              {saveCronError && (
                <div className="col-span-2">
                  <div className="rounded-xl p-2 text-red-600 bg-red-100">
                    {saveCronError}
                  </div>
                </div>
              )}

              <div className="flex justify-end">
                <button
                  onClick={cronSaveHandler}
                  type="button"
                  className="bg-green text-white rounded-xl px-3 py-2"
                >
                  <FontAwesomeIcon icon="fa-solid fa-save" className="mr-2" />
                  Save
                </button>
              </div>
            </div>
          </Card>
        </div>
      ) : editSchedule ? (
        <div className="col-span-4 transition-all delay-300">
          <Card className="mb-4 flex items-center justify-between gap-1">
            <h2
              style={{ color: "#00796B" }}
              className="relative font-bold ml-[10px] max-w-[350px] text-[16px] whitespace-nowrap text-ellipsis"
            >
              {editSchedule.crawler
                ? editSchedule.crawler.crawler_name
                : editSchedule.scan
                ? editSchedule.scan.scan_name
                : ""}
              <br />
              <span className="font-light">{editSchedule.name}</span>
              <br></br>
              <span className="font-light text-xs">
                {(() => {
                  const d = new Date(editSchedule.creation_date);
                  return d.toLocaleString();
                })()}
              </span>
            </h2>
            <button
              onClick={closeCronEditorHandler}
              className="bg-white  text-red-600 hover:text-white hover:bg-red-600 items-center justify-center px-2 py-1 rounded-xl"
            >
              <FontAwesomeIcon icon="fa-solid fa-xmark" className="mr-1" />
              Close
            </button>
          </Card>

          <Card className="mt-4">
            <div className="flex items-center">
              <div className="flex flex-auto flex-col mb-4 ">
                <label className=" text-gray-800 mb-2">
                  Schedule Name&nbsp;
                  <FontAwesomeIcon
                    icon="fa-solid fa-asterisk"
                    className="text-xs text-red-600"
                  />
                </label>
                <input
                  required
                  onChange={cronNameChangeHandler}
                  type="text"
                  defaultValue={editSchedule.name}
                  className="commonInput_style rounded-xl"
                />
              </div>
              <div className="flex items-center mt-3">
                <label className=" switch">
                  <input
                    onChange={cronEnabledChangeHandler}
                    type="checkbox"
                    defaultChecked={editSchedule.enabled}
                  />
                  <span className="slider round"></span>
                </label>
                <label className="cursor-pointer ml-2">Enabled</label>
              </div>
            </div>

            <div className="flex gap-3 mb-4">
              <div className="flex flex-auto">
                <div className="flex flex-grow items-center  mt-2 ">
                  <label className="switch">
                    <input
                      onChange={cronOneOffChangeHandler}
                      defaultChecked={editSchedule.one_off}
                      type="checkbox"
                    />
                    <span className="slider round"></span>
                  </label>
                  <label className=" cursor-pointer ml-2">One Off Task</label>
                </div>
              </div>
              <div className="flex flex-auto flex-col mb-3 ">
                <label className=" text-gray-800 mb-2">
                  Start Time&nbsp;
                  <FontAwesomeIcon
                    icon="fa-solid fa-asterisk"
                    className="text-xs text-red-600"
                  />
                </label>
                <input
                  onChange={cronStartChangeHandler}
                  required
                  defaultValue={(editSchedule.start_time || "")
                    .toString()
                    .substring(0, 16)}
                  type="datetime-local"
                  className="commonInput_style rounded-xl"
                />
              </div>
              {!cronOneOff && (
                <div className="flex flex-col flex-auto">
                  <label className=" text-gray-800 mb-2">Expiry</label>
                  <input
                    onChange={cronExpiryChangeHandler}
                    type="datetime-local"
                    defaultValue={(editSchedule.expires || "")
                      .toString()
                      .substring(0, 16)}
                    className="commonInput_style rounded-xl"
                  />
                </div>
              )}
            </div>
            {!cronOneOff && (
              <div className="my-2 py-2 px-3 rounded-lg bg-grey-300 text-white flex w-full gap-5 justify-between">
                <span className="flex flex-auto">
                  Execution Schedule: {editSchedule.crontab_readable}
                </span>
              </div>
            )}

            {!cronOneOff && (
              <div className="py-4">
                {
                  <Cron
                    onChange={cronHandler}
                    value={cron.value}
                    showResultText={true}
                    showResultCron={false}
                    options={options}
                  />
                }
              </div>
            )}
            <div className="grid grid-cols-1 gap-3 mt-3">
              {saveCronError && (
                <div className="rounded-xl p-2 text-red-600 bg-red-100">
                  {saveCronError}
                </div>
              )}

              <div className="flex flex-grow justify-between gap-5">
                <button
                  onClick={handleOpenModal}
                  type="button"
                  className="bg-red-600 text-white rounded-xl px-3 py-2"
                >
                  <FontAwesomeIcon icon="fa-solid fa-trash" className="mr-2" />
                  Delete
                </button>
                <button
                  onClick={cronSaveHandler}
                  type="button"
                  className="bg-green text-white rounded-xl px-3 py-2"
                >
                  <FontAwesomeIcon icon="fa-solid fa-save" className="mr-2" />
                  Save
                </button>
              </div>
            </div>
          </Card>

          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <h2 className="text-center mb-2">
                <FontAwesomeIcon
                  icon="fa-solid fa-trash"
                  className="mr-2 text-red-600"
                ></FontAwesomeIcon>
                Confirm Delete
              </h2>

              {deletingCron ? (
                <div className="mt-3">
                  {deteteStatus ? (
                    <div className="text-center">{deteteStatus}</div>
                  ) : (
                    <div className="text-center flex items-center justify-center">
                      <FontAwesomeIcon
                        icon="fa-solid fa-spinner"
                        className="mr-2 fa-pulse"
                      ></FontAwesomeIcon>
                      Deleting {editSchedule.name}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <p id="modal-modal-description" className="text-center">
                    You're about to delete {editSchedule.name}.
                  </p>
                  <div className="flex justify-between mt-3">
                    <button
                      onClick={handleCloseModal}
                      className="bg-grey-300 text-white px-2.5 py-2 rounded-lg"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={cronDeleteHandler}
                      className="bg-red-600 text-white px-2.5 py-2 rounded-lg"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              )}
            </Box>
          </Modal>
        </div>
      ) : (
        <div className="col-span-4">
          <Card className="mb-4 flex items-center justify-between gap-1">
            <h2
              style={{ color: "#00796B" }}
              className="relative font-bold ml-[10px] max-w-[350px] text-[16px] whitespace-nowrap text-ellipsis "
            >
              Scheduled Tasks
            </h2>
          </Card>

          <table className="rounded-t-lg table-auto bg-white w-full border-collapse">
            <thead className="bg-grey-300 rounded-t-lg">
              <tr className="scheduled_header_tr text-white rounded-t-lg">
                <th>#</th>
                <th>Name</th>
                <th>Created</th>
                <th>Status</th>
                <th>Type</th>
                <th>Start</th>
                <th>Expiry</th>
                <th>Last Run</th>
                <th>Scenario/Crawler</th>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {scheduledTasks.map((el, idx) => {
                return (
                  <tr
                    key={idx}
                    className="scheduled_tbody_tr border border-gray-400 font-light"
                  >
                    <td className="text-center">{idx + 1}</td>
                    <td>{el.name}</td>
                    <td className="text-center">
                      {formatDateTime(el.creation_date)}
                    </td>
                    <td className="text-center">
                      {el.enabled ? (
                        <FontAwesomeIcon
                          icon="fa-solid fa-toggle-on"
                          className="text-green"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon="fa-solid fa-toggle-off"
                          className="text-red-600"
                        />
                      )}
                    </td>
                    <td className="text-center">
                      {el.one_off ? (
                        <FontAwesomeIcon
                          icon="fa-solid fa-1"
                          className="text-red-600"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon="fa-solid fa-repeat"
                          className="text-green"
                        />
                      )}
                    </td>
                    <td className="text-center">
                      {(() => {
                        const d = new Date(el.start_time);
                        return d.toUTCString();
                      })()}
                    </td>
                    <td className="text-center">
                      {el.expires ? (
                        formatDateTime(el.expires)
                      ) : (
                        <FontAwesomeIcon
                          icon="fa-solid fa-xmark"
                          className="text-red-600"
                        />
                      )}
                    </td>
                    <td className="text-center">
                      {el.last_run_at &&
                        (() => {
                          const d = new Date(el.last_run_at);
                          return d.toUTCString();
                        })()}
                    </td>
                    <td className="text-center">
                      {el.crawler
                        ? el.crawler.crawler_name
                        : el.scan
                        ? el.scan.scan_name
                        : ""}
                    </td>
                    <td className="text-center px-3 flex justify-between gap-2">
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen-to-square"
                        onClick={() => {
                          selectEditScheduleHandler(el);
                        }}
                        className="cursor-pointer"
                      />
                      {el && (
                        <Link to={`${el.id}`}>
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-chevron-right"
                            className="text-green cursor-pointer"
                          />
                        </Link>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <Modal
        open={openSaveModal}
        onClose={handleCloseSaveModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center flex items-center justify-center">
            <FontAwesomeIcon
              icon="fa-solid fa-spinner"
              className="mr-2 fa-pulse fa-2x"
            ></FontAwesomeIcon>
            Saving
          </div>
        </Box>
      </Modal>
    </div>
  );
}
