import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import { formatBytes, stringToDateFormat } from "../../utils";

import CloseIcon from "@mui/icons-material/Close";
import { grey } from "@mui/material/colors";
import moment from "moment/moment";
import { useState } from "react";

function DataCard({ title, data }) {
  return (
    <Box width={"100%"} display={"flex"} alignItems={"center"} px={2} py={1}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <Typography variant="subtitle2" fontWeight={600}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <Typography variant="caption" fontWeight={400} color={grey[700]}>
            {data}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function ScanReportSummary({
  homepage_screenshot,
  start_date,
  end_date,
  points_consumed,
  total_pages,
  total_commands,
  avg_page_load_time,
  total_validations,
  successful_validations,
  failed_validations,
  report_size,
}) {
  const [showLargeImg, setShowLargeImg] = useState(false);
  const handleClose = () => {
    setShowLargeImg(false);
  };
  const handleOpenLargeImg = () => {
    setShowLargeImg(true);
  };
  const imgDialog = (
    <>
      <Dialog open={showLargeImg} onClose={handleClose} fullScreen>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <img src={homepage_screenshot} width={"100%"} />
        </DialogContent>
      </Dialog>
    </>
  );

  const Root = styled("div")(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      position: "relative",
    },
    [theme.breakpoints.up("md")]: {
      position: "sticky",
    },
  }));
  return (
    <Root sx={{ width: "100%", top: "0.5rem", overflowY: "auto" }}>
      {imgDialog}
      <Paper square>
        <Box sx={{ cursor: "pointer" }} onClick={handleOpenLargeImg}>
          <img src={homepage_screenshot} alt="" width={400} />
        </Box>
        <DataCard title={`Report Size`} data={formatBytes(report_size)} />
        <DataCard
          title={`Start Date`}
          data={moment.unix(start_date).format("LLL")}
        />
        <DataCard
          title={`Completion Date`}
          data={moment.unix(end_date).format("LLL")}
        />
        <DataCard title={`Points Consumed`} data={points_consumed} />
        <DataCard title={`Total Pages`} data={total_pages} />
        <DataCard title={`Total Commands`} data={total_commands} />
        <DataCard
          title={`Average page speed load`}
          data={`${avg_page_load_time.toFixed(2)}s`}
        />
        <DataCard title={`Total Validations`} data={total_validations} />
        <DataCard
          title={`Successful Validations`}
          data={successful_validations}
        />
        <DataCard title={`Failed Validations`} data={failed_validations} />
      </Paper>
    </Root>
  );
}
