import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useCallback } from "react";
import { env } from "../../../config.js";
import { useNavigate } from "react-router-dom";

function Regex() {
  const [regex, setRegex] = useState({
    regex_name: "",
    regex_value: "",
  });
  const [parameter, setParameter] = useState({
    parameterName: "",
    firstParameter: "",
    secondParameter: "",
  });
  const [data, setData] = useState();
  let navigate = useNavigate();

  const handleRoute = useCallback(
    (val) => {
      navigate(val);
    },
    [navigate]
  );
  const handleGetRegex = async () => {
    await fetch(`${env.REACT_APP_API_HOST}get_regex_admin_dashboard/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        // console.log(response);
        if (response.isAuthenticated === "False") handleRoute("/login");
        setData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleCheckUser = async () => {
    await fetch(`${env.REACT_APP_API_HOST}checkUser/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.user === 0) {
          handleRoute("/scan/scenario-scan");
        }
        if (response.isAuthenticated === "False") handleRoute("/login");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleAddRegex = async () => {
    if (
      regex.regex_name.trim().length === 0 ||
      regex.regex_value.trim().length === 0
    )
      return;
    await fetch(`${env.REACT_APP_API_HOST}add_regex_admin_dashboard/`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(regex),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.isAuthenticated === "False") handleRoute("/login");
        setTimeout(() => {
          handleGetRegex();
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleGetUrlParams = async () => {
    await fetch(`${env.REACT_APP_API_HOST}get_urlPrams/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.isAuthenticated === "False") handleRoute("/login");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    handleCheckUser();
    handleGetRegex();
    // handleGetUrlParams();
  }, []);

  const handleChange = (e, elem, tag) => {
    if (tag === "regex") {
      const newRegex = { ...regex };
      newRegex[elem] = e.target.value;
      setRegex(newRegex);
    } else {
      const newParameter = { ...parameter };
      newParameter[elem] = e.target.value;
      setParameter(newParameter);
    }
  };

  const handleRemoveRegex = async (id) => {
    await fetch(`${env.REACT_APP_API_HOST}delete_regex_admin_dashboard/${id}`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.isAuthenticated === "False") handleRoute("/login");
        setTimeout(() => {
          handleGetRegex();
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="adminRegex_container">
      <div className="adminRegex">
        <div className="adminRegex_top">
          <h1 className="adminRegex_h1">Regex.</h1>
          <div className="admin_regexTopSection">
            <input
              style={{ width: "243px" }}
              type="text"
              className="admin_regexInput"
              placeholder="Regex Name"
              onChange={(e) => {
                handleChange(e, "regex_name", "regex");
              }}
            />
            <input
              style={{ width: "321px" }}
              type="text"
              className="admin_regexInput"
              placeholder="Regex Value"
              onChange={(e) => {
                handleChange(e, "regex_value", "regex");
              }}
            />
            <button
              className="admin_regexButton"
              onClick={handleAddRegex}
              style={{ cursor: "pointer" }}
            >
              ADD NEW REGEX.
            </button>
          </div>
        </div>
        <div className="regex_tableCointainer__" style={{ width: "100%" }}>
          <div className="regex_tableCointainer">
            <table className="regex_table">
              <thead className="regex_table_thead">
                <tr className="regex_table_thead_tr">
                  <th>Regex Name</th>
                  <th>Regex value</th>
                </tr>
              </thead>
              <tbody className="regex_table_tbody">
                {data?.map((elem, index) => (
                  <tr key={index} className="regex_table_tbody_tr">
                    <td className="regex_rowStart_right_td">
                      <div className="regex_rowStart">
                        <FontAwesomeIcon
                          icon="fa-solid fa-trash"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleRemoveRegex(elem.regex_id);
                          }}
                          color="#E56353"
                        />
                        {elem.regex_name}
                      </div>
                    </td>
                    <td className="regex_rowStart_right_td">
                      <div className="regex_rowStart">{elem.regex_value}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="adminRegex_top">
          <h1 className="adminRegex_h1">Capture URL Parameter</h1>
          <div className="admin_regexTopSection">
            <input
              style={{ width: "243px" }}
              type="text"
              className="admin_regexInput"
              placeholder="Parameter Name"
            />
            <input
              placeholder="First Parameter"
              type="text"
              className="admin_regexInput"
            />
            <input
              placeholder="Second Parameter"
              type="text"
              className="admin_regexInput"
            />
            <button className="admin_regexButton">ADD NEW REGEX.</button>
          </div>
        </div> */}
        {/* <div className="regex_tableCointainer__" style={{ width: "100%" }}>
          <div className="regex_tableCointainer">
            <table className="regex_table">
              <thead className="regex_table_thead">
                <tr className="regex_table_thead_tr">
                  <th>Parameter Name</th>
                  <th>First Parameter</th>
                  <th>second Parameter</th>
                </tr>
              </thead>
              <tbody className="regex_table_tbody">
                {data2nd.map((elem, index) => (
                  <tr key={index} className="regex_table_tbody_tr">
                    <td>
                      <div className="regex_rowStart">
                        <FontAwesomeIcon
                          icon="fa-solid fa-trash"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            // handleRemoveInstruction(index)
                            console.log("clicked");
                          }}
                          color="#E56353"
                        />
                        {elem.parameterName}
                      </div>
                    </td>
                    <td>{elem.firstParameter}</td>
                    <td>{elem.secondParameter}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Regex;
