import { env } from "../config";

export const updateScenario = async (data) => {
  const update = await fetch(`${env.REACT_APP_API_HOST}update_scans/`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(data),
  });
  return update;
};
