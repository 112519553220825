import React, { useState, useCallback, useEffect } from "react";
import Input from "../../../components/Input";
import "../adminLayoutStyle.css";
import { env } from "../../../config.js";
import { useNavigate } from "react-router-dom";
function Account() {
  const [data, setData] = useState({
    accountId: "",
    accountName: "",
    adress: "",
    vatNumber: "",
    country: "",
    plan: "",
  });
  const [plans, setPlans] = useState([]);

  let navigate = useNavigate();

  const handleRoute = useCallback(
    (val) => {
      navigate(val);
    },
    [navigate]
  );
  const handleChange = (e, tag) => {
    const newData = { ...data };
    newData[tag] = e.target.value;
    setData(newData);
  };

  const handleCheckUser = async () => {
    await fetch(`${env.REACT_APP_API_HOST}checkUser/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.user === 0) {
          console.log("not Authenticated");
          handleRoute("/scan/scenario-scan");
        }
        if (response.isAuthenticated === "False") handleRoute("/login");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleGetPlans = async () => {
    await fetch(`${env.REACT_APP_API_HOST}get_plans/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        setPlans(response);
        // const newData = { ...data };
        // newData["plan"] = response[0];
        // setData(newData);
        if (response.isAuthenticated === "False") handleRoute("/login");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    handleCheckUser();
    handleGetPlans();
  }, []);

  const handleSelectChange = (e) => {
    console.log(e.target.value);
    const newData = { ...data };
    newData["plan"] = e.target.value;
    setData(newData);
  };

  const handleValidation = async () => {
    await fetch(`${env.REACT_APP_API_HOST}admin_registerAccount/`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.isAuthenticated === "False") handleRoute("/login");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div className="account_container">
      <Input
        handleChange={handleChange}
        h2Title={"Account ID"}
        value={data.accountId}
        tag="accountId"
      />
      <Input
        handleChange={handleChange}
        h2Title={"Account Name"}
        value={data.accountName}
        tag="accountName"
      />
      <Input
        handleChange={handleChange}
        h2Title={"Adress"}
        value={data.adress}
        tag="adress"
      />
      <Input
        handleChange={handleChange}
        h2Title={"VAT Number"}
        value={data.vatNumber}
        tag="vatNumber"
      />
      <Input
        handleChange={handleChange}
        h2Title={"Country"}
        value={data.country}
        tag="country"
      />
      <div className="admin_users_selectConatiner">
        <label htmlFor="" className="admin_users_selectLabel">
          Plan
        </label>
        <select
          value={data.plan}
          className="admin_users_select"
          name=""
          id=""
          onChange={handleSelectChange}
        >
          {plans?.map((elem, index) => (
            <option key={index} value={elem.plan_id}>
              {elem.plan}
            </option>
          ))}
        </select>
      </div>
      <button
        onClick={handleValidation}
        className="mainAdimn_common_buttonContainer"
      >
        VALIDATE & SAVE
      </button>
    </div>
  );
}

export default Account;
