import "./loginStyle.css";

import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import authService from "../../services/authService";
import { env } from "../../config.js";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

function Login() {
  let navigate = useNavigate();
  const [err, setErr] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const handleRoute = useCallback(
    (val) => {
      navigate(val);
    },
    [navigate]
  );
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    let subscription = true;
    if (subscription) {
      document.addEventListener("keypress", (event) => {
        if (event.key === "Enter") {
          handleLogin();
        }
      });
      document.addEventListener("keypress", () => {});
    }
    return () => (subscription = false);
  }, []);

  const checkLogin = async () => {
    await fetch(`${env.REACT_APP_API_HOST}login/`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.isAuthenticated === true)
          handleRoute("/scan/scenario-scan");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleLogin = useCallback(
    async (e) => {
      const userName = document.getElementById("userName").value;
      const password = document.getElementById("password").value;
      if (userName.trim().length === 0 || password.trim().length === 0) {
        setErrMsg("Both fields are required!");
        setErr(true);
      } else {
        setErr(false);
      }

      const data = await fetch(`${env.REACT_APP_API_HOST}login/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          username: userName,
          password: password,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((response) => {
          if (response.success === "True") {
            localStorage.setItem(
              "taglabUser",
              JSON.stringify({
                profilePicture: response.profile_picture,
                firstName: response.first_name,
              })
            );

            handleRoute("/scan/scenario-scan");
          }
          if (response.success === "False") {
            setErrMsg("You have entered an invalid username or password");
            setErr(true);
            // console.log("login request refused !");
          }
        })
        .catch((e) => {
          console.log(e);
        });
      console.log(data);
    },
    [handleRoute]
  );

  useEffect(() => {
    navigate("/auth?authMode=login");
    // checkLogin();
    // authService.getCsrfToken();
  }, []);
  return (
    <div className="flex">
      <div className="flex-1 w-2/5 bg-[#4BAC4D] h-screen flex justify-center items-center">
        <div className="relative">
          <div className="login-logo-background w-full h-full absolute z-10"></div>
          <img
            src={require("../../assets/login_logo.png")}
            alt=""
            className="relative z-20"
          />
        </div>
      </div>
      <div className="w-3/5 min-h-screen flex flex-col items-center justify-between p-[10%]">
        <div className="text-center">
          <h1 className="text-center text-[#5BBA5B] text-6xl mb-4">Welcome</h1>
          <p>
            By logging in with credentials associated to my identity,&nbsp;I
            acknowledge that I have read, understood, and agreed to{" "}
            <a target="_blank" href="https://taglab.net/terms-and-conditions/">
              the service terms and conditions.
            </a>
          </p>
        </div>
        <div>
          <div className="flex flex-col">
            <div>
              <label className="text-black/40 font-bold" htmlFor="">
                Username
              </label>
            </div>
            <input
              type="text"
              className="px-4 py-2 min-w-[324px] bg-black/10 border-black/30 border-solid border-2 rounded-3xl"
              id="userName"
              placeholder="User Name"
            />
          </div>
          <div className="flex flex-col">
            <div>
              <label className="text-black/40 font-bold" htmlFor="">
                Password
              </label>
            </div>
            <div className="relative">
              <input
                type={passwordShown ? "text" : "password"}
                className="px-4 py-2 min-w-[324px] bg-black/10 border-black/30 border-solid border-2 rounded-3xl"
                id="password"
                placeholder="password"
              />
              <FontAwesomeIcon
                icon={`fa-solid fa-eye${passwordShown ? "" : "-slash"}`}
                className="absolute top-3 right-2 cursor-pointer opacity-50"
                onClick={togglePassword}
              />
            </div>
          </div>
          <button
            type="submit"
            className="p-2 mt-8 text-white font-bold rounded-2xl min-w-[324px] bg-[#4BAC4D]"
            onClick={handleLogin}
          >
            LOG IN
          </button>
        </div>
        <div>
          <div className="text-center">© 2023 Taglab — All Rights Reserved</div>
        </div>
        {/* <div className="login_content">
          <h1>Log in</h1>
          <div className="login_content_inputs">
            <input
              type="text"
              className="login_content_inputs_input"
              id="userName"
              placeholder="User Name"
            />
            <input
              type="password"
              className="login_content_inputs_input"
              id="password"
            />
          </div>
          <div>
            <p>
              <strong>
                By logging in with credentials associated to my identity,&nbsp;I
                acknowledge that I have read, understood, and agreed to{" "}
                <a
                  target="_blank"
                  href="https://taglab.net/terms-and-conditions/"
                >
                  the service terms and conditions.
                </a>
              </strong>
            </p>
          </div>

          <div
            style={{
              marginBottom: err ? "10px" : "20px",
              marginTop: "10px",
              color: "red",
            }}
          >
            {err ? <div>{errMsg}</div> : <></>}
          </div>
          <button
            type="submit"
            className="login_content_button"
            onClick={handleLogin}
          >
            LOG IN
          </button>
        </div>
        <div className="login_content_hr">
          <hr className="login_hr" />
        </div> */}
      </div>
    </div>
  );
}

export default Login;
