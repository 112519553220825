import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  FacebookIcon,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { blue, green, grey, orange, yellow } from "@mui/material/colors";
import { useEffect, useState } from "react";

import AboutReferrals from "./AboutReferrals";
import CashoutHistory from "./CashoutHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Unstable_NumberInput as NumberInput } from "@mui/base/Unstable_NumberInput";
import PricingTable from "../../components/PricingTable/PricingTable";
import ReferralsTrend from "./ReferralsTrend";
import { env } from "../../config";
import { stringToDateFormat } from "../../utils";

export default function ReferralDashboard({
  link_id,
  referral_link,
  earnings,
}) {
  const host = window.location.protocol + "//" + window.location.host;
  const [referrals, setReferrals] = useState([]);
  const [upgrades, setUpgrades] = useState([]);
  const [trendDays, setSetTrendDays] = useState(30);

  const getSignups = async () => {
    try {
      const res = await fetch(`${env.REACT_APP_API_HOST}referral/signups/`, {
        method: "GET",
        credentials: "include",
      });
      const resData = await res.json();
      if (!res.ok) throw new Error(resData.error);
      setReferrals(resData.signups);
      setUpgrades(resData.upgrades);
    } catch (resError) {}
  };

  const handleLinkCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleTrendDaysChange = (e) => {
    const value = e.target.value;
    setSetTrendDays(parseInt(value));
  };

  const trendDaysField = (
    <TextField
      size="small"
      label="Days"
      type="number"
      onChange={handleTrendDaysChange}
      defaultValue={trendDays}
    />
  );

  useEffect(() => {
    getSignups();
  }, [referral_link]);

  return (
    <>
      <Paper square sx={{ my: 2 }}>
        <CardContent>
          <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
            <Box sx={{ display: "block", width: "100%", alignItems: "center" }}>
              <Typography sx={{ mr: 1 }} variant="overline">
                Referral Link
              </Typography>
              <Box>
                <Tooltip title="Click to copy">
                  <Chip
                    onClick={() => handleLinkCopy(`${host}${referral_link}`)}
                    sx={{ borderRadius: 0 }}
                    variant="outlined"
                    color="primary"
                    label={`${host}${referral_link}`}
                  />
                </Tooltip>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Share on{" "}
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Tooltip title="Share on X">
                        <a
                          target="_blank"
                          className="twitter-share-button"
                          href={`https://twitter.com/intent/tweet?url=${host}${referral_link}`}
                        >
                          <XIcon size={30} />
                        </a>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Share on Facebook">
                        <FacebookShareButton url={`${host}${referral_link}`}>
                          <FacebookIcon size={30} />
                        </FacebookShareButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Share on LinkedIn">
                        <LinkedinShareButton url={`${host}${referral_link}`}>
                          <LinkedinIcon size={30} />
                        </LinkedinShareButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Share on WhatsApp">
                        <WhatsappShareButton url={`${host}${referral_link}`}>
                          <WhatsappIcon size={30} />
                        </WhatsappShareButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "end",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Paper sx={{ px: 2, py: 1, bgcolor: green[400] }}>
                  <Typography variant="h4" color={"white"}>
                    {referrals.length}
                  </Typography>
                </Paper>
                <Typography variant="caption">Signups</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Paper sx={{ px: 2, py: 1, bgcolor: yellow[600] }}>
                  <Typography variant="h4" color={"white"}>
                    {upgrades.length}
                  </Typography>
                </Paper>
                <Typography variant="caption">Upgrades</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Paper sx={{ px: 2, py: 1, bgcolor: orange[400] }}>
                  <Typography variant="h4" color={"white"}>
                    $
                    {Number((referrals.length * 1000 * 3) / 2).toLocaleString()}
                  </Typography>
                </Paper>
                <Typography variant="caption">Potential Revenue</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Paper sx={{ px: 2, py: 1, bgcolor: blue[400] }}>
                  <Typography variant="h4" color={"white"}>
                    ${Number(earnings).toLocaleString()}
                  </Typography>
                </Paper>
                <Typography variant="caption">Available Funds</Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Paper>

      <PricingTable display={"referral"} />
      <AboutReferrals />
      {referrals.length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card sx={{ width: "100%" }}>
              <CardHeader
                title={
                  <Typography variant="overline">Referral Trends</Typography>
                }
                action={trendDaysField}
              />
              <CardContent>
                <ReferralsTrend
                  referrals={referrals}
                  upgrades={upgrades}
                  trendDays={trendDays}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card sx={{ width: "100%" }}>
              <CardHeader
                title={<Typography variant="overline">My Referrals</Typography>}
              />
              {referrals.map((el, i) => (
                <Box
                  key={i}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    px: 2,
                    my: 1,
                  }}
                >
                  <Box>
                    <Typography variant="caption">
                      {stringToDateFormat(el.signup_date)}
                    </Typography>
                    <Typography>{el.referred_user}</Typography>
                    <Typography variant="subtitle2" color={grey[600]}>
                      {el.referred_email}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>Plan</Typography>
                    <Typography variant="subtitle2" color={"secondary"}>
                      {el.plan}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>Earnings</Typography>
                    <Typography variant="overline">$ {el.earnings}</Typography>
                  </Box>
                </Box>
              ))}
            </Card>
          </Grid>
        </Grid>
      )}
      <Box sx={{ my: 3 }}>
        <CashoutHistory />
      </Box>
    </>
  );
}
