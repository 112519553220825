import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";

import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { env } from "../../config";
import { red } from "@mui/material/colors";
import { useState } from "react";

export default function ScanDelete({
  scanId,
  scanName,
  scanType,
  setStartDelete,
}) {
  const [deleting, setDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [deleteComplete, setDeleteComplete] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    setDeleteError(false);
    try {
      const response = await fetch(`${env.REACT_APP_API_HOST}scans/`, {
        method: "DELETE",
        credentials: "include",
        body: JSON.stringify({ scanId, scanType }),
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.error);
      setDeleteComplete(true);
      setTimeout(() => {
        setStartDelete(false);
        setDeleteComplete(false);
      }, 2000);
    } catch (error) {
      setDeleteError(`${error}`);
    } finally {
      setDeleting(false);
    }
  };

  const handleClose = () => {
    setStartDelete(false);
    setDeleteError(false);
  };
  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Delete ${scanName}?`}
        </DialogTitle>
        {deleteComplete ? (
          <>
            <DialogContent>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <TaskAltIcon fontSize="large" color="success" />
                <Typography>Deleted</Typography>
              </Box>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Box textAlign={"center"}>
                  <Typography
                    align="center"
                    color={red[600]}
                    variant="subtitle"
                  >
                    {deleteError}
                  </Typography>
                </Box>
                You're about to delete {scanName}. Do you want to proceed?
              </DialogContentText>
            </DialogContent>
            {!deleting ? (
              <DialogActions>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  color="error"
                  startIcon={<DeleteOutlineSharpIcon />}
                  onClick={handleDelete}
                  autoFocus
                  variant="contained"
                >
                  Delete
                </Button>
              </DialogActions>
            ) : (
              <>
                <LinearProgress color="error" />
              </>
            )}
          </>
        )}
      </Dialog>
    </>
  );
}
